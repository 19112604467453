import './meme.css';
import { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';
import CreateComment from '../createComment/CreateComment';
import Comment from '../comment/Comment';
import Like from '../like/Like'; // adjust the path as needed

import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import { useMediaQuery } from 'react-responsive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { format } from 'timeago.js';
import preciseFormat from '../../utils/preciseFormat';
import ModalImage from 'react-modal-image';
import { useTheme } from '../../context/ThemeContext';

export default function Meme({ meme }) {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const { player } = useContext(AuthContext);
  const [like, setLike] = useState(meme && meme.likes ? meme.likes.length : 0);
  const [isLiked, setIsLiked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [commentCount, setCommentCount] = useState(1);
  const [comments, setComments] = useState(
    meme && meme.comments ? meme.comments : [],
  );
  const [views, setViews] = useState(meme.views);
  const imgRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const { darkTheme } = useTheme();

  // const [memeToShow, SetMemeToShow] = useState(meme);

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  useEffect(() => {
    //setting views
    try {
      axiosConn.put(
        '/memes/' + meme._id + '/view',
        {},
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
    } catch (err) {
      console.log(err);
    }
    //console.log(meme);
    setViews((view) => view + 1);
    return () => {};
  }, [player.token, meme._id]);

  //console.log(meme);
  useEffect(() => {
    let mounted = true;
    if (meme.img) {
      const length = meme.img.split('.').length;
      //console.log(length);
      if (
        meme.img.split('.')[length - 1] === 'mp4' ||
        meme.img.split('.')[length - 1] === 'mov'
      ) {
        if (!mounted) return;
        setIsVideo(true);
        //console.log(post.img.split('.')[length - 1]);
      } else if (
        meme.img.includes('youtube.com') ||
        meme.img.includes('youtu.be') ||
        meme.img.includes('soundcloud.com') ||
        meme.img.includes('vimeo.com') ||
        meme.img.includes('twitch.com') ||
        meme.img.includes('streamable.com') ||
        meme.img.includes('wistia.com') ||
        meme.img.includes('dailymotion.com') ||
        meme.img.includes('mixcloud.com') ||
        meme.img.includes('vidyard.com') ||
        // meme.img.includes('brighteon.com') ||
        meme.img.includes('kaltura.com')
      ) {
        if (!mounted) return;
        setIsVideo(true);
      }
    }
    if (!mounted) return;
    setIsLiked(meme.likes.includes(player.data.player.playerId));
    return () => {
      mounted = false;
      setIsVideo(false);
    };
  }, [meme.likes, meme.img, player.token, player.data.player.playerId]);

  const likeHandler = async () => {
    // console.log(player);
    //console.log(meme.likes.length);
    try {
      await axiosConn.put(
        '/memes/' + meme._id + '/like',
        {
          playerId: player.data.player.playerId,
          profilePhoto: player.data.player.profilePhoto,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
    } catch (err) {}
    setLike(isLiked ? like - 1 : like + 1);
    setIsLiked(!isLiked);
    //when like changes pull this meme again.
    // try {
    //   setIsLoading(true);
    //   const res = await axiosConn.get(
    //     '/memes/?_id=' + meme._id,

    //     {
    //       headers: {
    //         Authorization: `Bearer ${player.token}`,
    //       },
    //     },
    //   );
    //   console.log(res.data.data.data[0]);
    //   SetMemeToShow(res.data.data.data);
    //   setIsLoading(false);
    // } catch (err) {
    //   setIsLoading(false);
    // }
  };
  // console.log(meme);
  const showMore = () => {
    setCommentCount((prev) => prev + 3);
  };

  return (
    <>
      <div
        className={`${!mobileView ? 'meme' : ''} ${
          darkTheme ? 'memeDarkTheme' : 'meme'
        }`}
      >
        <div className={mobileView ? 'postWrapper' : null}>
          {/* <div className="memeContainerElement"> */}
          {isLoading ? (
            <div className="feedPostProgress">
              <CircularProgress color="inherit" size="10px" />
            </div>
          ) : (
            <div
              ref={imgRef}
              className="imgVidContainer"
              title={`${meme.title}`}
            >
              {isVideo ? (
                <>
                  {isLoaded && <div>Loading...</div>}

                  <ReactPlayer
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    width="100%"
                    playing={false}
                    controls={true}
                    url={meme.img}
                    onReady={() => setIsLoaded(false)}
                  />
                </>
              ) : meme.img ? (
                <ModalImage
                  hideDownload={true}
                  small={meme.img}
                  large={meme.img}
                  className="memeImg2 loadingImg meme-img"
                  // src={meme.img}
                  alt=""
                  title={`${meme.title}`}
                  // loading="lazy"
                  // style={{
                  //   backgroundImage: `url("https://res.cloudinary.com/joegalaxy/image/upload/v1672717524/loading-gif_sfv2dl.gif")`,
                  //   backgroundPosition: 'center',
                  //   backgroundSize: '30%',
                  //   backgroundRepeat: 'no-repeat',
                  // }}
                />
              ) : null}
              <div className="memeByNameContainer">
                <Link className="profileLink" to={`/profile/${meme.playerId}`}>
                  <span className="memeByName">Meme by {meme.playerId}</span>
                </Link>
                <div className={!mobileView ? 'memeViews' : 'memeViewsMobile'}>
                  <VisibilityIcon sx={{ color: 'grey', fontSize: 15 }} />

                  {isNaN(views) ? 0 : views}
                </div>
                <span className={!mobileView ? 'postDate' : 'postDateMobile'}>
                  {preciseFormat(meme.createdAt)}
                </span>
              </div>
              <div className="memeBottom">
                <Like
                  imgRef={imgRef}
                  text="+2 JGK, Yay!"
                  likes={like}
                  likesProfileImgs={meme.likesProfileImgs}
                  isLiked={isLiked}
                  likeHandler={likeHandler}
                  mobileView={mobileView}
                  PF={PF}
                  title={meme.title}
                  width={window.innerWidth}
                  textOffset={-15}
                />
                {/* <div className="memeBottomRight"></div> */}
              </div>
              <div className="commentBottom">
                <div className="comments_wrap">
                  <CreateComment
                    // postId={meme._id}
                    memeId={meme._id}
                    setComments={setComments}
                    setCommentCount={setCommentCount}
                    parent="Meme"
                  />
                  <span className="postCommentText">
                    {comments &&
                      comments
                        .sort((a, b) => {
                          return new Date(b.commentAt) - new Date(a.commentAt);
                        })
                        .slice(0, commentCount)

                        .map((comment, i) => (
                          <Comment
                            comment={comment}
                            key={i}
                            // postId={meme._id}
                            memeId={meme._id}
                            parent="Meme"
                          />
                        ))}
                    {comments && commentCount < comments.length && (
                      <div
                        className={
                          !mobileView ? 'viewComments' : 'viewCommentsMobile'
                        }
                        onClick={() => showMore()}
                      >
                        View {comments.length - commentCount} more{' '}
                        {comments.length - commentCount === 1
                          ? 'comment'
                          : 'comments'}
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
