import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { AuthContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CurrencyFormat from 'react-currency-format';
import axiosConn from '../../axiosConn';
import { useQuery } from 'react-query';
import { ViewPortContext } from '../../context/ViewPortContext';
import { useTheme } from '../../context/ThemeContext';

export default function DisplayPlayerJGK({
  passedInPlayerId = null,
  parent = null,
}) {
  const { width } = useContext(ViewPortContext);
  const { player } = useContext(AuthContext);
  const { darkTheme } = useTheme();

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  // console.log(passedInPlayerId);

  const getCurrPlayer = async (passedInPlayerId) => {
    // console.log(passedInPlayerId);
    try {
      const res1 = await axiosConn.get(
        `/players/?playerId=${
          passedInPlayerId === null
            ? player.data.player.playerId
            : passedInPlayerId
        }`,
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      return res1.data.data.data[0];
    } catch (err) {
      console.log(err);
    }
  };

  const { isLoading, data, isError, isSuccess } = useQuery(
    ['getCurrPlayer', passedInPlayerId],
    () => getCurrPlayer(passedInPlayerId),
    {
      staleTime: 1000 * 60 * 3, // 5 mins
    },
  );
  // console.log(data);
  return (
    // <div className="jgkTokenBottom">
    <div className="jgkTokenWrapper">
      {/* <span>Display Player JGK</span> */}
      <div className="jgkTokenTop">
        <Link to={`/profile/${data?.playerId}`}>
          <img
            className={
              !mobileView ? 'postProfileImg' : 'jgkTokenProfileImgMobile'
            }
            src={
              data?.profilePhoto
                ? data?.profilePhoto
                : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
            }
            alt=""
            loading="lazy"
          />
        </Link>

        <span
          className={`${!mobileView ? 'jgkPlayerId ' : 'jgkPlayerIdMobile'} ${
            darkTheme ? 'dark-theme blackBg' : ''
          }`}
        >
          {/* {currPlayer.playerId} JGK${currPlayer.jgkTokens} Balance. */}
          <CurrencyFormat
            value={data?.jgkTokens}
            displayType={'text'}
            decimalScale={2}
            thousandSeparator={true}
            renderText={(value) => (
              <div>
                {width >= 1138 && data?.playerId}{' '}
                <span style={isLoading ? null : { backgroundColor: '#42b72a' }}>
                  {isLoading ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    value
                  )}
                </span>
                {/* {'  Balance.'} */}
                {width >= 1238 && (
                  <div className="tooltipJGK">
                    <InfoOutlinedIcon
                      className="sidebarIconTooltip"
                      fontSize="small"
                      // color={shareJG === 1 ? 'black' : 'secondary'}
                    />
                    <span className="tooltipJGKtext">
                      <p>JGK: JG Klout </p>
                      <br></br>
                      <p>- As a JG Player and in Life everything Counts. </p>
                      <p>- Everything you do matters. You Matter!</p>
                      <p>- You create Everything!</p>
                      <br></br>
                      <p>- This game is your life, see you at the top!</p>
                      <br></br>
                      <p>
                        Start accumulating JGK and Stay tuned for ways to
                        leverage your JGK balance in your favor.
                      </p>
                      <br></br>
                      <p>See your JGK Credits below</p>
                    </span>
                  </div>
                )}
              </div>
            )}
            prefix={'JGK'}
          />
        </span>
      </div>
    </div>
    // </div>
  );
}
