import { useState, useEffect, useRef } from 'react';
import confetti from 'canvas-confetti';
import playSound from '../utils/playSound';

export const useConfettiText = (
  inputRef,
  mobileView,
  customText,
  particleCount = 200,
  parent,
  isVoiceMemo,
) => {
  const moneySound1 =
    'https://res.cloudinary.com/joegalaxy/video/upload/v1706507635/coin-and-money-bag-5-185266_eb67fk.mp3';

  const [showText, setShowText] = useState(false);
  const [bottom, setBottom] = useState('0px');
  const [left, setLeft] = useState('0px');

  const MESSENGER_VOICE_MEMO_OFFSET_MOBILE = 70;
  const MESSENGER_TEXT_MEMO_OFFSET_MOBILE = 15;
  const OTHER_PARENT_OFFSET_MOBILE = 60;
  const MESSENGER_VOICE_MEMO_OFFSET_DESKTOP = 50;
  const MESSENGER_TEXT_MEMO_OFFSET_DESKTOP = 40;
  const OTHER_PARENT_OFFSET_DESKTOP = 30;
  const SHARE_OFFSET = 10;
  const MEME_OFFSET_DESKTOP = 0;
  const MEME_OFFSET_MOBILE = 75;
  const LOGIN_OFFSET_DESKTOP = 50;
  const LOGIN_OFFSET_MOBILE = 47;
  const CREATE_AD_OFFSET_DESKTOP = 50;
  const CREATE_AD_OFFSET_MOBILE = 70;
  const SHAREJG_OFFSET_DESKTOP = 50;
  const SHAREJG_OFFSET_MOBILE = 70;
  const INSTALL_OFFSET_DESKTOP = 80;
  const INSTALL_OFFSET_MOBILE = 75;

  const calculateBottomPosition = (rect, mobileView, parent, isVoiceMemo) => {
    // console.log(parent);
    let offset;
    if (mobileView) {
      offset =
        parent === 'messenger'
          ? isVoiceMemo
            ? MESSENGER_VOICE_MEMO_OFFSET_MOBILE
            : MESSENGER_TEXT_MEMO_OFFSET_MOBILE
          : parent === 'share'
          ? SHARE_OFFSET
          : parent === 'meme'
          ? MEME_OFFSET_MOBILE
          : parent === 'login'
          ? LOGIN_OFFSET_MOBILE
          : parent === 'createAd'
          ? CREATE_AD_OFFSET_MOBILE
          : parent === 'shareJGButton'
          ? SHAREJG_OFFSET_MOBILE
          : parent === 'installButton'
          ? INSTALL_OFFSET_MOBILE
          : OTHER_PARENT_OFFSET_MOBILE;
    } else {
      offset =
        parent === 'messenger'
          ? isVoiceMemo
            ? MESSENGER_VOICE_MEMO_OFFSET_DESKTOP
            : MESSENGER_TEXT_MEMO_OFFSET_DESKTOP
          : parent === 'share'
          ? SHARE_OFFSET
          : parent === 'meme'
          ? MEME_OFFSET_DESKTOP
          : parent === 'login'
          ? LOGIN_OFFSET_DESKTOP
          : parent === 'createAd'
          ? CREATE_AD_OFFSET_DESKTOP
          : parent === 'shareJGButton'
          ? SHAREJG_OFFSET_DESKTOP
          : parent === 'installButton'
          ? INSTALL_OFFSET_DESKTOP
          : OTHER_PARENT_OFFSET_DESKTOP;
    }
    // console.log(offset);
    return `${window.innerHeight - rect.bottom + offset}px`;
  };

  const timeoutId = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  // useEffect(() => {
  //   if (showText) {
  //     console.log('showText', showText, customText);
  //   }
  // }, [showText]);

  const showConfettiText = () => {
    // console.log('showConfettiText', inputRef.current);
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      const bottomPosition = calculateBottomPosition(
        rect,
        mobileView,
        parent,
        isVoiceMemo,
      );
      const leftPosition = `${rect.left + rect.width / 2}px`;

      setBottom(bottomPosition);
      if (parent === 'meme') {
        const memeleft = parseFloat(leftPosition) - 5;
        setLeft(`${memeleft}px`);
      } else {
        setLeft(leftPosition);
      }

      const originX = rect.left + rect.width / 2;
      const originY = rect.top + rect.height / 2;
      const confettiOrigin = {
        x: originX / window.innerWidth,
        y: originY / window.innerHeight,
      };
      // console.log(showText);
      setShowText(true);
      // console.log(showText + ' after');
      confetti({
        particleCount: particleCount,
        startVelocity: 30,
        spread: 360,
        origin: confettiOrigin,
      });

      playSound(moneySound1);

      timeoutId.current = setTimeout(() => {
        setShowText(false);
      }, 3000);
    }
  };

  const ConfettiTextComponent = () => (
    <div
      style={{
        display: showText ? 'block' : 'none',
        color: '#8a2be2',
        fontSize: '13px',
        position: 'fixed',
        bottom: bottom,
        left: left,
        transform: 'translateX(-50%)',
        zIndex: 9999,
      }}
    >
      {customText ? customText : '+4 JGK Yeah!'}
    </div>
  );

  return [showConfettiText, ConfettiTextComponent];
};
