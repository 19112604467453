import { createContext } from 'react';

import { io } from 'socket.io-client';

const SOCKET_URL = 'https://cubesocket.azurewebsites.net';

export const audio = new Audio(
  'https://res.cloudinary.com/joegalaxy/video/upload/v1658385502/Whatsapp_Message_-_QuickSounds.com_u2jgir.mp3',
);

export const snakeAudio = new Audio(
  'https://res.cloudinary.com/joegalaxy/video/upload/q_auto/v1670911462/snake_sflx2f.mp3',
);

export const dreamerAudio = new Audio(
  'https://res.cloudinary.com/joegalaxy/video/upload/v1671776001/Images/dreamer-88881_dfyepq.mp3',
);

export const socket = io(SOCKET_URL);
export const SocketContext = createContext();
