import axios from 'axios';

const axiosConn = axios.create();

//console.log(process.env.NODE_ENV);
// if (process.env.NODE_ENV === 'development') {
//axiosConn.defaults.baseURL = process.env.REACT_APP_API_BASE_URL_LOCAL;
// } else {
axiosConn.defaults.baseURL = process.env.REACT_APP_AZURE_API_BASE_URL;
// } else {
//    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_TEST;
// }

export default axiosConn;
