// utils.js
const useUIEffects = () => {
  const reduceOpacity = (setOpacity) => {
    setOpacity((prev) => {
      return { opacity: prev.opacity - 0.5 };
    });
  };

  const fadeOut = (setOpacity, closeContextMenu) => {
    let intervalID = setInterval(() => {
      reduceOpacity(setOpacity);
    }, 50);

    setTimeout(() => {
      clearInterval(intervalID);
      closeContextMenu();
    }, 1000);
  };

  const handleContextMenuUI = (e, setContextMenu, setOpacity, fadeOut) => {
    e.preventDefault();
    const { pageX, pageY } = e;
    // console.log(pageX, pageY);
    setContextMenu({
      x: pageX,
      y: pageY,
      show: (prev) => !prev,
    });
    setTimeout(
      () =>
        fadeOut(setOpacity, () =>
          setContextMenu({
            x: 0,
            y: 0,
            show: false,
          }),
        ),
      2000,
    );
    setOpacity({ opacity: 1 });
  };

  const closeContextMenu = (setContextMenu) => {
    setContextMenu({
      x: 0,
      y: 0,
      show: false,
    });
  };

  const handleTooltip = (setOpen) => {
    const close = () => setOpen(false);
    const open = () => setOpen(true);
    return { open, close };
  };

  return { handleContextMenuUI, closeContextMenu, handleTooltip, fadeOut };
};
// Corrected export statement
export default useUIEffects;
