import './contextMenu.css';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { useState, useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import axiosConn from '../../../axiosConn';
import { SocketContext } from '../../../context/socket';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { Link } from 'react-router-dom';

export default function ContextMenu({
  x,
  y,
  closeContextMenu,
  conversation,
  handleTooltipClose,
  setMessages,
  os,
  setCurrentChat,
  setShowUnReadMessages,
  parent,
  messageId,
  fwiend,
}) {
  const { player } = useContext(AuthContext);
  const socket2 = useContext(SocketContext);

  const [visible, setVisible] = useState(0);

  const handleDeleteAllConversationMessages = async () => {
    //console.log(conversation);

    try {
      const res = await axiosConn.delete('/messages/' + conversation._id, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      // console.log(res.data);
      // setMessages(res.data);
      //console.log(res);
    } catch (err) {
      console.log(err);
    }
    setMessages([]);
    //emit Clear.
    const receiverId = conversation?.members.find(
      (member) => member !== player.data.player._id,
    );
    //console.log(receiverId);
    try {
      socket2.emit('sendClearChat', {
        receiverId,
        conversationId: conversation._id,
      });
    } catch (err) {
      console.log(err);
    }
    // other side will setMessages([])
    // when Clear emitted.
  };

  // console.log(os, parent, messageId);
  return (
    <div onHover={handleTooltipClose()} className="contextmenu">
      {visible === 0 &&
        (os === 'iOS' ? (
          <div>
            {parent !== 'message' && (
              <>
                <div
                  className="mmenu_span2"
                  onClick={() => {
                    setCurrentChat(conversation);
                    setShowUnReadMessages(false);
                  }}
                >
                  <SmsOutlinedIcon />
                  Chat
                </div>
                <div className="contextmenu_splitter"></div>
                {fwiend && fwiend.playerId && (
                  <Link
                    to={`/profile/${fwiend?.playerId}`}
                    className="contextmenu_link"
                  >
                    <div className="vertical_layout">
                      <PersonOutlineIcon />
                      <div className="mmenu_col">
                        <div className="mmenu_span2">Profile</div>
                      </div>
                    </div>
                  </Link>
                )}
                <div className="contextmenu_splitter"></div>
                <div
                  onClick={() => {
                    handleDeleteAllConversationMessages();
                  }}
                >
                  <ClearAllOutlinedIcon />
                  <div className="mmenu_col">
                    <div className="mmenu_span2">Clear</div>
                  </div>
                </div>
              </>
            )}
            {/* {parent === 'message' && (
              <>
                <div
                  onClick={() => {
                    // Assuming messageId is available or passed as a prop
                    handleDeleteMessage(messageId);
                  }}
                >
                  <ClearAllOutlinedIcon />
                  <div className="mmenu_col">
                    <div className="mmenu_span2">Delete</div>
                  </div>
                </div>
              </>
            )} */}
          </div>
        ) : (
          <div>
            {parent !== 'message' && (
              <>
                {fwiend && fwiend.playerId && (
                  <Link
                    to={`/profile/${fwiend?.playerId}`}
                    className="contextmenu_link"
                  >
                    <div className="vertical_layout">
                      <PersonOutlineIcon />
                      <div className="mmenu_col">
                        <div className="mmenu_span2">Profile</div>
                      </div>
                    </div>
                  </Link>
                )}
                <div className="contextmenu_splitter"></div>

                <div
                  onClick={() => {
                    handleDeleteAllConversationMessages();
                  }}
                >
                  <ClearAllOutlinedIcon />
                  <div className="mmenu_col">
                    <div className="mmenu_span2">Clear</div>
                  </div>
                </div>
              </>
            )}
            {/* {parent === 'message' && (
              <>
                <div
                  onClick={() => {
                    // Assuming messageId is available or passed as a prop
                    handleDeleteMessage(messageId);
                  }}
                >
                  <ClearAllOutlinedIcon />
                  <div className="mmenu_col">
                    <div className="mmenu_span2">Delete</div>
                  </div>
                </div>
              </>
            )} */}
          </div>
        ))}
    </div>
  );
}
