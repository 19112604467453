import './adRotator.css';
import { useState, useEffect, useContext, memo, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Ad from '../ad/Ad';
import arePropsEqual from '../../arePropsEqual';
import axiosConn from '../../axiosConn';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

export default memo(function AdRotator() {
  const [randomNum, setRandomNum] = useState(0);
  const [adItems, setAdItems] = useState([]);
  const { player } = useContext(AuthContext);
  const adItemsRef = useRef(adItems);
  const location = useLocation();

  useEffect(() => {
    adItemsRef.current = adItems;
  }, [adItems]);

  const getAds = async () => {
    try {
      const res = await axiosConn.get('/ads/', {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      return res.data.data.data;
    } catch (err) {
      console.log(err);
      // Handle error here
    }
  };

  const { isLoading, data, status } = useQuery(['getAds'], getAds, {
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  useEffect(() => {
    let mounted = true;

    const handleVisibilityChange = () => {
      if (!document.hidden && mounted && adItemsRef.current) {
        setRandomNum(Math.floor(Math.random() * adItemsRef.current.length));
      }
    };

    if (status === 'success' && mounted) {
      setAdItems(data);
      handleVisibilityChange();
    }

    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      mounted = false;
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [status, data]);

  useEffect(() => {
    if (status === 'success' && data) {
      setRandomNum(Math.floor(Math.random() * data.length));
    }
  }, [location.pathname, status, data]);

  return (
    <div className="adRotatorSelfCentering">
      <div
        className="adRotatorContainerElement2"
        id="adRotatorContainerElement2"
      >
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          adItems &&
          adItems[randomNum] &&
          adItems.length > randomNum && <Ad ad={adItems[randomNum]} />
        )}
      </div>
    </div>
  );
}, arePropsEqual);
