const Logoff = (jgkTokens, unlockGalaxyJGKAmount) => {
  let from = localStorage.getItem('from');

  // Explicitly check for both null and "null"
  if (from === null || from === 'null') {
    // console.log('from is null');
    from = '/login';
  }

  // console.log('Logoff from', from);
  window.location.href = from;
  localStorage.removeItem('player');
  if (jgkTokens >= unlockGalaxyJGKAmount) {
    localStorage.removeItem('originFrom');
  }
};

export default Logoff;
