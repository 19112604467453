// galaxyUtils.js
import axiosConn from '../axiosConn';

export const isPlayerAuthorized = (galaxy, player) => {
  return (
    (galaxy.owners || []).some(
      (owner) => owner._id === player.data.player._id,
    ) ||
    (galaxy.administrators || []).some(
      (admin) => admin._id === player.data.player._id,
    ) ||
    (galaxy.moderators || []).some(
      (moderator) => moderator._id === player.data.player._id,
    )
  );
};

export const handleRemoveSubscriber = async (
  galaxy,
  player,
  subscriberId,
  setSubscribers,
  subscribers,
) => {
  //   console.log(
  //     'handleRemoveSubscriber',
  //     galaxy,
  //     player,
  //     subscriberId,
  //     setSubscribers,
  //     subscribers,
  //   );
  if (isPlayerAuthorized(galaxy, player)) {
    try {
      await axiosConn.delete(`/galaxies/${galaxy._id}/remove/${subscriberId}`, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      setSubscribers(
        subscribers.filter((subscriber) => subscriber._id !== subscriberId),
      );
    } catch (error) {
      console.error('Error removing subscriber:', error);
    }
  }
};
