function sendNotification(message, playerId) {
  //   console.log('sendNotification top');
  document.onvisibilitychange = () => {
    if (document.hidden) {
      //   console.log('document is hidden');
      const notification = new Notification(
        'New message from Joe Galaxy Chat',
        {
          icon: 'https://res.cloudinary.com/joegalaxy/image/upload/v1677560210/android-chrome-512x512_wbtvj3.png',
          body: `@${playerId}: ${message}`,
        },
      );
      notification.onclick = () =>
        function () {
          //   console.log('https://www.joegalaxy.net/messenger/\\"');
          //   window.open('https://www.joegalaxy.net/messenger/');
        };
    } else {
      //   console.log('document not hidden');
    }
  };
}

export default function checkPageStatus(message, senderPlayerId) {
  const loggedInPlayer = JSON.parse(localStorage.getItem('player'));
  //   console.log(message, senderPlayerId, loggedInPlayer.data.player.playerId);
  if (
    loggedInPlayer &&
    senderPlayerId !== loggedInPlayer.data.player.playerId
  ) {
    // console.log('sender, receiver are not equal');
    if (!('Notification' in window)) {
      alert('This browser does not support system notifications!');
    } else if (Notification.permission === 'granted') {
      sendNotification(message, senderPlayerId);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          sendNotification(message, senderPlayerId);
        }
      });
    }
  }
}
