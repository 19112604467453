import './memeRotator.css';
import { useState, useEffect, useContext, memo } from 'react';
import { AuthContext } from '../../context/AuthContext';
import useMobileView from '../../hooks/useMobileView';

import Meme from '../meme/Meme';
import arePropsEqual from '../../arePropsEqual';
import axiosConn from '../../axiosConn';

import { CircularProgress } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useGetMemes } from '../../hooks/useGetMemes';
export default memo(function MemeRotator() {
  const [isFetching, setIsFetching] = useState(false);
  const [randomNum, setRandomNum] = useState(0);
  const [memeItems, setMemeItems] = useState([]);
  const { player } = useContext(AuthContext);
  const [randomMeme, setRandomMeme] = useState(null);
  const mobileView = useMobileView();

  // console.log('MemeRotator rendered');

  useEffect(() => {
    let mounted = true;
    setIsFetching(true);
    const fetchRandomMeme = async () => {
      try {
        const response = await axiosConn.get('/memes/getRandomMeme', {
          headers: { Authorization: `Bearer ${player.token}` },
        });
        if (!mounted) return;
        setRandomMeme(response.data);
        setIsFetching(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchRandomMeme();
    return () => {
      mounted = false;
    };
  }, [player.token]);

  return (
    <>
      <div
        className={
          !mobileView
            ? 'memeRotatorContainerElement'
            : 'memeRotatorContainerElementMobile'
        }
        id="memeRotatorContainerElement"
      >
        {!randomMeme ? (
          <div className="feedPostProgress">
            <CircularProgress color="inherit" size="10px" />
          </div>
        ) : (
          <Meme key={randomMeme._id} meme={randomMeme} />
        )}
      </div>
    </>
  );
}, arePropsEqual);
