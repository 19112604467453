// src/utils/suspensionUtils.js
import axios from 'axios';

export const handleSuspension = async (
  actionTimestamps,
  setActionTimestamps,
  setIsSuspended,
  ACTION_LIMIT,
  TIME_FRAME,
  SUSPENSION_TIME,
  id, // Add id as a parameter
) => {
  //   console.log('handleSuspension');
  const now = Date.now();
  const recentActions = actionTimestamps.filter(
    (timestamp) => now - timestamp < TIME_FRAME,
  );

  if (recentActions.length >= ACTION_LIMIT) {
    // console.log(recentActions.length);
    setIsSuspended(true);
    setTimeout(() => setIsSuspended(false), SUSPENSION_TIME);

    // Increment the suspension count
    try {
      await axios.put(`/api/incrementSuspensionCount/${id}`);
    } catch (error) {
      console.error('Error incrementing suspension count:', error);
    }
    // console.log('returning true');
    return true; // Indicate that the user is suspended
  }

  // Add the current timestamp to the list of action timestamps
  setActionTimestamps([...recentActions, now]);
  //   console.log('returning false');
  return false; // Indicate that the user is not suspended
};
