import './dynamichtml.css';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player/lazy';

export default function DynamicHtml({
  from,
  text,
  own,
  setMessageTextIsVideo,
  senderProfilePhoto,
  receiverProfilePhoto,
}) {
  //const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const [htmlMessage, setHtmlMessage] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  const detectURLs = (message) => {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message?.match(urlRegex);
  };

  const replaceURLs = (message) => {
    if (!message) return;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return (
        '<a href="' +
        hyperlink +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        '</a>'
      );
    });
  };

  useEffect(() => {
    //detect if message.text has urls.
    const urlArray = detectURLs(text);
    //if yes, replaceURLs with a clickable link.
    if (urlArray) {
      // console.log(urlArray);
      const html = replaceURLs(text);
      // determine if url is a url to a video or image.
      if (
        html.includes('youtube.com') ||
        html.includes('youtu.be') ||
        html.includes('soundcloud.com') ||
        html.includes('vimeo.com') ||
        html.includes('twitch.com') ||
        html.includes('streamable.com') ||
        html.includes('wistia.com') ||
        html.includes('dailymotion.com') ||
        html.includes('mixcloud.com') ||
        html.includes('vidyard.com') ||
        // html.includes('brighteon.com') ||
        html.includes('kaltura.com')
      ) {
        // if yes, setIsVideo(true);
        setIsVideo(true);
        setMessageTextIsVideo(true);
        // console.log('isVideo true');
      } else {
        // or setIsImage(true)
        const length = html.split('.')?.length;
        // console.log(html);
        if (
          length > 1 &&
          (html.split('.')[length - 1].slice(-3).includes('jpg') ||
            html.split('.')[length - 1].slice(-3).includes('webp') ||
            html.split('.')[length - 1].slice(-3).includes('gif') ||
            html.split('.')[length - 1].slice(-3).includes('png'))
        ) {
          setIsImage(true);
          // console.log(html);
        } else if (
          html.split('.')[length - 1].slice(-3) === 'mp4' ||
          html.split('.')[length - 1].slice(-3) === 'mov'
        ) {
          setIsVideo(true);
          setMessageTextIsVideo(true);
        }
      }
      // then render video or url like in Meme.
      // this will work for messages or posts.
      //console.log(html);

      setHtmlMessage(html);
    } else {
      setHtmlMessage(text);
    }
    return () => {
      setIsVideo(false);
      setIsImage(false);
    };

    //set htmlMessage to the message with clickable links.
  }, [setMessageTextIsVideo, text]);

  const playerProps = {
    autoPlay: true,
    loop: true,
    muted: false,
    playing: false,
    controls: true,
    url: htmlMessage,
  };

  // console.log(own, from, isVideo, isImage, htmlMessage, playerProps);
  return (
    <>
      {/* {from === 'message' ?  */}
      {/* ( */}
      <>
        <div
          className={from === 'post' ? null : own ? 'message own' : 'message'}
        >
          {isVideo ? (
            from !== 'message' ? (
              <div
                style={{
                  position: 'relative',
                  paddingTop: '56.25%' /* for 16:9 aspect ratio */,
                  width: '100%',
                  // maxWidth: '500px',
                  // height: 'auto',
                }}
              >
                <ReactPlayer
                  style={{ position: 'absolute', top: 0, left: 0 }}
                  width="100%"
                  height="100%"
                  {...playerProps}
                />
              </div>
            ) : (
              <ReactPlayer
                width="87%"
                height={!mobileView ? '100%' : '50%'}
                {...playerProps}
              />
            )
          ) : null}
          {isImage ? (
            <img
              className="messageImg2 loadingImg"
              src={`https${text.split('https')[1]}`}
              alt=""
              loading="lazy"
              style={{
                backgroundImage: `url("https://res.cloudinary.com/joegalaxy/image/upload/v1672717524/loading-gif_sfv2dl.gif")`,
              }}
            />
          ) : null}
          <div
            className={
              !mobileView
                ? isVideo || isImage
                  ? 'messageTopImgVid'
                  : 'messageTop'
                : 'messageTopMobile'
            }
          >
            {from === 'message' && (
              <img
                className={!mobileView ? 'messageImg' : 'messageImgMobile'}
                src={
                  own
                    ? senderProfilePhoto
                      ? senderProfilePhoto
                      : // receiver?.profilePhoto
                        //   ? receiver.profilePhoto
                        //   : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                        'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                    : receiverProfilePhoto
                    ? receiverProfilePhoto
                    : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                }
                alt=""
              />
            )}
            {from === 'galaxyChat' && (
              <img
                className={!mobileView ? 'messageImg' : 'messageImgMobile'}
                src={
                  senderProfilePhoto
                    ? senderProfilePhoto
                    : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                }
                alt=""
              />
            )}
            {(from === 'message' || from === 'feed' || 'galaxyChat') &&
              from !== 'post' &&
              htmlMessage && (
                <p
                  className={
                    !mobileView
                      ? own
                        ? 'messageText'
                        : 'messageTextNotOwn'
                      : own
                      ? 'messageTextMobile'
                      : 'messageTextMobileNotOwn'
                  }
                  dangerouslySetInnerHTML={{ __html: htmlMessage }}
                />
              )}
          </div>
          {from === 'post' && (
            <span
              className={!mobileView ? 'postText' : 'postTextMobile'}
              dangerouslySetInnerHTML={{ __html: htmlMessage }}
            />
          )}
        </div>
      </>
      {/* ) : ( */}
      {/* <span
          className={!mobileView ? 'postText' : 'postTextMobile'}
          dangerouslySetInnerHTML={{ __html: htmlMessage }}
        /> */}
      {/* ) */}
      {/* } */}
    </>
  );
}
