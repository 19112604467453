import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Search } from '@material-ui/icons';
import ReactMapGL, {
  MapboxGL,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Marker,
  Popup,
} from 'react-map-gl';
// import mapboxgl from '!mapbox-gl';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
// import mapboxgl from '!mapbox-gl';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';
import Topbar from '../../components/topbar/Topbar';
import TopbarPlayerMap from '../../components/topbarPlayerMap/TopbarPlayerMap';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';
import { Link } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { getLocationAndSave } from '../../utils/locationUtil';

import { useMediaQuery } from 'react-responsive';
import './playermap.css';

export default function PlayerMapPage() {
  const { player } = useContext(AuthContext);

  const [latitude, SetLatitude] = useState(null);
  const [longitude, SetLongitude] = useState(null);
  const [accuracy, SetAccuracy] = useState(null);
  const [timestamp, SetTimestamp] = useState(null);
  const [radius, setRadius] = useState(5000);
  const [unit, setUnit] = useState('mi');
  const [playersWithin, setPlayersWithin] = useState({});
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [zoom, setZoom] = useState(4);
  const [getPosition, setGetPosition] = useState(true);
  //   const [address, SetAddress] = useState('');
  const [viewport, setViewport] = useState({
    latitude: 25.774772,
    longitude: -80.185942,
    zoom: 4,
    width: '100vw',
    height: '100vh',
    // center: [longitude, latitude],
  });
  const offset = 0.001; // Offset value for staggering markers

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  const error = () => {
    console.log('error geolocation called.');
    setGetPosition(false);
  };

  useEffect(() => {
    let mounted = true;
    const options = {
      enableHighAccuracy: true,
    };
    const getData = async () => {
      if (!mounted) return;
      await getLocationAndSave(
        SetLatitude,
        SetLongitude,
        SetAccuracy,
        SetTimestamp,
        // setViewport,
        player,
      ).then(async () => {
        const latitude = JSON.parse(secureLocalStorage.getItem('latitude'));
        const longitude = JSON.parse(secureLocalStorage.getItem('longitude'));
        // console.log(latitude, longitude);
        // console.log(radius);
        try {
          if (
            !latitude ||
            !longitude ||
            !getPosition ||
            radius < 0 ||
            radius === ''
          ) {
            console.log(
              'null latitude and longitude or location not shared or radius not positive',
            );
            return;
          }
          const res = await axiosConn.get(
            `/players/players-within/${radius}/center/${latitude},${longitude}/unit/${
              unit ? unit : 'mi'
            }`,
            {
              headers: {
                Authorization: `Bearer ${player.token}`,
              },
            },
          );
          // console.log(
          //   res.data.data.data,
          // .filter((playerWithin) =>
          //   player.data.player.friends.includes(playerWithin._id),
          // ),
          // );
          if (!mounted) return;
          setPlayersWithin(
            res.data.data.data,
            // .filter((playerWithin) =>
            //   player.data.player.friends.includes(playerWithin._id),
            // ),
          );
          // console.log(res.data.data.data);
          secureLocalStorage.setItem(
            'playersWithin',
            JSON.stringify(res.data.data.data),
          );
        } catch (err) {
          console.log(err);
        }
      });
      // Schedule the next update
      setTimeout(getData, 5000); // 5 seconds
    };
    getData();
    return () => {
      mounted = false;
      // secureLocalStorage.setItem('playersWithin', null);
    };
  }, [
    latitude,
    longitude,
    player.token,
    radius,
    unit,
    playersWithin.length,
    player.data.player.friends,
    getPosition,
  ]);

  //   console.log(latitude, longitude, accuracy, timestamp);

  const playersWithin2 = JSON.parse(
    secureLocalStorage.getItem('playersWithin'),
  );
  // console.log(playersWithin2);

  useEffect(() => {
    const listener = (e) => {
      if (e.key === 'Escape') {
        setSelectedPlayer(null);
      }
    };
    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, []);

  useEffect(() => {
    const updateViewport = () => {
      const latitude = JSON.parse(secureLocalStorage.getItem('latitude'));
      const longitude = JSON.parse(secureLocalStorage.getItem('longitude'));
      setViewport((prevViewport) => ({
        ...prevViewport,
        latitude,
        longitude,
      }));
    };

    updateViewport();
  }, [latitude, longitude]);

  return (
    <>
      <div>
        <Topbar parent={'PlayerMap'} />
        <TopbarPlayerMap
          radius={radius}
          setRadius={setRadius}
          unit={unit ? unit : 'mi'}
          setUnit={setUnit}
          parent={'PlayerMap'}
          setZoom={setZoom}
          getPosition={getPosition}
        />
      </div>
      {/* {mobileView && <TopbarNotification />} */}
      <div className="full">
        <ReactMapGL
          {...viewport}
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          mapStyle="mapbox://styles/joegalaxydotnet/clmquop4a04vw01phe5fi2sdq"
          onMove={(event) => {
            setViewport(event.viewport);
          }}
          // zoom={zoom}
        >
          <NavigationControl
            onViewportChange={(event) => {
              setViewport(event.viewport);
            }}
            position="top-left"
            // visualizePitch={true}
          />
          <GeolocateControl
            position="top-left"
            trackUserLocation={true}
            showUserHeading={true}
            showUserLocation={true}
            showAccuracyCircle={true}
            onTrackUserLocationStart={(evt) => {
              // console.log(evt);
              //setLatitude
              //setLongitude
            }}
          />
          <FullscreenControl position="top-left" />
          <ScaleControl
            unit={unit === 'mi' || unit === '' ? 'imperial' : 'metric'}
          />
          <Link
            to={`/profile/${player.data.player?.playerId}`}
            className="mmenu_header hover3"
          >
            <Marker latitude={latitude} longitude={longitude}>
              <button className="marker-btn2">
                <img
                  // src="https://res.cloudinary.com/joegalaxy/image/upload/v1695177384/Map_marker_fymyi0.svg"
                  src={
                    player.data.player?.profilePhoto
                      ? player.data.player?.profilePhoto
                      : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                  }
                  alt={player.data.player?.playerId}
                  title={player.data.player?.playerId}
                />
              </button>
            </Marker>
          </Link>
          {/* SetMyOnlineFriends(myFriends.filter((f) =>
          onlinePlayers.includes(f._id))); */}
          {/* playersWithin2.map((player) => ( */}
          {getPosition &&
            playersWithin2?.length !== 0 &&
            playersWithin2 !== null &&
            playersWithin2
              .sort((a, b) => a.jgkTokens - b.jgkTokens)
              .filter((playerWithin) =>
                player.data.player.friends.includes(playerWithin._id),
              )
              .map((player, index) => (
                <Link
                  to={`/profile/${player?.playerId}`}
                  className="mmenu_header hover3"
                  key={player._id}
                >
                  <Marker
                    key={player._id}
                    longitude={player.lastLocation.coordinates[0]}
                    latitude={player.lastLocation.coordinates[1]}
                  >
                    <button
                      className="marker-btn2"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedPlayer(player);
                      }}
                    >
                      <img
                        //   src="https://res.cloudinary.com/joegalaxy/image/upload/v1695177384/Map_marker_fymyi0.svg"
                        src={
                          player.profilePhoto
                            ? player.profilePhoto
                            : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                        }
                        alt={player.playerId}
                        title={player.playerId}
                      />
                    </button>
                  </Marker>
                </Link>
              ))}
          {selectedPlayer ? (
            <Popup
              longitude={selectedPlayer.lastLocation.coordinates[0]}
              latitude={selectedPlayer.lastLocation.coordinates[1]}
              onClose={() => {
                setSelectedPlayer(null);
              }}
            >
              <div>
                <Link
                  to={`/profile/${selectedPlayer?.playerId}`}
                  className="mmenu_header hover3"
                >
                  <h2>{selectedPlayer.playerId}</h2>
                </Link>
                <p>{selectedPlayer.desc}</p>
              </div>
              <img
                width="100%"
                src={
                  selectedPlayer.coverPhoto
                    ? selectedPlayer.coverPhoto
                    : 'https://gp1.wac.edgecastcdn.net/802892/http_public_production/profile_cover_photos/images/1242299/original/crop:x0y0w1920h746/quality:90/hash:1465227418/A-Screen_shot_2016-05-13_at_4.30.51_PM_copy.png?1465227418'
                }
                alt={selectedPlayer.playerId}
              />
            </Popup>
          ) : null}
        </ReactMapGL>
      </div>
    </>
  );
}
// return await Promise.resolve(
//   //get latitude, longitude
//   navigator.geolocation.getCurrentPosition((position) => {
//     // console.log(position);
//     if (!mounted) return;

//     SetLatitude(position.coords.latitude);
//     SetLongitude(position.coords.longitude);
//     SetAccuracy(position.coords.accuracy);
//     SetTimestamp(position.timestamp);
//     setViewport({
//       latitude: position.coords.latitude,
//       longitude: position.coords.longitude,
//     });

//     secureLocalStorage.setItem(
//       'latitude',
//       JSON.stringify(position.coords.latitude),
//     );
//     secureLocalStorage.setItem(
//       'longitude',
//       JSON.stringify(position.coords.longitude),
//     );

//     try {
//       //only update if new coordinates
//       if (latitude && longitude) {
//         // const url = `http://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
//         // fetch(url)
//         //   .then((response) => response.json())
//         //   .then((data) =>
//         //     console.log(
//         //       data,
//         //       data.address?.city,
//         //       data.address?.region,
//         //       data.address?.road,
//         //       data.address?.state,
//         //       data.address?.postcode,
//         //     ),
//         //   );
//         axiosConn.put(
//           '/players/lastLocation',
//           {
//             latitude: position.coords.latitude,
//             longitude: position.coords.longitude,
//             accuracy: position.coords.accuracy,
//             timestamp: position.timestamp,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${player.token}`,
//             },
//           },
//         );
//       }
//       // else {
//       //   console.log(latitude, longitude);
//       // }
//     } catch (err) {
//       console.log(err.message);
//     }
//   }, error),
// )
