import isVideoFile from './isVideoFile';

// handleFileInput.js
const handleFileInput = (e, setError, setFile, maxVideoSize, maxImageSize) => {
  const file = e.target.files[0];
  if (!file) {
    return;
  }

  const validTypes = [
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/gif',
    'video/mp4',
    'video/quicktime', // MIME type for .mov files
  ];

  const isVideo = isVideoFile(file.name.toLowerCase());

  if (!validTypes.includes(file.type)) {
    setError(`${file.name} format is not supported.`);
    return;
  }

  if (isVideo && file.size > maxVideoSize) {
    setError(
      `${file.name} is too large, max ${
        maxVideoSize / (1024 * 1024)
      }mb allowed.`,
    );
    return;
  }

  if (!isVideo && file.size > maxImageSize) {
    setError(
      `${file.name} is too large, max ${
        maxImageSize / (1024 * 1024)
      }mb allowed.`,
    );
    return;
  }

  setFile(file);
};

export default handleFileInput;
