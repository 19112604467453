// ChatBoxBottom.jsx
import { useContext } from 'react'; // import { Picker } from 'emoji-mart';
// import 'emoji-mart/css/emoji-mart.css';
import Picker from 'emoji-picker-react';
import useMobileView from '../../hooks/useMobileView';
import { ViewPortContext } from '../../context/ViewPortContext';
import './chatBoxBottom.css';
import { ClipLoader } from 'react-spinners';

export default function ChatBoxBottom({
  voiceMemo,
  picker,
  menu,
  handleEmoji,
  textRef,
  register,
  text,
  setText,
  handleSubmit,
  loading,
  sendingFile,
  handleMicClick,
  isRecording,
  setPicker,
  fileInput,
  setSendingFile,
  //   width,
  handleSendAudioMemo,
}) {
  const mobileView = useMobileView();
  const { width } = useContext(ViewPortContext);

  return (
    <div className="chatBoxBottom">
      <div
        className={
          !mobileView ? 'comment_input_wrap' : 'chatMessage_wrapMobile'
        }
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {picker && (
          <div className="comment_emoji_picker" ref={menu}>
            <Picker onEmojiClick={handleEmoji} />
          </div>
        )}
        <div className="flexGrowContainer">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            style={{ flexGrow: 1 }}
          >
            <input
              type="text"
              ref={!mobileView ? textRef : register}
              value={text}
              placeholder="Message"
              onChange={(e) => setText(e.target.value)}
              // onKeyUp={handleSubmit}
              disabled={voiceMemo}
              onKeyDown={(e) => {
                if ((e.key === 'Enter' || e.key === 'Tab') && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              className="fullWidth"
              maxLength={11000}
            />
          </form>
        </div>

        <div className="comment_circle" style={{ marginTop: '5px' }}>
          <ClipLoader size={10} color="#1876f2" loading={loading} />
        </div>
        {
          <div
            className={
              sendingFile
                ? 'comment_circle_icon hover2 noClicks'
                : 'comment_circle_icon hover2'
            }
            onClick={handleMicClick}
          >
            <i
              className={
                !mobileView
                  ? isRecording
                    ? 'microphone_iconPlaying'
                    : 'microphone_icon'
                  : 'microphone_iconMobile'
              }
              id="mic"
            ></i>
          </div>
        }
        {!mobileView && !voiceMemo && (
          <div
            className="comment_circle_icon hover2"
            onClick={() => {
              setPicker((prev) => !prev);
            }}
          >
            <i className="emoji_icon"></i>
          </div>
        )}
        {!voiceMemo && (
          <div
            className="comment_circle_icon hover2"
            onClick={() => {
              fileInput.current.click();
              setSendingFile(true);
            }}
          >
            <i className="addPhoto_icon"></i>
          </div>
        )}
      </div>
      {width >= 418 && (
        <button
          className="chatSubmitButton"
          onClick={voiceMemo ? handleSendAudioMemo : handleSubmit}
        >
          Send
        </button>
      )}
    </div>
  );
}
