// playSound.js

const playSound = (url) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio(url);
    audio.onended = resolve; // Resolve the promise when the audio ends
    audio.onerror = reject; // Reject the promise if an error occurs
    audio.play();
  });
};

export default playSound;
