import './galaxyRightbarMobile.css';
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
  memo,
} from 'react';
import axiosConn from '../../axiosConn';
import { AuthContext } from '../../context/AuthContext';
import { SocketContext } from '../../context/socket';
import useMobileView from '../../hooks/useMobileView';
import { Link } from 'react-router-dom';
import arePropsEqual from '../../arePropsEqual';
import {
  isPlayerAuthorized,
  handleRemoveSubscriber,
} from '../../utils/galaxyUtils';
import { useTheme } from '../../context/ThemeContext';

export default memo(function GalaxyRightBarMobile({
  galaxy: initialGalaxy,
  onJgkTokenSumChange,
  parent = '',
  galaxyChatOnlinePlayers,
}) {
  const galaxy = useMemo(() => initialGalaxy, [initialGalaxy]);

  const [subscribers, setSubscribers] = useState([]);
  const [galaxyOnlinePlayers, setGalaxyOnlinePlayers] = useState([]);
  const { player } = useContext(AuthContext);
  const socket = useContext(SocketContext);
  const mobileView = useMobileView();
  const prevOnlinePlayersRef = useRef([]);
  // const totalTranslation = subscribers ? subscribers.length * 8 : 0;
  const prevSubscribersRef = useRef(); // Create a ref to store the previous subscribers
  const galaxyChatOnlinePlayersRef = useRef(galaxyChatOnlinePlayers);
  const { darkTheme } = useTheme();

  const [galaxyChats, setGalaxyChats] = useState({});
  const isPlayerInGalaxyChat = useCallback(
    (playerId) => {
      if (!galaxyChats || !galaxy._id || galaxyChats.galaxyId !== galaxy._id) {
        return false;
      }
      return galaxyChats.players.some((player) => player.id === playerId);
    },
    [galaxyChats, galaxy._id],
  );

  const initialIsInGalaxyChat = subscribers.reduce((acc, subscriber) => {
    acc[subscriber._id] =
      parent === 'galaxyProfile'
        ? galaxyChatOnlinePlayers.some((player) => player.id === subscriber._id)
        : isPlayerInGalaxyChat(subscriber._id);
    return acc;
  }, {});

  const [isInGalaxyChat, setIsInGalaxyChat] = useState({
    initialIsInGalaxyChat,
  });

  useEffect(() => {
    if (parent === 'galaxyPage') {
      socket.emit('fetchGalaxyChat', galaxy._id);
    }
  }, [parent, socket, galaxy._id]);

  const handleGetGalaxyChat = useCallback((galaxyChat) => {
    setGalaxyChats(galaxyChat);
  }, []);

  useEffect(() => {
    let mounted = true;
    // const handleGetGalaxyChat = (galaxyChat) => {
    //   // console.log('handleGetGalaxyChat: ', galaxyChat);
    //   if (!mounted) return;
    //   setGalaxyChats(galaxyChat);
    // };

    socket.on('getGalaxyChat', handleGetGalaxyChat);

    if (parent === 'galaxyPage') {
      socket.emit('joinGalaxyChat', galaxy._id);
      socket.emit('fetchGalaxyChat', galaxy._id);
    }

    return () => {
      mounted = false;
      socket.off('getGalaxyChat', handleGetGalaxyChat);
      if (parent === 'galaxyPage') {
        socket.emit('leaveGalaxyChat', {
          galaxyId: galaxy._id,
          playerId: player.data.player._id,
        });
      }
    };
  }, [socket, galaxy._id, parent, player.data.player._id, handleGetGalaxyChat]);

  useEffect(() => {
    galaxyChatOnlinePlayersRef.current = galaxyChatOnlinePlayers;
  }, [galaxyChatOnlinePlayers]); // This runs after every render

  useEffect(() => {
    if (parent === 'galaxyPage') return;
    let isMounted = true;

    const fetchSubscribers = async () => {
      try {
        const response = await axiosConn.get(
          `/galaxies/${galaxy._id}/members`,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        if (!isMounted) return;

        if (
          JSON.stringify(response.data.members) !==
          JSON.stringify(prevSubscribersRef.current)
        ) {
          setSubscribers(response.data.members);
          prevSubscribersRef.current = response.data.members;

          const jgkTokenSum = response.data.members.reduce(
            (sum, member) => sum + (Number(member.jgkTokens) || 0),
            0,
          );
          onJgkTokenSumChange(jgkTokenSum);

          // Update isInGalaxyChat state here
          const newIsInGalaxyChat = response.data.members.reduce(
            (acc, subscriber) => {
              acc[subscriber._id] =
                parent === 'galaxyProfile'
                  ? galaxyChatOnlinePlayersRef.current.some(
                      (player) => player.id === subscriber._id,
                    )
                  : isPlayerInGalaxyChat(subscriber._id);
              return acc;
            },
            {},
          );

          // Only update the state if the new isInGalaxyChat is different from the previous isInGalaxyChat
          if (
            JSON.stringify(newIsInGalaxyChat) !== JSON.stringify(isInGalaxyChat)
          ) {
            setIsInGalaxyChat(newIsInGalaxyChat);
          }
        }
      } catch (error) {
        console.error('Failed to fetch subscribers: ', error);
      }
    };

    const handleGetGalaxyChat = (galaxyChat) => {
      if (JSON.stringify(galaxyChats) !== JSON.stringify(galaxyChat)) {
        setGalaxyChats(galaxyChat);
      }
    };

    if (parent === 'galaxyPage') {
      socket.emit('fetchGalaxyChat', galaxy._id);
      socket.on('getGalaxyChat', handleGetGalaxyChat);
    }

    fetchSubscribers();

    return () => {
      isMounted = false;
      if (parent === 'galaxyPage') {
        socket.emit('leaveGalaxyChat', {
          galaxyId: galaxy._id,
          playerId: player.data.player._id,
        });
        socket.off('getGalaxyChat', handleGetGalaxyChat);
      }
    };
  }, [
    galaxy._id,
    player.token,
    onJgkTokenSumChange,
    socket,
    parent,
    player.data.player._id,
    // galaxyChatOnlinePlayers,
    isInGalaxyChat,
  ]);

  useEffect(() => {
    let mounted = true;
    const isPlayerInGalaxyChat = (playerId) => {
      if (!galaxyChats || !galaxy._id || galaxyChats.galaxyId !== galaxy._id) {
        return false;
      }
      return galaxyChats.players.some((player) => player.id === playerId);
    };

    const newIsInGalaxyChat = subscribers.reduce((acc, subscriber) => {
      acc[subscriber._id] =
        parent === 'galaxyProfile'
          ? galaxyChatOnlinePlayers.some(
              (player) => player.id === subscriber._id,
            )
          : isPlayerInGalaxyChat(subscriber._id);
      return acc;
    }, {});

    // Only update the state if the new isInGalaxyChat is different from the previous isInGalaxyChat
    if (JSON.stringify(newIsInGalaxyChat) !== JSON.stringify(isInGalaxyChat)) {
      if (!mounted) return;
      setIsInGalaxyChat(newIsInGalaxyChat);
    }
    return () => {
      mounted = false;
    };
  }, [
    parent,
    galaxyChatOnlinePlayers,
    galaxy._id,
    galaxyChats,
    subscribers,
    isInGalaxyChat,
  ]);

  useEffect(() => {
    let mounted = true;
    socket.emit('addPlayer', player.data.player._id);

    socket.on('getPlayers', (players) => {
      const onlinePlayers = subscribers.filter((f) =>
        players.some((u) => u.playerId === f._id),
      );

      if (
        JSON.stringify(prevOnlinePlayersRef.current) !==
        JSON.stringify(onlinePlayers)
      ) {
        if (!mounted) return;
        // Play sound here
        if (
          JSON.stringify(galaxyOnlinePlayers) !== JSON.stringify(onlinePlayers)
        ) {
          setGalaxyOnlinePlayers(onlinePlayers);
        }
        prevOnlinePlayersRef.current = onlinePlayers;
      }
    });

    return () => {
      socket.off('getPlayer');
      mounted = false;
    };
  }, [player, socket, subscribers]);

  // if (parent === 'galaxyPage') console.log('galaxyChats: ', galaxyChats);
  // Helper function

  return (
    <>
      {subscribers.some(
        (subscriber) => subscriber._id === player.data.player._id,
      ) && (
        <div className="feedFriendCount">
          {subscribers && subscribers.length === 0
            ? ''
            : subscribers.length === 1
            ? '1 Galaxy Subscriber'
            : `${subscribers.length} Galaxy Subscribers`}
        </div>
      )}
      <div className="centerWrapper">
        {subscribers.some(
          (subscriber) => subscriber._id === player.data.player._id,
        ) && (
          <div className="feedFriendImgs">
            {subscribers &&
              subscribers
                .sort((a, b) => b.jgkTokens - a.jgkTokens)
                .map((subscriber, i) => {
                  const isOnline =
                    galaxyOnlinePlayers &&
                    galaxyOnlinePlayers.some(
                      (player) => player.playerId === subscriber.playerId,
                    );

                  if (parent === 'galaxyPage' && !isOnline) {
                    return null;
                  }

                  if (
                    parent === 'galaxyPage' &&
                    !isInGalaxyChat[subscriber._id]
                  ) {
                    return null;
                  }

                  return (
                    <div key={subscriber._id} className="playerWrapper">
                      <Link
                        to={`/profile/${subscriber.playerId}`}
                        key={i}
                        className="linkWrapper"
                        style={{
                          zIndex: `${i}`,
                          margin: '1px',
                        }}
                      >
                        <div className="subscriberWrapper">
                          <img
                            className="subscriberPhoto"
                            src={
                              subscriber.profilePhoto
                                ? subscriber.profilePhoto
                                : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                            }
                            alt={subscriber.playerId}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png';
                            }}
                          />
                          {isOnline && (
                            <div
                              className={
                                !mobileView
                                  ? 'feedChatOnlineBadge'
                                  : 'feedChatOnlineBadgeMobile'
                              }
                            ></div>
                          )}

                          {isInGalaxyChat[subscriber._id] && (
                            <>
                              <div className="galaxyChatOnlineBadgeMobile"></div>
                            </>
                          )}
                        </div>
                      </Link>
                      {isPlayerAuthorized(galaxy, player) && (
                        <button
                          className={
                            darkTheme
                              ? 'xButtonGalaxyPlayerDark'
                              : 'xButtonGalaxyPlayer'
                          }
                          onClick={() => {
                            if (
                              window.confirm(
                                `Are you sure you want to remove ${subscriber.playerId} from ${galaxy.name}?`,
                              )
                            ) {
                              handleRemoveSubscriber(subscriber._id);
                            }
                          }}
                          title={`Remove ${subscriber.playerId}`}
                        >
                          x
                        </button>
                      )}
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </>
  );
},
arePropsEqual);
