// function isExcluded() {
//   const from = localStorage.getItem('from');
//   //   console.log('isExcluded', from);
//   const excludedIds = ['660a1c8149a3f75c60b457e1', 'la-joya-eco-community'];
//   return excludedIds.some((id) => from.includes(id));
// }
// export default isExcluded;

function isExcluded(page) {
  const fromPath = localStorage.getItem('from');
  let from = fromPath?.split('/').pop();
  // Extract the GUID or string from the 'from' variable
  if (!from || from === 'null') {
    const originFrom = localStorage.getItem('originFrom');
    if (originFrom && originFrom !== 'null') {
      from = originFrom;
    }
  }
  // console.log('from', from);

  const exclusions = {
    'la-joya-eco-community': ['PlayerMap', 'Galaxies'],
    'camden-live-galaxy': [], //Camden Live
    '660a1c8149a3f75c60b457e1': [
      'Galaxies',
      'Memes',
      'JG Merch',
      'Ads',
      'PlayerMap',
    ], //JG Soccer Academy
    'kebell-football': ['Galaxies', 'Memes', 'JG Merch', 'Ads', 'PlayerMap'], //Kebell Football

    // Add more galaxies and their excluded pages as needed
  };

  const excludedPages = exclusions[from]; // Get the excluded pages for the current galaxy using the extracted GUID or string
  //   console.log('excludedPages', excludedPages);
  if (!excludedPages) {
    // console.log('no excluded pages');
    // If the galaxy is not found in the exclusions object
    return false; // No exclusions for this galaxy
  }

  if (page) {
    // console.log(page, excludedPages.includes(page));
    // If a specific page is provided, check if it's excluded
    return excludedPages.includes(page);
  } else {
    // If no specific page is provided, return the array of excluded pages for the galaxy
    return excludedPages;
  }
}
export default isExcluded;
