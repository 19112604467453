import axiosConn from '../axiosConn';
import { uploadProgress } from './uploadProgress';

export const handleProfileAndCoverUpload =
  ({
    parent,
    tag,
    setIsFetching,
    setProgress,
    setUploadPercentage,
    setUpdateTrigger,
    setPlayer1 = null,
    player,
    galaxyId = null,
    setGalaxy = null,
  }) =>
  async (e) => {
    e.preventDefault();
    setIsFetching(true);
    // console.log(parent);
    if (!e.target || !e.target.files) return;
    const fileList = e.target.files;
    const latestUploadedFile = fileList.item(fileList?.length - 1);

    if (!latestUploadedFile) {
      setIsFetching(false);
      return;
    }

    let formData = new FormData();
    const fileName = Date.now() + latestUploadedFile.name;
    formData.append('name', fileName);
    formData.append('file', latestUploadedFile);
    formData.append('upload_preset', 'cubejg');
    formData.append('cloud_name', 'joegalaxy');

    const config = uploadProgress(setProgress, setUploadPercentage);

    let url = `https://api.cloudinary.com/v1_1/joegalaxy/${
      fileName.includes('.mp4') ? 'video' : 'image'
    }/upload`;

    try {
      const { data } = await axiosConn.post(url, formData, config);
      const SecureUrl =
        data.secure_url.split('/upload/')[0] +
        '/upload/q_auto/' +
        data.secure_url.split('/upload/')[1];

      if (parent === 'player') {
        const currentPlayer = JSON.parse(localStorage.getItem('player'));

        if (tag === 'profile') {
          currentPlayer.data.player.profilePhoto = SecureUrl;
        } else if (tag === 'cover') {
          currentPlayer.data.player.coverPhoto = SecureUrl;
        }

        await axiosConn.patch(
          '/players/updateMe',
          { [tag === 'profile' ? 'profilePhoto' : 'coverPhoto']: SecureUrl },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );

        localStorage.setItem('player', JSON.stringify(currentPlayer));
        setPlayer1(currentPlayer.data.player);
      } else if (parent === 'galaxy') {
        // console.log(SecureUrl, galaxyId, tag);
        let res = await axiosConn.patch(
          `/galaxies/${galaxyId}`,
          {
            [tag === 'cover' ? 'coverMedia' : '']: SecureUrl,
          },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // console.log(res.data.data.data);
        // console.log(setGalaxy, typeof setGalaxy);

        if (typeof setGalaxy === 'function') {
          // console.log('setGalaxy');
          setGalaxy(res.data.data.data);
        }
      }

      // setIsFetching(false);
      setUpdateTrigger((prev) => prev + 1);
    } catch (err) {
      console.log(err.message);
    }
    setIsFetching(false);
  };
