import { useContext, useState, useRef, useEffect } from 'react';
import './createMeme.css';
import axios from 'axios';

import axiosConn from '../../axiosConn';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Cancel } from '@material-ui/icons';
import LoadingBar from 'react-top-loading-bar';
import { useMediaQuery } from 'react-responsive';

import { AuthContext } from '../../context/AuthContext';
import { CircularProgress } from '@material-ui/core';
import { SocketContext } from '../../context/socket';
import { useConfettiText } from '../../hooks/useConfettiText';
import { uploadProgress } from '../../utils/uploadProgress';
import { MAX_VIDEO_SIZE, MAX_IMAGE_SIZE } from '../../config/constants';
import { useTheme } from '../../context/ThemeContext';

const MAX_VIDEO_SIZE_MB = MAX_VIDEO_SIZE / (1024 * 1024); // Convert bytes to megabytes

// import IconButton from 'material-ui/lib/icon-button';
//import { Icon_Visa, Icon_MasterCard } from 'material-ui-credit-card-icons';
const MESSAGE_PARTICLE_COUNT = 1000;

export default function CreateMeme({ setMemeItems }) {
  const { player } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [disableMemeUrl, SetDisableMemeUrl] = useState(false);
  const [disableFileInput, SetDisableFileInput] = useState(false);
  const fileInput = useRef(null);
  const [progress, setProgress] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const socket2 = useContext(SocketContext);
  const { darkTheme } = useTheme();

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  const memeRef = useRef(null);

  const [showConfettiText, ConfettiTextComponent] = useConfettiText(
    memeRef,
    mobileView,
    '+10 JGK Win!',
    MESSAGE_PARTICLE_COUNT,
    'meme',
  );

  //const [newMeme, setNewMeme] = useState();
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message]);

  useEffect(() => {
    // This will run every time `disableFileInput` changes
    // console.log('disableFileInput:', disableFileInput);
  }, [disableFileInput]);

  // const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const img = useRef();
  const title = useRef();
  //form
  //url, img url, title, playerId, price.
  //upload/pay button
  //axios to create a new ad,
  //stripe client cc processing.
  //if approved set isApproved.
  const submitHandler = async (e) => {
    // console.log('submitHandler');
    //console.log(file);
    e.preventDefault();
    setIsLoading(true);

    if (disableMemeUrl) {
      //meme is from a file so host it.
      let SecurePostImgQ_AutoUrl = '';

      let data = new FormData();
      // console.log(file);
      const fileName = Date.now() + file?.name;
      data.append('name', fileName);
      data.append('file', file);
      data.append('upload_preset', 'cubejg');
      data.append('cloud_name', 'joegalaxy');

      const config = uploadProgress(setProgress, setUploadPercentage);

      try {
        let url = 'https://api.cloudinary.com/v1_1/joegalaxy/';
        if (
          fileName.toLowerCase().includes('.mp4') ||
          fileName.toLowerCase().includes('.mov')
        ) {
          url = url + 'video/upload';
          //console.log(url);
        } else {
          url = url + 'image/upload';
        }
        //console.log(url);
        setIsLoading(true);

        await axios.post(url, data, config).then(async (data) => {
          //console.log(data);
          SecurePostImgQ_AutoUrl =
            data.data.secure_url.split('/upload/')[0] +
            '/upload/q_auto/fl_progressive:steep/' +
            data.data.secure_url.split('/upload/')[1];
          //console.log(SecurePostImgQ_AutoUrl);
        });

        const Meme = {
          img: SecurePostImgQ_AutoUrl,
          title: title.current.value,
          playerId: player.data.player.playerId,
        };

        try {
          const res = await axiosConn.post('/memes', Meme, {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          });
          setMessage('Success! Your dank meme is included in Meme Machine.');
          setFile(null);
          title.current.value = '';
          img.current.value = '';
          setIsLoading(false);
          // console.log(res.data.data.data);
          setMemeItems((prev) => [...prev, res.data.data.data]);
          // console.log(res.data.data.data);
          showConfettiText();
          //send meme via socket
          try {
            socket2.emit('sendMeme', {
              meme: res.data.data.data,
            });
          } catch (err) {
            console.log(err);
          }
          // window.location.reload();
        } catch (err) {
          title.current.value = '';
          img.current.value = '';
          fileInput.current.value = '';

          setFile(null);
          setError(err.message);

          console.log(err);
          SetDisableFileInput(false);
          SetDisableMemeUrl(false);

          setIsLoading(false);
        } finally {
          title.current.value = '';
          img.current.value = '';
          fileInput.current.value = '';
          setFile(null);
          SetDisableFileInput(false);
          SetDisableMemeUrl(false);

          setIsLoading(false);
        }
      } catch (err) {
        setFile(null);
        setError(err.message);
        console.log(err);

        if (fileInput.current) {
          fileInput.current.value = '';
        }

        if (title.current) {
          title.current.value = '';
        }

        if (img.current) {
          img.current.value = '';
        }

        SetDisableFileInput(false);
        SetDisableMemeUrl(false);

        setIsLoading(false);
      }
    } else if (disableFileInput) {
      //meme is coming from a url.
      const length = img.current.value.split('.').length;
      if (
        img.current.value.split('.')[length - 1] === 'jpg' ||
        img.current.value.split('.')[length - 1] === 'webp' ||
        img.current.value.split('.')[length - 1] === 'gif' ||
        img.current.value.split('.')[length - 1] === 'mp4' ||
        img.current.value.split('.')[length - 1] === 'mov' ||
        img.current.value.split('.')[length - 1] === 'png' ||
        img.current.value.includes('youtube.com') ||
        img.current.value.includes('youtu.be') ||
        img.current.value.includes('soundcloud.com') ||
        img.current.value.includes('vimeo.com') ||
        img.current.value.includes('twitch.com') ||
        img.current.value.includes('streamable.com') ||
        img.current.value.includes('wistia.com') ||
        img.current.value.includes('dailymotion.com') ||
        img.current.value.includes('mixcloud.com') ||
        img.current.value.includes('vidyard.com') ||
        // img.current.value.includes('brighteon.com') ||
        img.current.value.includes('kaltura.com')
      ) {
        const Meme = {
          img: img.current.value,
          title: title.current.value,
          playerId: player.data.player.playerId,
        };

        try {
          const res = await axiosConn.post('/memes', Meme, {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          });
          setMessage('Success! Your dank meme is included in Meme Machine.');
          setIsLoading(false);
          title.current.value = '';
          img.current.value = '';
          // console.log(res.data.data.data);
          setMemeItems((prev) => [...prev, res.data.data.data]);
          // console.log(res.data.data.data);
          showConfettiText();

          //send meme via socket
          try {
            socket2.emit('sendMeme', {
              meme: res.data.data.data,
            });
          } catch (err) {
            console.log(err);
          }

          // window.location.reload();
        } catch (err) {
          title.current.value = '';
          img.current.value = '';
          console.log(err);
          setIsLoading(false);
        } finally {
          title.current.value = '';
          img.current.value = '';
          fileInput.current.value = '';
          setFile(null);
          SetDisableFileInput(false);
          // console.log('finally');
          SetDisableMemeUrl(false);

          setIsLoading(false);
        }
        //window.location.reload();
      } else {
        setError('Meme Url must be to a video or image, please try again.');
        title.current.value = '';
        img.current.value = '';
        setIsLoading(false);
      }
    }
  };

  const handleFileInput = (e) => {
    //console.log(e.target.files[0]);
    // check input file for type and size.
    if (
      e.target.files[0]?.type !== 'image/jpeg' &&
      e.target.files[0]?.type !== 'image/png' &&
      e.target.files[0]?.type !== 'image/webp' &&
      e.target.files[0]?.type !== 'image/gif' &&
      e.target.files[0]?.type !== 'video/mp4' &&
      e.target.files[0]?.type !== 'video/quicktime'
    ) {
      setError(`${e.target.files[0]?.name} format is not supported.`);
    } else if (e.target.files[0].size > MAX_VIDEO_SIZE) {
      setError(
        `${e.target.files[0]?.name} is too large max ${MAX_VIDEO_SIZE_MB}mb allowed.`,
      );
    } else {
      setFile(e.target.files[0]);
      //console.log(file);
    }
  };
  // console.log(file);
  const className = `createMemeShare${disableMemeUrl ? 'NoFile' : ''}${
    darkTheme ? 'DarkTheme' : ''
  }`;

  return (
    <div className="createMemeFeed">
      <LoadingBar
        color="limegreen"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <div className="createMemeFeedWrapper">
        <div className={className}>
          <div className="createMemeShareWrapper">
            <div className={'createMemeShareTop'}>
              <img
                className="memeProfileImg"
                src={
                  player.data.player.profilePhoto
                    ? player.data.player.profilePhoto
                    : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                }
                alt=""
              />
              <span
                className={
                  darkTheme
                    ? 'app dark-theme-secondary createMemeDesc'
                    : 'app createMemeDesc'
                }
              >
                Share a Dank Meme.
                <div className="memeTooltip">
                  <InfoOutlinedIcon
                    className="sidebarIconTooltip"
                    fontSize="small"
                  />
                  <span className="memeTooltiptext">
                    <p>Memes are ranked based on likes.</p>
                    <br></br>
                    <p>
                      To share a meme, enter the url to the hosted meme, or
                      choose a file.
                    </p>
                    <br></br>
                    <p>Enter the Meme Title or Description.</p>

                    <br></br>
                    <p>Click "Dale" (Do it!).</p>
                  </span>
                </div>
              </span>
              <form
                className={
                  darkTheme
                    ? 'app createMemeBox dark-theme'
                    : 'app createMemeBox'
                }
                onSubmit={submitHandler}
              >
                <div>
                  <input
                    placeholder="Meme Url"
                    type="text"
                    required
                    className="createMemeInput"
                    ref={img}
                    disabled={disableMemeUrl}
                    onChange={(e) => {
                      //disable File Input if Url
                      //console.log(e.target.value);
                      e.target.value
                        ? SetDisableFileInput(true)
                        : SetDisableFileInput(false);
                    }}
                  />
                </div>
                <div ref={memeRef}>
                  <input
                    placeholder="Meme Title"
                    type="text"
                    required
                    className="createMemeInput"
                    ref={title}
                  />
                </div>
                <input
                  ref={fileInput}
                  disabled={disableFileInput}
                  type="file"
                  style={{ fontSize: '10px' }}
                  id="file"
                  accept=".png,.jpeg,.jpg,.mp4,.mov"
                  onChange={(e) => {
                    //disable Url input if file.
                    //console.log(e.target.files[0]);
                    e.target.files[0]
                      ? SetDisableMemeUrl(true)
                      : SetDisableMemeUrl(false);
                    handleFileInput(e);
                  }}
                />
                <div>
                  {message && <div className="success_text">{message}</div>}
                  {error && (
                    <div className="messengerError_error">
                      <div className="error_text">{error}</div>
                      <button
                        className="blue_btn"
                        onClick={() => {
                          setError('');
                          setFile(null);
                          setIsLoading(false);
                        }}
                      >
                        Try again
                      </button>
                    </div>
                  )}
                  {file &&
                    (file.name.toLowerCase().includes('.mp4') ||
                    file.name.toLowerCase().includes('.mov') ? (
                      <div className="shareVidContainer">
                        <video className="memeVid" controls>
                          <source
                            src={URL.createObjectURL(file)}
                            type="video/mp4"
                          />
                        </video>
                        <Cancel
                          disabled={isLoading}
                          className="shareCancelVid"
                          onClick={() => {
                            setFile(null);
                            fileInput.current.value = null;
                            SetDisableMemeUrl(false);
                            title.current.value = '';
                            img.current.value = '';
                            setIsLoading(false);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="shareImgContainer">
                        <img
                          className="createMemeImg"
                          src={URL.createObjectURL(file)}
                          alt=""
                        />
                        <Cancel
                          disabled={isLoading}
                          className="shareCancelImg"
                          onClick={() => {
                            setFile(null);
                            SetDisableMemeUrl(false);
                            fileInput.current.value = null;
                            title.current.value = '';
                            img.current.value = '';
                            setIsLoading(false);
                          }}
                        />
                      </div>
                    ))}
                  <ConfettiTextComponent />
                  <button
                    className="memeButton"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : (
                      'Dale'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
