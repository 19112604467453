import { useState } from 'react';
import './style.css';
//import axios from 'axios';
import axiosConn from '../../axiosConn';

export default function SendVerification({ player }) {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const sendVerificationLink = async () => {
    //console.log('sendVerificationLink');
    try {
      const { data } = await axiosConn.post(
        '/players/sendVerification',
        { _id: player._id },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      setSuccess(data.message);
    } catch (error) {
      setError(error.response.data.message);
    }
  };
  return (
    <div className="send_verificationLink">
      <span>
        <b>{player.playerId}</b>, your account is not yet verified. Verify your
        account before it gets deleted after a month from creating.
      </span>
      <a
        onClick={() => {
          sendVerificationLink();
        }}
      >
        click here to send the verification link
      </a>
      {success && <div className="success_text">{success}</div>}
      {error && <div className="error_text">{error}</div>}
    </div>
  );
}
