const AuthReducer = (state, action) => {
  //console.log(state, state.player.data.player.followings, action.payload);
  switch (action.type) {
    case 'LOGIN_START':
      return {
        player: null,
        isFetching: true,
        error: false,
      };
    case 'LOGIN_SUCCESS':
      return {
        player: action.payload,
        isFetching: false,
        error: false,
      };
    case 'LOGIN_FAILURE':
      return {
        player: null,
        isFetching: false,
        error: true,
      };
    case 'FOLLOW':
      return {
        ...state,
        player: {
          ...state.player,
          data: {
            player: {
              ...state.player.data.player,
              followings: [
                ...state.player.data.player.followings,
                action.payload,
              ],
            },
          },
        },
        // player: {
        //   ...state.player,
        //   followings: [...state.player.data.player.followings, action.payload],
        // },
      };
    case 'UNFOLLOW':
      return {
        ...state,
        player: {
          ...state.player,
          data: {
            player: {
              ...state.player.data.player,
              followings: state.player.data.player.followings.filter(
                (following) => following !== action.payload,
              ),
            },
          },
        },
        // player: {
        //   ...state.player,
        //   followings: state.player.data.player.followings.filter(
        //     (following) => following !== action.payload,
        //   ),
        // },
      };
    case 'UPDATE_PLAYER':
      return {
        ...state,
        player: {
          ...state.player,
          data: {
            player: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default AuthReducer;
