import { saveLocationData } from './apiUtils.js';

let cachedLat = null;
let cachedLng = null;
let cachedSunTimes = null;

export async function fetchSunTimes(lat, lng) {
  try {
    const response = await fetch(
      `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${lng}&formatted=0`,
    );
    const data = await response.json();
    const sunriseTime = new Date(data.results.sunrise);
    const sunsetTime = new Date(data.results.sunset);
    cachedSunTimes = { sunriseTime, sunsetTime };
    return cachedSunTimes;
  } catch (error) {
    console.error('Error fetching sun times:', error);
    return null;
  }
}

export async function getLocationAndFetchSunTimes(playerToken = null) {
  if (cachedLat !== null && cachedLng !== null && cachedSunTimes !== null) {
    return cachedSunTimes;
  }

  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude, accuracy } = position.coords;
          const timestamp = position.timestamp;
          console.log('Latitude:', latitude, 'Longitude:', longitude);
          cachedLat = latitude;
          cachedLng = longitude;
          const sunTimes = await fetchSunTimes(latitude, longitude);
          // console.log(playerToken);
          try {
            await saveLocationData(
              latitude,
              longitude,
              accuracy,
              timestamp,
              playerToken,
            );
          } catch (err) {
            console.error('Error saving location:', err.message);
          }
          resolve(sunTimes);
        },
        (error) => {
          console.error('Error getting location:', error);
          reject(error);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      reject(new Error('Geolocation is not supported by this browser.'));
    }
  });
}
