import React from 'react';
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  FooterLinkDarkTheme,
  Heading,
} from './FooterStyles';
import { useTheme } from '../../context/ThemeContext';

const Footer = () => {
  const { darkTheme } = useTheme();

  return (
    <Box className={darkTheme ? 'dark-theme blackBg' : ''}>
      {/* <h1 style={{ color: 'green', textAlign: 'center', marginTop: '-50px' }}>
        Health Wealth Love
      </h1> */}

      <Container>
        <Row>
          {/* <Column>
            <Heading>About Us</Heading>
            <FooterLink href="#">Aim</FooterLink>
            <FooterLink href="#">Vision</FooterLink>
          </Column> */}
          <Column>
            <Heading>Services</Heading>
            {/* <FooterLink href="#">Support</FooterLink> */}
            {darkTheme ? (
              <FooterLinkDarkTheme
                href="mailto://whitelabelwithjoe@joegalaxy.net?subject=Hey Joe, I'd love to custom white label, more info please."
                title="White Label Collaborations with Joe Galaxy"
              >
                White Label
              </FooterLinkDarkTheme>
            ) : (
              <FooterLink
                href="mailto://whitelabelwithjoe@joegalaxy.net?subject=Hey Joe, I'd love to custom white label, more info please."
                title="White Label Collaborations with Joe Galaxy"
              >
                White Label
              </FooterLink>
            )}

            {darkTheme ? (
              <FooterLinkDarkTheme
                href="mailto://advertisewithjoe@joegalaxy.net?subject=Hey Joe, I'm ready to advertise. How do I get started?"
                title="Advertise with Joe Galaxy"
              >
                Advertise with JOE GALAXY
              </FooterLinkDarkTheme>
            ) : (
              <FooterLink
                href="mailto://advertisewithjoe@joegalaxy.net?subject=Hey Joe, I'm ready to advertise. How do I get started?"
                title="Advertise with Joe Galaxy"
              >
                Advertise with JOE GALAXY
              </FooterLink>
            )}

            {/* <FooterLink href="#">Teaching</FooterLink> */}
          </Column>
          <Column>
            <Heading>Contact Us</Heading>
            {darkTheme ? (
              <FooterLinkDarkTheme
                href="mailto://support@joegalaxy.net?subject=Login Page Support Request"
                title="Joe Galaxy Login Page Support"
              >
                Support
              </FooterLinkDarkTheme>
            ) : (
              <FooterLink
                href="mailto://support@joegalaxy.net?subject=Login Page Support Request"
                title="Joe Galaxy Login Page Support"
              >
                Support
              </FooterLink>
            )}
            {darkTheme ? (
              <FooterLinkDarkTheme
                href="mailto://collabwithjoe@joegalaxy.net?subject=Hey Joe Let's Collab!"
                title="Collaborations with Joe Galaxy"
              >
                Collab with JOE GALAXY
              </FooterLinkDarkTheme>
            ) : (
              <FooterLink
                href="mailto://collabwithjoe@joegalaxy.net?subject=Hey Joe Let's Collab!"
                title="Collaborations with Joe Galaxy"
              >
                Collab with JOE GALAXY
              </FooterLink>
            )}
          </Column>
          {/* <Column>
            <Heading>Social Media</Heading>
            <FooterLink href="#">
              <i className="fab fa-facebook-f">
                <span style={{ marginLeft: '10px' }}>Facebook</span>
              </i>
            </FooterLink>
            <FooterLink href="#">
              <i className="fab fa-instagram">
                <span style={{ marginLeft: '10px' }}>Instagram</span>
              </i>
            </FooterLink>
          </Column> */}
        </Row>
      </Container>
    </Box>
  );
};
export default Footer;
