import './friendship.css';
import { useEffect, useRef, useState, useContext } from 'react';
import useClickOutside from '../../clickOutside';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';
import { Link } from 'react-router-dom';
import { useGetCurrPlayer } from '../../hooks/useGetCurrPlayer';
import { useTheme } from '../../context/ThemeContext';

export default function Friendship({ player1 }) {
  const [friendsMenu, setFriendsMenu] = useState(false);
  const [respondMenu, setRespondMenu] = useState(false);
  const menu = useRef(null);
  const menu1 = useRef(null);
  useClickOutside(menu, () => setFriendsMenu(false));
  useClickOutside(menu1, () => setRespondMenu(false));
  const { player, dispatch } = useContext(AuthContext);

  const [visitedPlayer, setVisitedPlayer] = useState({});
  const [currPlayer, setCurrPlayer] = useState({});
  const { darkTheme } = useTheme();

  //const [friends, setFriends] = useState([]);

  //player follows player1.
  const [followed, setFollowed] = useState(
    player.data.player.followings.includes(player1?._id),
  );

  //we are in each other's friends array.  we are friends.
  const [isFriends, setIsFriends] = useState(false);

  //player sent player1 a request.
  const [requestSent, setRequestSent] = useState(false);

  //player received a request from player1.
  const [requestReceived, setRequestReceived] = useState(false);
  const [isDone1, setIsDone1] = useState(false);
  const [isDone2, setIsDone2] = useState(false);
  const [isDone3, setIsDone3] = useState(false);
  const [convoId, setConvoId] = useState('');

  useEffect(() => {
    let mounted = true;
    const getVisitedPlayer = async () => {
      try {
        const res = await axiosConn.get(
          `/players/?playerId=${player1.playerId}`,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        if (!mounted) return;
        setVisitedPlayer(res.data.data.data[0]);
        // console.log('visitedPlayer: ', res.data.data.data[0]);
        setIsDone1(true);
      } catch (err) {
        console.log(err);
      }
    };
    getVisitedPlayer();
    return () => {
      mounted = false;
    };
  }, [player.data.player._id, player.token, player1.playerId]);

  const onSuccess = (data) => {
    // console.log('data: ', data);
  };

  const onError = (err) => {
    // console.log('err: ', err);
  };

  const { data, status } = useGetCurrPlayer(onSuccess, onError, player);
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    //set conversations
    if (status === 'success') {
      if (!mounted) return;
      setCurrPlayer(data);
      setIsDone2(true);
    }
    return () => {
      mounted = false;
    };
  }, [status, data]);

  useEffect(() => {
    if (currPlayer && visitedPlayer && isDone1 && isDone2) {
      setFollowed(currPlayer.followings?.includes(visitedPlayer?._id));
      setIsFriends(
        currPlayer.friends?.includes(visitedPlayer?._id) &&
          visitedPlayer?.friends?.includes(currPlayer?._id),
      );
      setRequestSent(visitedPlayer?.requests?.includes(currPlayer._id));
      setRequestReceived(currPlayer.requests?.includes(visitedPlayer._id));
      setIsDone3(true);
    }
    return () => {};
  }, [
    currPlayer?._id,
    currPlayer?.followings,
    currPlayer?.friends,
    currPlayer?.requests,
    isDone1,
    isDone2,
    isDone3,
    player,
    player1,
    visitedPlayer,
  ]);

  // function removeAllInstances(arr, item) {
  //   for (var i = arr.length; i--; ) {
  //     if (arr[i] === item) arr.splice(i, 1);
  //   }
  // }

  //console.log(player1);
  const acceptRequest = async () => {
    try {
      //console.log(player1._id);
      await axiosConn.put(
        `/players/acceptRequest/${player1._id}`,
        {
          id: player.data.player._id,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      const currentPlayer = JSON.parse(localStorage.getItem('player'));
      currentPlayer.data.player.friends.push(player1._id);
      currentPlayer.data.player.followings.push(player1._id);
      currentPlayer.data.player.followers.push(player1._id);
      localStorage.setItem('player', JSON.stringify(currentPlayer));
      //console.log(currentPlayer);
      window.location.reload();
      return 'ok';
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  };

  const addFriend = async () => {
    try {
      await axiosConn.put(
        `/players/addFriend/${player1._id}`,
        {
          id: player.data.player._id,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );

      return 'ok';
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  };

  const cancelRequest = async () => {
    try {
      await axiosConn.put(
        `/players/cancelRequest/${player1._id}`,
        {
          id: player.data.player._id,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );

      return 'ok';
    } catch (error) {
      console.log(error.response.data.message);

      return error.response.data.message;
    }
  };

  const deleteRequest = async () => {
    try {
      await axiosConn.put(
        `/players/deleteRequest/${player1._id}`,
        {
          id: player.data.player._id,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );

      return 'ok';
    } catch (error) {
      console.log(error.response.data.message);

      return error.response.data.message;
    }
  };

  const unfriend = async () => {
    try {
      await axiosConn.put(
        `/players/unfriend/${player1._id}`,
        {
          id: player.data.player._id,
        },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );

      // const currentPlayer = JSON.parse(localStorage.getItem('player'));
      // // console.log(currentPlayer.data.player.friends);
      // // console.log(currentPlayer.data.player.followings);
      // // console.log(currentPlayer.data.player.followers);
      // removeAllInstances(currentPlayer.data.player.friends, player1._id);
      // removeAllInstances(currentPlayer.data.player.followings, player1._id);
      // removeAllInstances(currentPlayer.data.player.followers, player1._id);
      // // console.log(currentPlayer.data.player.friends);
      // // console.log(currentPlayer.data.player.followings);
      // // console.log(currentPlayer.data.player.followers);
      // localStorage.setItem('player', JSON.stringify(currentPlayer));
      window.location.reload();

      return 'ok';
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data.message;
    }
  };

  const followUnfollow = async () => {
    try {
      //console.log(followed);
      if (followed) {
        await axiosConn.put(
          `/players/${player1._id}/unfollow`,
          {
            id: player.data.player._id,
          },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        dispatch({ type: 'UNFOLLOW', payload: player1._id });
      } else {
        // console.log(
        //   player.data.player.playerId,
        //   'is following: ',
        //   player1.playerId,
        // );
        await axiosConn.put(
          `/players/${player1._id}/follow`,
          {
            id: player.data.player._id,
          },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        dispatch({ type: 'FOLLOW', payload: player1._id });
      }
      setFollowed(!followed);
      //friendshipp.following = !friendshipp.following;
    } catch (err) {
      console.log(err);
    }
  };

  const addFriendHandler = async () => {
    // setFriendship({ ...friendship, requestSent: true, following: true });
    setRequestSent(true);
    setFollowed(true);
    //console.log(friendship);
    await addFriend(player1._id, player.token);
  };
  const cancelRequestHandler = async () => {
    // setFriendship({ ...friendship, requestSent: false, following: false });
    setRequestSent(false);
    setFollowed(false);
    //console.log(friendship);

    await cancelRequest(player1._id, player.token);
  };
  const followHandler = async () => {
    //setFriendship({ ...friendship, following: true });
    //console.log(friendship);
    //followUnfollow we will toggle followed state there.
    await followUnfollow();
  };
  const unfollowHandler = async () => {
    //setFriendship({ ...friendship, following: false });
    //console.log(friendship);

    await followUnfollow();
  };

  const acceptRequestHandler = async () => {
    // setFriendship({
    //   ...friendship,
    //   friends: true,
    //   following: true,
    //   requestSent: false,
    //   requestReceived: false,
    // });
    setIsFriends(true);
    setFollowed(true);
    setRequestSent(false);
    setRequestReceived(false);
    //console.log(friendship);

    await acceptRequest(player1._id, player.token);
  };
  const unfriendHandler = async () => {
    // setFriendship({
    //   ...friendship,
    //   friends: false,
    //   following: false,
    //   requestSent: false,
    //   requestReceived: false,
    // });
    setIsFriends(false);
    setFollowed(false);
    setRequestSent(false);
    setRequestReceived(false);
    //console.log(friendship);

    await unfriend(player1._id, player.token);
  };
  const deleteRequestHandler = async () => {
    // setFriendship({
    //   ...friendship,
    //   friends: false,
    //   following: false,
    //   requestSent: false,
    //   requestReceived: false,
    // });
    setIsFriends(false);
    setFollowed(false);
    setRequestSent(false);
    setRequestReceived(false);
    //console.log(friendship);

    await deleteRequest(player1._id, player.token);
  };

  const goToMessenger = (convoId) => {
    // console.log(convoId);
    setConvoId(convoId);
    window.location.href = `/messenger/${convoId}`;
    //window.location.href = `/messenger`;
    //next step pass the conversation as a param
    //messenger will grab param and setCurrentChat
    //once done with this remove <Link on span below
  };

  const createConversationIfNone = async () => {
    //console.log('createConversationIfNoneCalled()...');

    try {
      // console.log(player1.playerId);
      // console.log(currPlayer.playerId);
      await axiosConn
        .get(`/conversations/find/${currPlayer._id}/${visitedPlayer._id}`, {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        })
        .then(async (res) => {
          //console.log(res.data);
          if (typeof res.data === 'undefined' || res.data === null) {
            //console.log('no conversation yet!');
            //if no conversation between currPlayer._id & visitedPlayer then
            //create a conversation.
            //setCurrentChat to new conversation.
            const res2 = await axiosConn.post(
              `/conversations/`,
              {
                senderId: currPlayer._id,
                receiverId: visitedPlayer._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${player.token}`,
                },
              },
            );
            goToMessenger(res2.data._id);
            // console.log(res2.data._id);
          } else {
            goToMessenger(res.data._id);
            // console.log(res.data._id);
          }
          // goToMessenger();
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isDone1 &&
        isDone2 &&
        isDone3 &&
        typeof visitedPlayer !== 'undefined' &&
        typeof currPlayer !== 'undefined' && (
          <div className="friendship">
            <div className="flex">
              {isFriends ? (
                <div className="friends_menu_wrap">
                  <button
                    className="gray_btn"
                    onClick={() => setFriendsMenu(true)}
                  >
                    <img
                      src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660852717/Images/friends_ndlpv1.png"
                      alt=""
                    />
                    <span>Friends</span>
                  </button>
                  {friendsMenu && (
                    <div
                      className={
                        darkTheme
                          ? 'open_cover_menu darkTheme blackBg'
                          : 'open_cover_menu'
                      }
                      ref={menu}
                    >
                      {/* <div className={
                          darkTheme
                            ? 'darkTheme blackBg open_cover_menu_item hover1'
                            : 'open_cover_menu_item hover1'
                        }>
                <img
                  src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660852841/Images/favoritesOutline_kmqlfl.png"
                  alt=""
                />
                Favorites
              </div>
              <div className="open_cover_menu_item hover1">
                <img
                  src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660852888/Images/editFriends_fflepo.png"
                  alt=""
                />
                Edit Friend list
              </div>
              */}
                      {/*
              {followed ? (
                <div
                  className="open_cover_menu_item hover1"
                  onClick={() => unfollowHandler()}
                >
                  <img
                    src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660852935/Images/unfollowOutlined_prxqkw.png"
                    alt=""
                  />
                  Unfollow
                </div>
              ) : (
                <div
                  className="open_cover_menu_item hover1"
                  onClick={() => followHandler()}
                >
                  <img
                    src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660852935/Images/unfollowOutlined_prxqkw.png"
                    alt=""
                  />
                  Follow
                </div>
              )}
              */}
                      <div
                        className={
                          darkTheme
                            ? 'darkTheme blackBg open_cover_menu_item hover1'
                            : 'open_cover_menu_item hover1'
                        }
                        onClick={() => unfriendHandler()}
                      >
                        <i className="unfriend_outlined_icon"></i>
                        Unfriend
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                !requestSent &&
                !requestReceived && (
                  <button
                    className="jgpurple_btn"
                    onClick={() => addFriendHandler()}
                  >
                    <div className="friend_img">
                      <img
                        src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660853126/Images/addFriend_xcfhya.png"
                        alt=""
                        className="invert img_bro"
                      />
                    </div>
                    <span>Add Friend</span>
                  </button>
                )
              )}
              {requestSent ? (
                <button
                  className="jgpurple_btn"
                  onClick={() => cancelRequestHandler()}
                >
                  <img
                    src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660853174/Images/cancelRequest_rjism1.png"
                    className="invert"
                    alt=""
                  />
                  <span>Cancel Request</span>
                </button>
              ) : (
                requestReceived && (
                  <div className="friends_menu_wrap">
                    <button
                      className="gray_btn"
                      onClick={() => setRespondMenu(true)}
                    >
                      <img
                        src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660853206/Images/friends_p9ox8p.png"
                        alt=""
                      />
                      <span>Respond</span>
                    </button>
                    {respondMenu && (
                      <div
                        className={
                          darkTheme
                            ? 'open_cover_menu darkTheme blackBg'
                            : 'open_cover_menu'
                        }
                        ref={menu1}
                      >
                        <div
                          className={
                            darkTheme
                              ? 'darkTheme blackBg open_cover_menu_item hover1'
                              : 'open_cover_menu_item hover1'
                          }
                          onClick={() => acceptRequestHandler()}
                        >
                          Confirm
                        </div>
                        <div
                          className={
                            darkTheme
                              ? 'darkTheme blackBg open_cover_menu_item hover1'
                              : 'open_cover_menu_item hover1'
                          }
                          onClick={() => deleteRequestHandler()}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
              {followed ? (
                <button className="gray_btn" onClick={() => unfollowHandler()}>
                  <img
                    src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660853252/Images/follow_axxydp.png"
                    alt=""
                  />
                  <span>Following</span>
                </button>
              ) : (
                <button className="blue_btn" onClick={() => followHandler()}>
                  <img
                    src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660853252/Images/follow_axxydp.png"
                    className="invert"
                    alt=""
                  />
                  <span>Follow</span>
                </button>
              )}
              <button
                className={isFriends ? 'blue_btn' : 'gray_btn'}
                onClick={() => createConversationIfNone()}
              >
                <img
                  src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1660853304/Images/message_gud2nk.png"
                  className={isFriends && 'invert'}
                  alt=""
                />
                {isFriends ? (
                  <Link
                    to={`/messenger/${convoId}`}
                    //to={'/messenger'}
                    style={{ textDecoration: 'none' }}
                  >
                    <span className="invert">Message</span>{' '}
                  </Link>
                ) : (
                  <span>Message</span>
                )}
              </button>
            </div>
          </div>
        )}
    </>
  );
}
