import './reset.css';
import axiosConn from '../../axiosConn';
import { useState, useRef, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import validator from 'validator';
import { useTheme } from '../../context/ThemeContext';

export default function Reset() {
  const emailAddress = useRef();
  const password = useRef();
  const passwordAgain = useRef();
  const code = useRef();
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingResendCode, setIsFetchingResendCode] = useState(false);
  const [error, setError] = useState('');
  const [visible, setVisible] = useState(0);
  const [email, setEmail] = useState('');
  const [theCode, setTheCode] = useState('');
  const [message, setMessage] = useState('');
  const { darkTheme } = useTheme();

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
      emailAddress.current.value = emailParam;
    }
  }, [location]);

  const handleClick = async (e, type) => {
    setMessage('');
    e.preventDefault();
    let emailValue;

    if (type === 'resendCode') {
      setIsFetchingResendCode(true);
      emailValue = email; // Use the email stored in the state
    } else {
      emailValue = emailAddress.current.value;
      setIsFetching(true);
      setEmail(emailValue);
    }

    try {
      await axiosConn.post('/players/sendResetPasswordCode', {
        emailAddress: emailValue,
      });
      setMessage('Success! Your reset code has been sent.');
      setVisible(1);
    } catch (err) {
      setError(err.message);
      setMessage('');
      console.log(err);
    }

    setIsFetching(false);
    setIsFetchingResendCode(false);
  };

  const validateResetCodeClick = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    setTheCode(code.current.value);
    try {
      await axiosConn.post('/players/validateResetCode', {
        emailAddress: email,
        code: code.current.value,
      });
      setVisible(2);
    } catch (err) {
      setError(err.message);
      setMessage('');
      console.log(err);
    }
    setIsFetching(false);
  };

  const resetPasswordClick = async (e) => {
    e.preventDefault();
    setIsFetching(true);
    setError('');

    if (!validator.isStrongPassword(password.current.value)) {
      setError(
        'Please enter a strong password.  minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1',
      );
      setIsFetching(false);
      return;
    }

    if (password.current.value !== passwordAgain.current.value) {
      setError('Passwords entered do not match');
      setIsFetching(false);
      return;
    }

    try {
      await axiosConn.post(`/players/changePassword/`, {
        emailAddress: email,
        code: theCode,
        password: password.current.value,
        passwordConfirm: passwordAgain.current.value,
      });
      setVisible(3);
    } catch (err) {
      setError(err.message);
      console.log(err);
    }
    setIsFetching(false);
  };

  return (
    <div className={darkTheme ? 'dark-theme blackBg login' : 'login'}>
      <div className="loginWrapper">
        <div className="loginLeft">
          <div>
            <img
              src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658172769/Images/sbcgiu6mxdtvqvjflfwu.png"
              alt="Joe Galaxy Logo"
            ></img>
          </div>
          <span className="loginDesc">
            <p>This game is your Life!</p>
            <p className="tag">See you at the Top...</p>
          </span>
        </div>
        {visible === 0 && (
          <div className="loginRight">
            <div className="logoDescMobile">
              <div>
                <img
                  src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658172769/Images/sbcgiu6mxdtvqvjflfwu.png"
                  alt="Joe Galaxy Logo"
                ></img>
              </div>
              <span className="loginDesc">
                <p>This game is your Life!</p>
                <p className="tag">See you at the Top...</p>
              </span>
            </div>
            <form
              className={darkTheme ? 'dark-theme blackBg loginBox' : 'loginBox'}
              onSubmit={handleClick}
            >
              <div>
                <span className="loginDesc">
                  <p>
                    Enter your email address below and we'll send you a password
                    reset code.
                  </p>
                </span>
              </div>
              <input
                id="resetEmail"
                placeholder="Email"
                type="email"
                required
                className="loginInput"
                ref={emailAddress}
                autoComplete="username"
              />
              {error && <div className="error_text">{error}</div>}

              <button
                className="loginButton"
                type="submit"
                disabled={isFetching}
              >
                {isFetching ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  'Reset Password'
                )}
              </button>
            </form>
          </div>
        )}
        {visible === 1 && (
          <div className="loginRight">
            <div className="logoDescMobile">
              <div>
                <img
                  src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658172769/Images/sbcgiu6mxdtvqvjflfwu.png"
                  alt="Joe Galaxy Logo"
                ></img>
              </div>
              <span className="loginDesc">
                <p>This game is your Life!</p>
                <p className="tag">See you at the Top...</p>
              </span>
            </div>
            <form
              className={darkTheme ? 'dark-theme blackBg loginBox' : 'loginBox'}
              onSubmit={validateResetCodeClick}
            >
              <div>
                <span className="loginDesc">
                  <p>Please enter the code that has been sent to your email.</p>
                </span>
              </div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <button
                  type="button"
                  onClick={(e) => handleClick(e, 'resendCode', email)}
                  className="loginButton"
                  disabled={isFetchingResendCode}
                >
                  {isFetchingResendCode ? (
                    <CircularProgress color="inherit" size="20px" />
                  ) : (
                    'Resend Code'
                  )}
                </button>
              </div>
              <input
                placeholder="Code"
                type="text"
                required
                className="loginInput"
                minLength="5"
                maxLength="5"
                ref={code}
              />
              {error && <div className="error_text">{error}</div>}
              {message && <div className="success_text">{message}</div>}

              <button
                className="loginButton"
                type="submit"
                disabled={isFetching}
              >
                {isFetching ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  'Continue'
                )}
              </button>
            </form>
          </div>
        )}
        {visible === 2 && (
          <div className="loginRight">
            <div className="logoDescMobile">
              <div>
                <img
                  src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658172769/Images/sbcgiu6mxdtvqvjflfwu.png"
                  alt="Joe Galaxy Logo"
                ></img>
              </div>
              <span className="loginDesc">
                <p>This game is your Life!</p>
                <p className="tag">See you at the Top...</p>
              </span>
            </div>
            <form
              className={darkTheme ? 'dark-theme blackBg loginBox' : 'loginBox'}
              onSubmit={resetPasswordClick}
            >
              <div>
                <span className="loginDesc">
                  <p>Change Password</p>
                </span>
              </div>
              <input
                placeholder="New password"
                required
                ref={password}
                className="loginInput"
                type="password"
                minLength="8"
                autoComplete="new-password"
              />
              <input
                placeholder="Password again"
                required
                ref={passwordAgain}
                className="loginInput"
                type="password"
                autoComplete="new-password"
              />
              {error && <div className="error_text">{error}</div>}

              <button
                className="loginButton"
                type="submit"
                disabled={isFetching}
              >
                {isFetching ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  'Continue'
                )}
              </button>
            </form>
          </div>
        )}
        {visible === 3 && (
          <div className="loginRight">
            <div className="logoDescMobile">
              <div>
                <img
                  src="https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1658172769/Images/sbcgiu6mxdtvqvjflfwu.png"
                  alt="Joe Galaxy Logo"
                ></img>
              </div>
              <span className="loginDesc">
                <p>This game is your Life!</p>
                <p className="tag">See you at the Top...</p>
              </span>
            </div>
            <div
              className={darkTheme ? 'dark-theme blackBg resetBox' : 'resetBox'}
            >
              <div>
                <span className="loginDesc">
                  <p>Password Reset Success!</p>
                </span>
              </div>
              {error && <div className="error_text">{error}</div>}
              <div>
                <Link to="/login" style={{ textDecoration: 'none' }}>
                  <button
                    className="loginButton"
                    type="submit"
                    disabled={isFetching}
                  >
                    {isFetching ? (
                      <CircularProgress color="white" size="20px" />
                    ) : (
                      'Continue to Login'
                    )}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
