// TypingIndicator.jsx
import React from 'react';

const TypingIndicator = ({ isTyping, senderPlayerId }) => {
  if (!isTyping || !senderPlayerId) {
    return null;
  }

  return <span>{senderPlayerId} is typing...</span>;
};

export default TypingIndicator;
