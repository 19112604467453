import axiosConn from './axiosConn';

export const loginCall = async (playerCredential, dispatch) => {
  dispatch({ type: 'LOGIN_START' });

  try {
    //console.log(playerCredential);
    const res = await axiosConn.post('/players/login', playerCredential);
    //console.log(res.data);
    setTimeout(() => {
      dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
    }, 1000); // Delay of 2 seconds
    return 'success';
  } catch (err) {
    dispatch({ type: 'LOGIN_FAILURE', payload: err });
    return err;
  }
};
