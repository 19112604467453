// import { useQuery } from 'react-query';
import { useInfiniteQuery } from 'react-query';

import axiosConn from '../axiosConn';

const fetchJGKTransactions = async ({ pageParam = 1, playerId, token }) => {
  const res = await axiosConn.get(`/jgkTokenTransactions/${playerId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      page: pageParam,
      limit: 10,
    },
  });
  //   console.log(res);
  return res.data;
  //   return res.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
};

export const useGetJGKTransactions = (player, onSuccess, onError) => {
  return useInfiniteQuery(
    ['jgkTransactions', player.data.player._id],
    ({ pageParam = 1 }) =>
      fetchJGKTransactions({
        pageParam,
        playerId: player.data.player._id,
        token: player.token,
      }),
    {
      onSuccess,
      onError,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length === 0) return undefined; // No more pages
        return allPages.length + 1; // Next page number
      },
      refetchOnWindowFocus: false, // Disable refetching on window focus
    },
  );
};
