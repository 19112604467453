import './topbar.css';
import {
  Search,
  Person,
  Chat,
  // Notifications
} from '@material-ui/icons';
import { ArrowDown } from '../../svg/arrowDown';
import { useQuery } from 'react-query';
import { useGetConversations } from '../../hooks/useGetConversations';
import { useGetCurrPlayer } from '../../hooks/useGetCurrPlayer';
import LogoutIcon from '@mui/icons-material/Logout';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import { Tooltip } from '@mui/material';
import validator from 'validator';
import { Link } from 'react-router-dom';
import { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ViewPortContext, useViewport } from '../../context/ViewPortContext';
import PlayerSearchResults from '../../components/playerSearchResults/PlayerSearchResults';
import axiosConn from '../../axiosConn';
import useClickOutside from '../../clickOutside';
import { useMediaQuery } from 'react-responsive';
import PlayerMenu from './playerMenu';
import { SocketContext } from '../../context/socket';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import useMobileView from '../../hooks/useMobileView';
import Logoff from '../../utils/logoffTopbar';
import isExcluded from '../../utils/isExcluded';
import fromToImg from '../../utils/fromToImg';
import getCookie from '../../utils/getCookie';
import { useTheme, useThemeUpdate } from '../../context/ThemeContext';
import { addShakeAnimation } from '../../utils/animationUtils';

const unlockGalaxyJGKAmount = 200;

export default function Topbar({
  parent = 'General',
  setMmenuOpen,
  conversationsData,
  conversationsStatus,
  refetchConversations,
  conversations,
  setConversations,
  totalUnReadMessages = 0,
  setTotalUnReadMessages,
  visitedPlayer = null,
}) {
  // console.log(parent);
  const { player } = useContext(AuthContext);
  const { width } = useContext(ViewPortContext);
  // console.log(visitedPlayer?.playerId, player?.data.player.playerId);
  const profileImage =
    visitedPlayer?._id !== player?.data.player._id
      ? player?.data.player.profilePhoto ||
        'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
      : visitedPlayer?.profilePhoto ||
        'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png';

  // console.log(width);
  //const [player1, setPlayer1] = useState(player);
  const [numFollowers] = useState(player.data.player.followers.length);
  const [numFriendRequests, setNumFriendRequests] = useState(
    player.data.player.requests.length,
  );
  const [friendRequestPlayers, setFriendRequestPlayers] = useState([]);
  const searchInput = useRef();
  const [isSearching, setIsSearching] = useState(false);

  const [searchedPlayers, setSearchedPlayers] = useState([]);
  const [currPlayer, setCurrPlayer] = useState({});

  const [playerSearchResults, setPlayerSearchResults] = useState(false);
  const [showPlayerMenu, setShowPlayerMenu] = useState(false);
  const [showFriendRequestPlayersMenu, setShowFriendRequestPlayersMenu] =
    useState(false);
  const socket2 = useContext(SocketContext);
  const menu = useRef(null);
  const friendRequestPlayersMenu = useRef(null);
  const playermenu = useRef(null);
  // const [showUnReadMessages, setShowUnReadMessages] = useState(true);
  const mobileView = useMobileView();
  const from = localStorage.getItem('from');
  const [showTip, setShowTip] = useState(false);
  const [currentTip, setCurrentTip] = useState(0);
  const [unlockedGalaxies, setUnlockedGalaxies] = useState(false);
  const { darkTheme } = useTheme();
  const toggleTheme = useThemeUpdate();
  const imgRef = useRef(null);

  const tips = [
    'Click here to see the Player Menu.',
    'Find your Profile Page here as well.',
  ];

  useEffect(() => {
    // Check if the user is new and show the tip
    const isNewUser = localStorage.getItem('isNewUser');
    if (!isNewUser) {
      setShowTip(true);
      localStorage.setItem('isNewUser', 'false');
    }
  }, []);

  const handleTipDismiss = () => {
    if (currentTip < tips.length - 1) {
      setCurrentTip(currentTip + 1);
    } else {
      setShowTip(false);
    }
  };

  //console.log(parent.parent);

  const handleClickOutside = () => {
    setPlayerSearchResults(false);
    searchInput.current.value = '';
  };

  useClickOutside(menu, () => handleClickOutside());

  useClickOutside(friendRequestPlayersMenu, () => {
    setShowFriendRequestPlayersMenu(false);
  });

  useClickOutside(playermenu, () => {
    setShowPlayerMenu(false);
    if (parent && typeof setMmenuOpen === 'function') {
      setMmenuOpen(false);
    }
  });

  //1. Get currentPlayer. currentPlayer.requests[]

  const onSuccess2 = (data) => {
    // console.log('data: ', data);
  };

  const onError2 = (err) => {
    // console.log('err: ', err);
  };

  const { data: data2, status: status2 } = useGetCurrPlayer(
    onSuccess2,
    onError2,
    player,
  );
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    //set conversations
    if (status2 === 'success') {
      if (!mounted) return;
      setCurrPlayer(data2);
    }
    return () => {
      mounted = false;
    };
  }, [status2, data2]);

  useEffect(() => {
    let mounted = true;
    // get friendRequestPlayers
    const getFriendRequestPlayers = async () => {
      try {
        const res1 = await axiosConn.get(
          `/players/friendRequests/${player.data.player._id}`,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // console.log('friendRequestPlayers: ', res1.data);
        if (!mounted) return;
        setFriendRequestPlayers(res1.data);
        setNumFriendRequests(res1.data.length);
      } catch (err) {
        console.log(err);
      }
    };
    getFriendRequestPlayers();
    // Specify how to clean up after this effect:
    return () => {
      mounted = false;
    };
  }, [player.data.player._id, player.token]);

  const handleSearch = async (searchInput) => {
    //Player search
    setPlayerSearchResults(true);

    try {
      if (searchInput === '') {
        //console.log('empty');
        setIsSearching(false);
        setSearchedPlayers([]);
      } else if (validator.isAlphanumeric(searchInput)) {
        //console.log(searchInput);
        setIsSearching(true);
        const res = await axiosConn.get(`/players/regex/${searchInput}`, {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        });
        //console.log(res.data.data.data);
        setSearchedPlayers(res.data.data.data);
        setIsSearching(false);
      }
    } catch (err) {
      setIsSearching(false);
      setSearchedPlayers([]);
      console.log(err);
    }
  };

  // const onSuccess = (data) => {
  //   // console.log('data: ', data);
  // };

  // const onError = (err) => {
  //   // console.log('err: ', err);
  // };

  // const { data, status, refetch } = useGetConversations(
  //   onSuccess,
  //   onError,
  //   player,
  // );
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    //set conversations
    if (conversationsStatus === 'success') {
      if (!mounted) return;
      setConversations(conversationsData);
    }
    return () => {
      mounted = false;
    };
  }, [conversationsStatus, conversationsData, setConversations]);

  // useEffect(() => {
  //   conversations?.map((conversation) => {
  //     //console.log(conversation.unReadMessages);
  //     setTotalUnReadMessages((prev) => prev + conversation.unReadMessages);
  //   });

  //   // setShowUnReadMessages(totalUnReadMessages > 0);
  //   return () => {
  //     setTotalUnReadMessages(0);
  //   };
  // }, [conversations]);

  useEffect(() => {
    if (typeof setTotalUnReadMessages !== 'function') return;

    let mounted = true;
    let total = 0;
    conversations?.forEach((conversation) => {
      total += conversation.unReadMessages ?? 0; // Use 0 if unReadMessages is undefined
    });
    // console.log(total);
    if (!mounted) return;
    setTotalUnReadMessages(total);

    return () => {
      // console.log('unmounted');
      setTotalUnReadMessages(0);
      mounted = false;
    };
  }, [conversations]);

  useEffect(() => {
    const handleFocus = () => {
      if (typeof refetchConversations === 'function') {
        refetchConversations();
      }
    };

    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [refetchConversations]);

  useEffect(() => {
    const calculateAge = (bDay, bMonth, bYear) => {
      // Validate input values
      if (typeof bDay !== 'number' || bDay < 1 || bDay > 31) {
        console.log('Invalid or missing day');
        return null; // or appropriate error handling
      }
      if (typeof bMonth !== 'number' || bMonth < 1 || bMonth > 12) {
        console.log('Invalid or missing month');
        return null; // or appropriate error handling
      }
      if (
        typeof bYear !== 'number' ||
        bYear < 1900 ||
        bYear > new Date().getFullYear()
      ) {
        console.log('Invalid or missing year');
        return null; // or appropriate error handling
      }

      const today = new Date();
      const birthDate = new Date(bYear, bMonth - 1, bDay); // Month is 0-based
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };

    // To unlock galaxies, the player must have at least 1000 jgkTokens and be at least 18 years old
    if (currPlayer?.jgkTokens >= unlockGalaxyJGKAmount) {
      const age = calculateAge(
        currPlayer?.bDay,
        currPlayer?.bMonth,
        currPlayer?.bYear,
      );
      // console.log(age, currPlayer?.jgkTokens);
      if (age >= 18 || age === null) {
        localStorage.setItem('unlockedGalaxies', 'true');
        setUnlockedGalaxies(true);
      } else {
        localStorage.setItem('unlockedGalaxies', 'false');
        setUnlockedGalaxies(false);
      }
    } else {
      localStorage.setItem('unlockedGalaxies', 'false');
    }
  }, [currPlayer]);

  useEffect(() => {
    const cleanup = addShakeAnimation(imgRef);
    return cleanup;
  }, []);
  // console.log(parent);
  // console.log(totalUnReadMessages);
  // console.log(!isExcluded());
  // console.log('isExcluded() returns:', isExcluded());
  //Topbar is unclocked if the player role is galaxyAdmin, admin, or moderator
  return (
    <div
      ref={friendRequestPlayersMenu}
      className={
        !mobileView
          ? parent === 'PlayerMap'
            ? 'topbarContainerPlayerMap'
            : 'topbarContainer'
          : parent === 'Messenger'
          ? 'topbarContainerMobileChat'
          : 'topbarContainerMobile'
      }
    >
      <div className={!mobileView ? 'topbarLeft' : 'topbarLeftMobile'}>
        <Link
          to={
            unlockedGalaxies ||
            player.data.player.role === 'admin' ||
            player.data.player.role === 'moderator' ||
            player.data.player.role === 'galaxyAdmin'
              ? '/home'
              : from && from !== 'null'
              ? from
              : '/'
          }
          style={{ textDecoration: 'none' }}
        >
          {/* <span className="logo">JOE GALAXY</span> */}
          {width < 777 ? (
            <img
              src={
                'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1664565843/Images/JOEGALAXYDopeGalaxyLogooriginal_tecmer.png'
              }
              alt=""
              className={
                !mobileView ? 'topbarJGLogoImg' : 'topbarJGLogoImgMobile'
              }
            />
          ) : (
            <img
              src={fromToImg(from, 'Topbar')}
              alt=""
              className={
                !mobileView ? 'topbarJGLogoImg' : 'topbarJGLogoImgMobile'
              }
            />
          )}
        </Link>
      </div>
      <div className="topbarCenter">
        <div className={!mobileView ? 'searchbar' : 'searchbarMobile'}>
          <Search className={!mobileView ? 'searchIcon' : 'searchIconMobile'} />
          <label className="labelSearch" htmlFor="search">
            <input
              type="search"
              autoComplete="on"
              aria-label="Search"
              placeholder={width > 466 ? 'Search for players' : 'Search'}
              className={!mobileView ? 'searchInput' : 'searchInputMobile'}
              onChange={(e) => handleSearch(e.target.value)}
              ref={searchInput}
            />
          </label>
          <div className="playerSearchResults" ref={menu}>
            {searchedPlayers.length > 0 &&
              searchedPlayers != null &&
              typeof searchedPlayers !== 'undefined' &&
              playerSearchResults && (
                <PlayerSearchResults searchedPlayers={searchedPlayers} />
              )}
          </div>
        </div>
      </div>
      <div className="topbarMiddleRight">
        {currPlayer?.jgkTokens > 999 && !mobileView && (
          <div onClick={toggleTheme} className="topbarNotificationIconItem">
            <img
              className={
                currPlayer?.jgkTokens > 4999
                  ? 'lilDImgTopbar'
                  : 'chirbichawImgTopbar'
              }
              //src={post.img}
              src={
                currPlayer?.jgkTokens > 9999
                  ? 'https://res.cloudinary.com/joegalaxy/image/upload/v1690738859/logo_transparency2_sdvx5i.png'
                  : currPlayer?.jgkTokens > 4999 &&
                    currPlayer?.jgkTokens < 10000
                  ? 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1683396920/logo_transparency4_nyioc3.png'
                  : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/fl_progressive:steep/v1680380660/imageedit_0_3957215681_osoxqy.png'
              }
              // width="100"
              // height="100"
              alt="Chirbichaw"
              title="Chirbichaw"
              loading="eager"
              ref={imgRef}
            />
          </div>
        )}
      </div>
      <div className="topbarRight">
        {/* <div className="topbarLinks">
          <Link to="/" style={{ textDecoration: 'none' }}>
            <span className="topbarLink">{player.data.player.playerId}</span>
          </Link>
        </div> */}
        {/* <div className="playerSearchResults">
          {friendRequestPlayers && showFriendRequestPlayersMenu && (
            <PlayerSearchResults searchedPlayers={friendRequestPlayers} />
          )}
        </div> */}
        <div className="friendRequestPlayersSearchResults">
          {friendRequestPlayers && showFriendRequestPlayersMenu && (
            <PlayerSearchResults searchedPlayers={friendRequestPlayers} />
          )}
        </div>
        <div className="topbarIcons">
          {/* <div
            className={
              !mobileView ? 'topbarAdIconItem' : 'topbarAdIconItemMobile'
            }
          >
            <Link to="/advertise" style={{ textDecoration: 'none' }}>
              <Tooltip title={`Advertise`}>
                <SellOutlinedIcon fontSize="large" />
              </Tooltip>
            </Link>
          </div>
          <div className="topbarMemeIconItem">
            <Link to="/meme" style={{ textDecoration: 'none' }}>
              <Tooltip title={`Meme Machine`}>
                <QueuePlayNextOutlinedIcon fontSize="large" />
              </Tooltip>
            </Link>
          </div> */}
          {/* {!mobileView && currPlayer?.jgkTokens > 999 && (
            <div className="topbarNotificationIconItem">
              <img
                className="chirbichawImgTopbar"
                //src={post.img}
                src="https://res.cloudinary.com/joegalaxy/image/upload/v1680380660/imageedit_0_3957215681_osoxqy.png"
                // width="100"
                // height="100"
                alt="Chirbichaw"
                title="Chirbichaw"
                loading="eager"
              />
            </div>
          )} */}
          {!mobileView && (
            <div className="topbarNotificationIconItem">
              <Link to={`/jgktoken`} style={{ textDecoration: 'none' }}>
                <Tooltip
                  title={`${(
                    Math.round(parseFloat(currPlayer?.jgkTokens) * 100) / 100
                  ).toFixed(2)} jgkTokens`}
                >
                  <CurrencyExchangeOutlinedIcon
                    fontSize={!mobileView ? 'small' : 'large'}
                  />
                </Tooltip>
                <span
                  className={
                    currPlayer?.jgkTokens < 9999
                      ? 'topbarNotificationIconBadgeJGK'
                      : 'topbarNotificationIconBadgeJGK10K'
                  }
                >
                  {currPlayer?.jgkTokens &&
                    (
                      Math.round(parseFloat(currPlayer?.jgkTokens) * 100) / 100
                    ).toFixed(2)}
                </span>
              </Link>
            </div>
          )}

          <div
            className="topbarIconItem"
            onClick={() => {
              setShowFriendRequestPlayersMenu((prev) => !prev);
            }}
          >
            <Tooltip title={`${numFriendRequests} friend requests`}>
              <Person fontSize={!mobileView ? 'small' : 'large'} />
            </Tooltip>
            <span className="topbarIconBadge">{numFriendRequests}</span>
          </div>
          <div className="topbarIconItem">
            <Link to={`/messenger/''`} style={{ textDecoration: 'none' }}>
              <Tooltip title={`${totalUnReadMessages} unread messages`}>
                <Chat fontSize={!mobileView ? 'small' : 'large'} />
              </Tooltip>
              <span className="topbarIconBadge">{totalUnReadMessages}</span>
            </Link>
          </div>
          <div className="topbarIconItem">
            <span>
              <Tooltip title="Logout of Joe Galaxy">
                <LogoutIcon
                  className="white-color"
                  onClick={() =>
                    Logoff(currPlayer?.jgkTokens, unlockGalaxyJGKAmount)
                  }
                  fontSize={!mobileView ? 'small' : 'large'}
                />
              </Tooltip>
            </span>
          </div>
        </div>

        {!mobileView ? (
          <Link to={`/profile/${player.data.player.playerId}`}>
            <img
              src={profileImage}
              alt={`${player.data.player.playerId}`}
              title={`${player.data.player.playerId}`}
              className={!mobileView ? 'topbarImg' : 'topbarImgMobile'}
            />
          </Link>
        ) : (
          <div
            className={`circle_icon hover1 ${
              showPlayerMenu && 'active_header'
            }`}
            ref={playermenu}
          >
            {showTip && currentTip < 2 && (
              <div className="tip left-tip">
                <p>{tips[currentTip]}</p>
                <button onClick={handleTipDismiss}>Got it</button>
              </div>
            )}
            <div
              onClick={() => {
                setShowPlayerMenu((prev) => !prev);
                if (parent && typeof setMmenuOpen === 'function') {
                  setMmenuOpen((prev) => !prev);
                }
              }}
            >
              <div style={{ transform: 'translateY(2px)' }}>
                {/* <ArrowDown /> */}
                <img
                  src={profileImage}
                  alt={`${player.data.player.playerId}`}
                  title={`${player.data.player.playerId}`}
                  className={!mobileView ? 'topbarImg' : 'topbarImgMobile'}
                />
              </div>
            </div>

            {showPlayerMenu && (
              <PlayerMenu player={player.data.player} parent={parent} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
