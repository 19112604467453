import { useQuery } from 'react-query';
import axiosConn from '../axiosConn';

const fetchPosts = async ({ queryKey }) => {
  const [_, player, playerId, page, endpoint, galaxyId] = queryKey;
  try {
    let res;
    if (playerId && endpoint !== 'galaxyPosts') {
      res = await axiosConn.get(
        `/posts/profile/${playerId}${page ? `?page=${page}` : ''}`,
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      res.data = res.data.filter((post) => !post.galaxyId);
      // console.log(res.data);
    } else if (endpoint === 'galaxyPosts') {
      res = await axiosConn.get(
        `/galaxies/${galaxyId}/posts/${page ? `?page=${page}` : ''}`,
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
    } else {
      res = await axiosConn.get(
        `/posts/timeline/${player.data.player._id}${
          page ? `?page=${page}` : ''
        }`,
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      res.data = res.data.filter((post) => !post.galaxyId);
      // console.log(res.data);
    }
    // Return res.data.posts if endpoint is 'galaxyPosts', otherwise return res.data
    return endpoint === 'galaxyPosts' ? res.data.posts : res.data;
  } catch (err) {
    console.log(err);
  }
};

export const useGetPosts = (
  onSuccess,
  onError,
  player,
  playerId,
  page,
  endpoint = 'posts',
  galaxyId = null,
) => {
  // Add galaxyId parameter with default value
  return useQuery(
    ['getPosts', player, playerId, page, endpoint, galaxyId],
    fetchPosts,
    {
      // Add galaxyId to queryKey
      onSuccess,
      onError,
      keepPreviousData: true,
    },
  );
};
