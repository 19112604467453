// useInfiniteScroll.js
// import { useEffect } from 'react';

// const useInfiniteScroll = (callback) => {
//   const handleScroll = () => {
//     const scrollTop =
//       'scrollingElement' in document
//         ? document.scrollingElement.scrollTop
//         : window.pageYOffset;
//     const scrollHeight =
//       'scrollingElement' in document
//         ? document.scrollingElement.scrollHeight
//         : document.body.scrollHeight;

//     if (scrollTop + window.innerHeight >= scrollHeight - 200) {
//       callback();
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   return {};
// };

// export default useInfiniteScroll;
// useInfiniteScroll.js
import { useEffect, useRef } from 'react';

const useInfiniteScroll = (callback, direction = 'down') => {
  const lastScrollTop = useRef(0);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;

    if (
      (direction === 'up' && scrollTop < lastScrollTop.current) ||
      (direction === 'down' &&
        scrollTop > lastScrollTop.current &&
        scrollTop + window.innerHeight >= scrollHeight - 200)
    ) {
      // console.log(scrollTop);
      callback();
    }

    lastScrollTop.current = scrollTop;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return {};
};

export default useInfiniteScroll;
