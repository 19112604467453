export const handleYouTube = (url, setIsVideo, setIsTube) => {
  if (!url) {
    console.error('URL is undefined');
    return;
  }
  const videoExtensions = ['mp4', 'webm', 'ogg'];
  let isYouTube = url.includes('youtube.com') || url.includes('youtu.be');
  // console.log('isYouTube:', isYouTube); // Log whether the URL is a YouTube URL

  // If the URL is a shortened YouTube URL, convert it to the standard format
  if (url.includes('youtu.be')) {
    const videoId = url.match(/youtu\.be\/([^?]+)/)[1];
    url = `https://www.youtube.com/watch?v=${videoId}`;
    isYouTube = true;
    // console.log('url:', url); // Log the converted URL
  }

  const extension = url.split('.').pop();

  let videoId = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)[2];
  if (videoId) {
    videoId = videoId.split('?')[0]; // Add this line to remove query parameters
    videoId = videoId.split(/[^0-9a-z_\-]/i)[0];
  }
  // console.log('videoId:', videoId); // Log the extracted video ID

  if (videoExtensions.includes(extension) || isYouTube) {
    setIsVideo(true);
  } else {
    setIsVideo(false);
  }

  if (isYouTube) {
    setIsTube(true);
  } else {
    setIsTube(false);
  }

  if (isYouTube) {
    // console.log('window.YT:', window.YT); // Log the YouTube Iframe API object
    if (window.YT) {
      createPlayer(videoId);
    } else {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = () => createPlayer(videoId);
    }
  }

  function createPlayer(videoId) {
    const player = new window.YT.Player('ytplayer', {
      videoId: videoId,
      events: {
        onStateChange: function (event) {
          if (
            event.data === window.YT.PlayerState.ENDED &&
            player &&
            typeof player.playVideo === 'function'
          ) {
            player.playVideo();
          }
        },
      },
    });
    // console.log('player:', player); // Log the created player
  }
};
