// utils.js
export const handleEmoji = (
  e,
  { emoji },
  text,
  setText,
  textRef,
  setCursorPosition,
) => {
  const ref = textRef.current;
  ref.focus();
  const start = text.substring(0, ref.selectionStart);
  const end = text.substring(ref.selectionStart);
  const newText = start + emoji + end;
  setText(newText);
  setCursorPosition(start.length + emoji.length);
};
