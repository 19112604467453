import './myfriendstosharepost.css';
import { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';

import { Link } from 'react-router-dom';

export default function MyFriendsToSharePost({
  searchedPlayers,
  myFriendsToSharePost,
  SetMyFriendsToSharePost,
}) {
  // console.log(searchedPlayers);
  const checkbox = useRef();

  const handlePlayerChecked = (e, player) => {
    // console.log('handlePlayerChecked Top', e);
    if (e.target.checked)
      SetMyFriendsToSharePost((oldArray) => [...oldArray, player]);
    else {
      SetMyFriendsToSharePost(
        myFriendsToSharePost.filter(
          (item) => item.playerId !== player.playerId,
        ),
      );
    }
    // console.log(player);
  };

  return (
    <>
      <div className="myFriendsToSharePostWrapper">
        <ul className="ulollist">
          {searchedPlayers
            .sort((a, b) => {
              return b.jgkTokens - a.jgkTokens;
            })
            .map((player) => {
              return (
                <li key={player._id}>
                  <div className="myFriendsToSharePostResult">
                    <Link
                      to={'/profile/' + player.playerId}
                      style={{ textDecoration: 'none' }}
                    >
                      <img
                        src={
                          player.profilePhoto
                            ? player.profilePhoto
                            : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                        }
                        alt=""
                        className="topbarImg"
                      />
                    </Link>
                    <span className="myFriendsToSharePostPlayersPlayerId">
                      {player.playerId}
                    </span>
                    <Form>
                      {['checkbox'].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                          <Form.Check
                            ref={checkbox}
                            type={type}
                            // defaultChecked={sendJGKPlayer}
                            id={`${player._id}`}
                            label={` Share`}
                            onClick={(e) => handlePlayerChecked(e, player)}
                            // disabled={cashoutGiftCard ? true : false}
                          />
                        </div>
                      ))}
                    </Form>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
}
