// useMobileView.js
import { useMediaQuery } from 'react-responsive';

const useMobileView = () => {
  const isMobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  return isMobileView;
};

export default useMobileView;
