import { useQuery } from 'react-query';
import axiosConn from '../axiosConn';

//converted to react query from useState api call.
const getConversations = async ({ queryKey }) => {
  //   console.log(queryKey[1]);
  try {
    const res = await axiosConn.get(
      '/conversations/' + queryKey[1].data.player._id,
      {
        headers: {
          Authorization: `Bearer ${queryKey[1].token}`,
        },
      },
    );
    // console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const useGetConversations = (onSuccess, onError, player) => {
  //   console.log(player);
  return useQuery(['getConversations', player], getConversations, {
    onSuccess,
    onError,
  });
};
