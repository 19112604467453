import React, { useState, useContext } from 'react';
import useMobileView from '../../hooks/useMobileView';
import validator from 'validator';
import './createGalaxyForm.css';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';

function CreateGalaxyForm() {
  const { player } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [summaryLength, setSummaryLength] = useState(0);

  const [description, setDescription] = useState('');
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [location, setLocation] = useState('');
  const [locationLength, setLocationLength] = useState(0);
  const [category, setCategory] = useState('General');
  const [contactInfo, setContactInfo] = useState('');
  const [kidFriendly, setKidFriendly] = useState(false);
  const [adultOnly, setAdultOnly] = useState(false);

  const [error, setError] = useState('');
  const mobileView = useMobileView();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newGalaxy = {
      name,
      summary,
      description,
      category,
      contactInfo,
      location,
      kidFriendly,
    };
    // console.log(newGalaxy);
    try {
      const response = await axiosConn.post('/galaxies', newGalaxy, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });

      // Clear the form
      setName('');
      setSummary('');
      setDescription('');
      setCategory('');
      setContactInfo('');
      setLocation('');
      setKidFriendly(false);

      // Log the response from the server
      //   console.log(response.data);
    } catch (error) {
      console.error('Error creating new galaxy:', error);
    }
  };

  const handleInputChange = (e) => {
    // console.log(e.target.value);
    const { name, value } = e.target;
    // console.log(name, value);

    // Update the corresponding state
    if (name === 'name') setName(value);
    else if (name === 'summary') {
      setSummary(value);
      setSummaryLength(value.length);
    } else if (name === 'description') {
      setDescription(value);
      setDescriptionLength(value.length);
    } else if (name === 'location') {
      setLocation(value);
      setLocationLength(value.length);
    } else if (name === 'category') setCategory(value);
    else if (name === 'contactInfo') setContactInfo(value);

    // Name
    if (name === 'name' && value !== '' && !/^[a-zA-Z\s]*$/.test(value)) {
      setError('Name must only contain Alpha Characters and spaces.');
      return;
    }

    // Summary
    if (
      name === 'summary' &&
      value !== '' &&
      (value.length < 10 || value.length > 500)
    ) {
      setError('Summary length must be between 10 and 500 characters.');
      return;
    }

    // Description
    if (
      name === 'description' &&
      value !== '' &&
      (value.length < 20 || value.length > 500)
    ) {
      setError('Description length must be between 20 and 500 characters.');
      return;
    }

    // Location
    if (
      name === 'location' &&
      value !== '' &&
      (value.length < 10 || value.length > 40)
    ) {
      setError('Location length must be between 10 and 40 characters.');
      return;
    }

    setError('');
  };

  return (
    <div className="fullWidth">
      <form className="registerBox fullWidth" onSubmit={handleSubmit}>
        <div className="reg_line">
          <input
            type="text"
            name="name"
            value={name}
            onChange={handleInputChange}
            required
            className="loginInput"
            placeholder="Name *"
          />
        </div>
        <div className="reg_line">
          <textarea
            name="summary"
            value={summary}
            onChange={handleInputChange}
            required
            minLength={10}
            maxLength={500}
            className="loginInput"
            placeholder="Summary *"
          />
          <p>{summaryLength}/500</p>
        </div>
        <div className="reg_line">
          <textarea
            name="description"
            value={description}
            onChange={handleInputChange}
            required
            minLength={20}
            maxLength={500}
            className="loginInput"
            placeholder="Description"
          />
          <p>{descriptionLength}/500</p>
        </div>
        <div className="reg_line">
          <input
            type="text"
            name="category"
            value={category}
            onChange={handleInputChange}
            required
            className="loginInput"
            placeholder="Category *"
          />
        </div>
        <div className="reg_line">
          <input
            type="text"
            name="contactInfo"
            value={contactInfo}
            onChange={handleInputChange}
            required
            className="loginInput"
            placeholder="Contact Info *"
          />
        </div>
        <div className="reg_line">
          <input
            type="text"
            name="location"
            value={location}
            onChange={handleInputChange}
            required
            minLength={10}
            maxLength={40}
            className="loginInput"
            placeholder="Location"
          />
          <p>{locationLength}/40</p>
        </div>
        <div className="reg_line">
          <label>
            <input
              type="checkbox"
              name="kidFriendly"
              checked={kidFriendly}
              onChange={(e) => setKidFriendly(e.target.checked)}
              disabled={adultOnly}
            />
            Kid Friendly
          </label>
        </div>
        <div className="error_message">{error}</div>
        <div className="reg_line">
          <button type="submit" className="loginButton">
            Create Galaxy
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateGalaxyForm;
