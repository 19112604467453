export const uploadProgress = (setProgress, setUploadPercentage) => {
  return {
    onUploadProgress: (e) => {
      const { loaded, total } = e;
      setProgress((loaded / total) * 100);
      let percent = Math.floor((loaded * 90) / total); // Reserve 10% for server processing
      setUploadPercentage(percent);
    },
  };
};
