import React, { useState, useRef } from 'react';
import confetti from 'canvas-confetti';
import { Link } from 'react-router-dom';
import playSound from '../../utils/playSound';

const Like = ({
  imgRef,
  text,
  likes,
  likesProfileImgs,
  isLiked,
  likeHandler,
  mobileView,
  PF,
  title,
  width,
  textOffset = 0,
}) => {
  const moneySound1 =
    'https://res.cloudinary.com/joegalaxy/video/upload/v1706507635/coin-and-money-bag-5-185266_eb67fk.mp3';
  const [showText, setShowText] = useState(false);
  const [textPosition, setTextPosition] = useState({ top: '0px', left: '0px' });
  const [isClickable, setIsClickable] = useState(true); // New state to manage clickability
  const likeButtonRef = useRef(null); // Create a ref for the like button

  const handleClickConfettiText = () => {
    if (imgRef.current && likeButtonRef.current) {
      const { top, left, height, width } =
        imgRef.current.getBoundingClientRect();
      const { offsetTop: likeButtonTop, offsetLeft: likeButtonLeft } =
        likeButtonRef.current;
      setTextPosition({
        top: `${likeButtonTop + textOffset}px`,
        left: `${likeButtonLeft}px`,
      });
      setShowText(true);
      confetti({
        particleCount: 100,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: (left + width / 2) / window.innerWidth,
          y: (top + height / 2) / window.innerHeight,
        },
      });
      playSound(moneySound1);
      setTimeout(() => setShowText(false), 2000);
    }
  };

  return (
    <div className="postBottomLeft">
      <div>
        <img
          className={
            !mobileView
              ? isLiked
                ? 'likeIcon likeIconLiked'
                : 'likeIcon'
              : isLiked
              ? 'likeIconLiked likeIconMobile'
              : 'likeIconMobile'
          }
          src={`${PF}like_bei1gu.png`}
          onClick={() => {
            if (!isClickable) return; // Ignore click if not clickable
            likeHandler();
            !isLiked && handleClickConfettiText();
            setIsClickable(false); // Disable further clicks
            setTimeout(() => setIsClickable(true), 10000); // Re-enable after 2 seconds
          }}
          alt=""
          title={isLiked ? 'You liked this' : ''}
          loading="lazy"
          ref={likeButtonRef} // Add the ref to the like button
        />
        {showText && (
          <div
            style={{
              position: 'absolute',
              top: textPosition.top,
              left: textPosition.left,
              color: '#8a2be2',
              zIndex: 1,
            }}
          >
            {text}
          </div>
        )}
      </div>
      <span
        className={!mobileView ? 'postLikeCounter' : 'postLikeCounterMobile'}
      >
        {likes > 0
          ? likes === 1
            ? '1 like'
            : `${likes} likes`
          : 'be 1st to like '}
      </span>
      <div className="postLikeProfileImgs">
        {likes > 0 &&
          likesProfileImgs &&
          likesProfileImgs
            .slice(0, width < 360 ? 4 : 6)
            .map((likeProfileImg, i) => (
              <Link to={`/profile/${likeProfileImg.playerId}`} key={i}>
                <img
                  src={
                    likeProfileImg.profilePhoto
                      ? likeProfileImg.profilePhoto
                      : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                  }
                  alt=""
                  loading="lazy"
                  style={{
                    transform: `translateX(${-i * 8}px)`,
                    zIndex: `${i}`,
                  }}
                />
              </Link>
            ))}
      </div>
    </div>
  );
};

export default Like;
