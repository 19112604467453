import React, { createContext, useState, useContext, useEffect } from 'react';

const SuspensionContext = createContext();

export const SuspensionProvider = ({ children, playerId }) => {
  const [isSuspended, setIsSuspended] = useState(() => {
    const savedSuspension = localStorage.getItem(`isSuspended_${playerId}`);
    const suspensionEndTime = localStorage.getItem(
      `suspensionEndTime_${playerId}`,
    );
    if (savedSuspension && suspensionEndTime) {
      const now = Date.now();
      if (now < parseInt(suspensionEndTime, 10)) {
        return true;
      } else {
        localStorage.removeItem(`isSuspended_${playerId}`);
        localStorage.removeItem(`suspensionEndTime_${playerId}`);
        return false;
      }
    }
    return false;
  });

  useEffect(() => {
    if (isSuspended) {
      const suspensionEndTime = localStorage.getItem(
        `suspensionEndTime_${playerId}`,
      );
      if (!suspensionEndTime || Date.now() >= parseInt(suspensionEndTime, 10)) {
        const newSuspensionEndTime = Date.now() + 3600000; // 1 hour
        localStorage.setItem(
          `suspensionEndTime_${playerId}`,
          newSuspensionEndTime.toString(),
        );
      }
      localStorage.setItem(`isSuspended_${playerId}`, 'true');
    } else {
      const suspensionEndTime = localStorage.getItem(
        `suspensionEndTime_${playerId}`,
      );
      if (suspensionEndTime && Date.now() < parseInt(suspensionEndTime, 10)) {
        setIsSuspended(true);
      } else {
        localStorage.removeItem(`isSuspended_${playerId}`);
        localStorage.removeItem(`suspensionEndTime_${playerId}`);
      }
    }
  }, [isSuspended, playerId]);

  return (
    <SuspensionContext.Provider value={{ isSuspended, setIsSuspended }}>
      {children}
    </SuspensionContext.Provider>
  );
};

export const useSuspension = () => useContext(SuspensionContext);
