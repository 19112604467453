import { createContext, useContext, useEffect, useState } from 'react';
import { getLocationAndFetchSunTimes } from '../utils/sunTimesUtil';

const INITIAL_STATE = {
  darkTheme: true,
};

const ThemeContext = createContext(INITIAL_STATE);
const ThemeUpdateContext = createContext();

export function useTheme() {
  return useContext(ThemeContext);
}

export function useThemeUpdate() {
  return useContext(ThemeUpdateContext);
}

export const ThemeProvider = ({ children, playerToken }) => {
  const [darkTheme, setDarkTheme] = useState(INITIAL_STATE.darkTheme);
  // console.log(playerToken);
  useEffect(() => {
    const savedTheme = localStorage.getItem('darkTheme');
    if (savedTheme !== null) {
      setDarkTheme(JSON.parse(savedTheme));
    } else {
      fetchSunTimesAndSetTheme();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('darkTheme', JSON.stringify(darkTheme));
  }, [darkTheme]);

  async function fetchSunTimesAndSetTheme() {
    try {
      const sunTimes = await getLocationAndFetchSunTimes(playerToken);
      console.log('Sun times:', sunTimes);
      if (sunTimes) {
        const { sunriseTime, sunsetTime } = sunTimes;
        const currentTime = new Date();
        if (currentTime >= sunriseTime && currentTime < sunsetTime) {
          setDarkTheme(false);
        } else {
          setDarkTheme(true);
        }
      }
    } catch (error) {
      console.error('Error fetching sun times and setting theme:', error);
    }
  }

  function toggleTheme() {
    setDarkTheme((prevDarkTheme) => !prevDarkTheme);
  }

  return (
    <ThemeContext.Provider value={{ darkTheme }}>
      <ThemeUpdateContext.Provider value={toggleTheme}>
        {children}
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
};
