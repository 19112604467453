import { createContext, useEffect, useReducer } from 'react';
import AuthReducer from './AuthReducer';
import { UpdatePlayer } from './AuthActions'; // Import the UpdatePlayer action creator

const INITIAL_STATE = {
  player: JSON.parse(localStorage.getItem('player')) || null,
  isFetching: false,
  error: false,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem('player', JSON.stringify(state.player));
  }, [state.player]);

  // Define the updatePlayer function
  const updatePlayer = (playerData) => {
    dispatch(UpdatePlayer(playerData));
  };

  return (
    <AuthContext.Provider
      value={{
        player: state.player,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
        updatePlayer, // Add the updatePlayer function to the context value
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
