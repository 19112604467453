import { useEffect, useState, useRef, useContext } from 'react';
import { useConfettiText } from '../../hooks/useConfettiText';
import { useMediaQuery } from 'react-responsive';
import axiosConn from '../../axiosConn';
import { AuthContext } from '../../context/AuthContext';
import './installButton.css';

const INSTALL_BUTTON_PARTICLE_COUNT = 2000;

const InstallButton = () => {
  const { player } = useContext(AuthContext);

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const inputRef = useRef(null);

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  //   console.log('mobileView', mobileView);
  const [showConfettiText, ConfettiTextComponent] = useConfettiText(
    inputRef,
    mobileView,
    '+100 JGK Thats Major!',
    INSTALL_BUTTON_PARTICLE_COUNT,
    'installButton',
    false,
  );

  useEffect(() => {
    // Check if the app is already installed or if the snooze period is active
    const snoozeTimestamp = localStorage.getItem('snoozeTimestamp');
    if (
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone === true ||
      (snoozeTimestamp && new Date().getTime() < parseInt(snoozeTimestamp, 10))
    ) {
      setIsInstalled(true);
      setShowButton(false);
    } else {
      // Detect specific browsers and conditions to show the install button
      if (
        navigator.userAgent.includes('iPhone') &&
        !navigator.userAgent.includes('CriOS') // Exclude Chrome on iOS
      ) {
        console.log('Safari on iOS detected');
        setShowButton(true);
      } else if (
        navigator.userAgent.includes('Android') &&
        navigator.userAgent.includes('Firefox')
      ) {
        console.log('Firefox on Android detected');
        setShowButton(true);
      }
    }

    // Listen for the beforeinstallprompt event
    const handleBeforeInstallPrompt = (e) => {
      console.log('beforeinstallprompt event fired');
      e.preventDefault();
      setDeferredPrompt(e);
      // Only show the button if not in snooze period
      if (
        !snoozeTimestamp ||
        new Date().getTime() >= parseInt(snoozeTimestamp, 10)
      ) {
        setShowButton(true);
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Listen for the appinstalled event
    const handleAppInstalled = () => {
      console.log('appinstalled event fired');
      setShowButton(false);
    };

    window.addEventListener('appinstalled', handleAppInstalled);

    // Cleanup function
    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const handleClick = () => {
    // console.log('Install button clicked');
    // console.log('deferredPrompt:', deferredPrompt);

    if (
      navigator.userAgent.includes('iPhone') &&
      !navigator.userAgent.includes('CriOS')
    ) {
      alert('To install this app, tap Share and then Add to Home Screen.');
      //TODO: do a patch update on the player to log iphone player so install likely but unknown.
    } else if (
      navigator.userAgent.includes('Android') &&
      navigator.userAgent.includes('Firefox')
    ) {
      alert('To install this app, tap Menu and then Add to Home Screen.');
    } else if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      deferredPrompt.userChoice // Wait for the user to respond to the prompt
        .then((choiceResult) => {
          const updatePlayer = async () => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the install prompt');
              await axiosConn.patch(
                '/players/updateMe',
                { pwaIsInstalled: true },
                {
                  headers: {
                    Authorization: `Bearer ${player.token}`,
                  },
                },
              );
            } else {
              console.log('User dismissed the install prompt');
            }
            setDeferredPrompt(null);
          };
          updatePlayer();
        });
    }
  };

  const handleSnoozeClick = () => {
    const snoozeDuration = 1000 * 60 * 60 * 24; // 24 hours
    const snoozeUntil = new Date().getTime() + snoozeDuration;
    localStorage.setItem('snoozeTimestamp', snoozeUntil);
    setShowButton(false);
  };
  // console.log(
  //   'isInstalled:',
  //   isInstalled,
  //   'showButton:',
  //   showButton,
  //   'deferredPrompt:',
  //   deferredPrompt,
  // );
  // Don't hide the install button if showButton is true
  if (!showButton && isInstalled) return null;

  return (
    <div ref={inputRef} className="installButton">
      <ConfettiTextComponent />
      <div className="refreshToUpdate postLeftButtonRank:hover">
        {showButton && (
          <button
            className="refreshToUpdateButton"
            type="button"
            onClick={handleClick}
          >
            Install
            <br />
            Receive 100 JGK!
          </button>
        )}
        {showButton && (
          <button
            className="snoozeButton"
            type="button"
            onClick={handleSnoozeClick} // You'll need to define this function
          >
            Snooze
          </button>
        )}
      </div>
    </div>
  );
};

export default InstallButton;
