import { useQuery } from 'react-query';
import axiosConn from '../axiosConn';

const getGalaxies = async ({ queryKey }) => {
  const [_, player, page] = queryKey;
  try {
    // console.log('page: ', page);
    const res = await axiosConn.get(`/galaxies?page=${page}`, {
      headers: {
        Authorization: `Bearer ${player.token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const useGetGalaxies = (onSuccess, onError, player, page) => {
  return useQuery(['getGalaxies', player, page], getGalaxies, {
    onSuccess,
    onError,
    keepPreviousData: true,
  });
};
