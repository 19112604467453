export const addShakeAnimation = (imgRef, duration = 500, interval = 30000) => {
  const intervalId = setInterval(() => {
    if (imgRef.current) {
      imgRef.current.classList.add('shake');
      setTimeout(() => {
        if (imgRef.current) {
          imgRef.current.classList.remove('shake');
        }
      }, duration); // Duration of the shake animation
    }
  }, interval); // Interval for the shake animation

  return () => clearInterval(intervalId);
};
