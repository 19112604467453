import React, { useEffect, useState, useContext, useRef } from 'react';
import axiosConn from '../../axiosConn';
import './galaxyRightbar.css';
import { AuthContext } from '../../context/AuthContext';
import { SocketContext } from '../../context/socket';
import useMobileView from '../../hooks/useMobileView';
import { Link } from 'react-router-dom';
import useStyles from '../../utils/useStyles';
import {
  isPlayerAuthorized,
  handleRemoveSubscriber,
} from '../../utils/galaxyUtils';

function GalaxyRightBar({
  galaxy,
  onJgkTokenSumChange,
  galaxyChatOnlinePlayers,
}) {
  const [subscribers, setSubscribers] = useState([]);
  const [galaxyOnlinePlayers, setGalaxyOnlinePlayers] = useState([]);
  const { player } = useContext(AuthContext);
  const socket = useContext(SocketContext);
  const mobileView = useMobileView();
  const prevOnlinePlayersRef = useRef([]);
  const classes = useStyles();

  // Pass the sum to the parent component
  // useEffect(() => {
  //   onJgkTokenSumChange(jgkTokenSum);
  // }, [jgkTokenSum, onJgkTokenSumChange]);
  // const isPlayerAuthorized =
  //   (galaxy.owners || []).some(
  //     (owner) => owner._id === player.data.player._id,
  //   ) ||
  //   (galaxy.administrators || []).some(
  //     (admin) => admin._id === player.data.player._id,
  //   ) ||
  //   (galaxy.moderators || []).some(
  //     (moderator) => moderator._id === player.data.player._id,
  //   );

  // const handleRemoveSubscriber = async (subscriberId) => {
  //   if (isPlayerAuthorized) {
  //     try {
  //       await axiosConn.delete(
  //         `/galaxies/${galaxy._id}/remove/${subscriberId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${player.token}`,
  //           },
  //         },
  //       );
  //       setSubscribers(
  //         subscribers.filter((subscriber) => subscriber._id !== subscriberId),
  //       );
  //     } catch (error) {
  //       console.error('Error removing subscriber:', error);
  //     }
  //   }
  // };

  useEffect(() => {
    let mounted = true;
    const fetchSubscribers = async () => {
      try {
        const response = await axiosConn.get(
          `/galaxies/${galaxy._id}/members`,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        if (!mounted) return;
        setSubscribers(response.data.members);

        // Calculate the sum of jgkToken after the subscribers are fetched
        const jgkTokenSum = response.data.members.reduce(
          (sum, member) => sum + (Number(member.jgkTokens) || 0),
          0,
        );
        onJgkTokenSumChange(jgkTokenSum);
      } catch (error) {
        console.error('Failed to fetch subscribers: ', error);
      }
    };

    fetchSubscribers();
    return () => {
      mounted = false;
    };
  }, [galaxy, player.token, onJgkTokenSumChange]);

  useEffect(() => {
    let mounted = true;
    // console.log(socket);
    socket.emit('addPlayer', player.data.player._id);

    socket.on('getPlayers', (players) => {
      // console.log('players: ', players);
      // setGalaxyOnlinePlayers(players);
      const onlinePlayers = subscribers.filter((f) =>
        players.some((u) => u.playerId === f._id),
      );

      // Check if the online players have changed
      if (
        JSON.stringify(prevOnlinePlayersRef.current) !==
        JSON.stringify(onlinePlayers)
      ) {
        // Play sound here
      }
      if (!mounted) return;
      // Update the current and previous online players
      setGalaxyOnlinePlayers(onlinePlayers);
      prevOnlinePlayersRef.current = onlinePlayers;
    });

    return () => {
      socket.off('getPlayer');
      mounted = false;
    };
  }, [player, socket, subscribers]);

  // console.log(galaxyOnlinePlayers, subscribers);
  // console.log(window.location.pathname);
  // console.log(galaxyChatOnlinePlayers, subscribers);
  return (
    <div className="galaxyRightBar">
      <h4 className="rightBarTitle">
        Galaxy Subscribers:{' '}
        {subscribers &&
          subscribers.some(
            (subscriber) => subscriber._id === player.data.player._id,
          ) &&
          subscribers.length}
      </h4>{' '}
      {subscribers &&
        subscribers.some(
          (subscriber) => subscriber._id === player.data.player._id,
        ) && (
          <div className="rightbarFollowings">
            {subscribers
              .sort((a, b) => b.jgkTokens - a.jgkTokens)
              .map((subscriber, index) => (
                <div key={subscriber._id} className="subscriberCard">
                  <Link to={`/profile/${subscriber.playerId}`}>
                    <div className="subscriberPhotoWrapper">
                      <img
                        className="subscriberPhoto"
                        src={
                          subscriber.profilePhoto
                            ? subscriber.profilePhoto
                            : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                        }
                        alt={subscriber.playerId}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png';
                        }}
                      />
                      {galaxyOnlinePlayers &&
                        galaxyOnlinePlayers.some(
                          (player) => player.playerId === subscriber.playerId,
                        ) && (
                          <div
                            className={
                              !mobileView
                                ? 'galaxyOnlineBadge'
                                : 'galaxyOnlineBadgeMobile'
                            }
                          ></div>
                        )}
                      {galaxyChatOnlinePlayers &&
                        galaxyChatOnlinePlayers.some((player) => {
                          return player.id === subscriber._id;
                        }) && <div className="galaxyChatOnlineBadge"></div>}
                    </div>
                  </Link>
                  <div className="subscriberInfo">
                    {
                      <>
                        {isPlayerAuthorized(galaxy, player) ? (
                          <button
                            variant="contained"
                            className={classes.buttonSubscribed}
                            onClick={() => {
                              handleRemoveSubscriber(
                                galaxy,
                                player,
                                subscriber._id,
                                setSubscribers,
                                subscribers,
                              );
                            }}
                            title={`Remove ${subscriber.playerId}`}
                          >
                            {subscriber.playerId}
                          </button>
                        ) : (
                          <span className="subscriberId">
                            {subscriber.playerId}
                          </span>
                        )}
                      </>
                    }
                    <span className="subscriberTokens highlighted">
                      {Number(subscriber.jgkTokens)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
    </div>
  );
}

export default GalaxyRightBar;
