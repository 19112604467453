/* eslint-disable react/prop-types */
import React from 'react';
import './errorMessage.css';
import { useTheme } from '../../context/ThemeContext';

function ErrorMessage({ error, resetErrorBoundary }) {
  const { darkTheme } = useTheme();
  console.log(darkTheme);
  console.error(error);

  // Example: Send error details to an external monitoring service
  // sendErrorToMonitoringService(error);

  return (
    <div
      className={
        darkTheme
          ? 'errorMessageContainer darkTheme blackBg'
          : 'errorMessageContainer'
      }
    >
      <div
        className={
          darkTheme
            ? 'errorContainerDarkTheme darkTheme blackBg'
            : 'errorContainer'
        }
      >
        <h1>Dang! something went wrong.</h1>
        <p>An error occurred:</p>
        <pre>{error.message}</pre>
        <div className="error-message-buttons">
          <button className="postButtonRank" onClick={resetErrorBoundary}>
            Try Again?
          </button>
          <button
            className="postButtonRank"
            onClick={() => (window.location.href = '/')}
          >
            Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorMessage;
