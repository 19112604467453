export const LoginStart = (playerCredentials) => ({
  type: 'LOGIN_START',
});

export const LoginSuccess = (player) => ({
  type: 'LOGIN_SUCCESS',
  payload: player,
});

export const LoginFailure = () => ({
  type: 'LOGIN_FAILURE',
});

export const Follow = (id) => ({
  type: 'FOLLOW',
  payload: id,
});

export const Unfollow = (id) => ({
  type: 'UNFOLLOW',
  payload: id,
});

export const UpdatePlayer = (playerData) => ({
  type: 'UPDATE_PLAYER',
  payload: playerData,
});
