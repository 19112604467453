import { useQuery } from 'react-query';
import axiosConn from '../axiosConn';

//converted to react query from useState api call.
const getMemes = async ({ queryKey }) => {
  const [_, player, page] = queryKey;
  // console.log('getMemes', page);
  try {
    const res = await axiosConn.get(
      `/memes/getAllMemesComments${page ? `?page=${page}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      },
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const useGetMemes = (onSuccess, onError, player, page) => {
  return useQuery(['getMemes', player, page], getMemes, {
    onSuccess,
    onError,
    keepPreviousData: true, // Add this line
  });
};
