import React from 'react';
import './welcomeMessage.css'; // Adjust the path as necessary

const WelcomeMessage = ({ subscriptionStatus, playerId }) => {
  if (subscriptionStatus === 'Subscribe') {
    return (
      <div className="centerText">
        <span className="centeredSpan">
          <p>Welcome {playerId},</p>
          <p>
            <b>Step 1:</b> click the{' '}
            <b>
              <span className="color-blue white-text">SUBSCRIBE</span>
            </b>{' '}
            button to join the Galaxy.
          </p>
          <p>
            <b>Step 2:</b> Also, change your <b>profile Photo</b> (click top
            right) and profile Cover Photo. You earn JGK for doing it.
          </p>
          <p>
            <b>Step 3:</b> <b>Verify</b> your account by clicking the
            verification link (below).
          </p>
        </span>
      </div>
    );
  } else if (subscriptionStatus === 'Requested') {
    return (
      <div className="centerText">
        <span className="centeredSpan">
          {/* Duplicate the content as needed and modify here for 'Requested' status */}
          <p>{playerId}, your request to join the Galaxy has been sent.</p>
          <p>
            <b>Check back shortly...</b>
          </p>
        </span>
      </div>
    );
  } else {
    // Render nothing or some default content
    return null;
  }
};

export default WelcomeMessage;
