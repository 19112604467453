function arePropsEqual(prevProps, nextProps) {
  // If both prevProps and nextProps have an ad prop
  if (prevProps.ad && nextProps.ad) {
    // Compare the id property of ad
    return prevProps.ad._id === nextProps.ad._id;
  }

  return prevProps.label === nextProps.label;
}

export default arePropsEqual;
