// ChatBoxMiddle.jsx
import './chatBoxMiddle.css';
import React, { useEffect, useRef } from 'react';

import { ClipLoader } from 'react-spinners';
import { Cancel } from '@material-ui/icons';

const ChatBoxMiddle = ({
  isTypingDisplay,
  senderPlayerId,
  status,
  mediaBlobUrl,
  loading,
  handleMicClick,
  isRecording,
  mobileView,
  width,
  setVoiceMemo,
  clearBlobUrl,
  handleSendAudioMemo,
  fileInput,
  handleFileInput,
  error,
  setError,
  setFile,
  setText,
  file,
  setLoading,
  setIsLoading,
  setSendingFile,
  messageRef,
  chatContainerRef,
}) => {
  //   console.log('ChatBoxMiddle', handleFileInput);
  //   alert('chatBoxMiddle');
  // const hiddenInputRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [file]);

  function scrollToBottom() {
    if (chatContainerRef && chatContainerRef.current) {
      setTimeout(() => {
        if (chatContainerRef.current) {
          const scrollHeight = chatContainerRef.current.scrollHeight;
          chatContainerRef.current.scrollTop = scrollHeight;
        }
      }, 1000); // Adjust delay as needed
    }
  }

  // const handleFileChange = (e) => {
  //   handleFileInput(e);
  //   // Focus on the hidden input field to open the keyboard
  //   if (hiddenInputRef.current) {
  //     hiddenInputRef.current.focus();
  //   }
  // };

  return (
    <>
      <div ref={messageRef}>
        {status !== 'idle' && (
          <div className="voiceMemoCancelSend">
            <p>{status}</p>
            <div className="audioClear">
              <audio
                className="audio"
                src={mediaBlobUrl}
                controls
                autoPlay
                // muted={os === 'iOS' ? true : false}
                // loop
              />
              <div className="comment_circle" style={{ marginTop: '5px' }}>
                <ClipLoader size={10} color="#1876f2" loading={loading} />
              </div>
              {(width > 280 || status === 'recording') && (
                <div
                  className="mic_circle_icon hover2"
                  onClick={handleMicClick}
                >
                  <i
                    className={
                      !mobileView
                        ? isRecording
                          ? 'microphone_iconPlaying hue'
                          : 'microphone_icon'
                        : isRecording
                        ? 'microphone_iconMobilePlaying hue'
                        : 'microphone_iconMobile'
                    }
                    id="mic"
                  ></i>
                </div>
              )}
              {status !== 'recording' && (
                <>
                  <button
                    // disabled={status === 'recording' ? true : false}
                    onClick={() => {
                      setVoiceMemo(false);
                      clearBlobUrl();
                    }}
                    // disabled={isRecording}
                    className={
                      width < 500
                        ? 'voiceMemoCancelButtonSmall'
                        : 'voiceMemoCancelButton'
                    }
                  >
                    {width < 500 ? 'X' : 'Cancel'}
                  </button>
                  <button
                    // disabled={status === 'recording' ? true : false}
                    className={
                      width <= 280
                        ? 'voiceMemoSendButtonSmall'
                        : 'voiceMemoSendButton'
                    }
                    onClick={handleSendAudioMemo}
                  >
                    {width <= 280 ? 'Go' : 'Send'}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <input
        ref={fileInput}
        type="file"
        style={{ display: 'none' }}
        id="messengerFile"
        accept=".png,.jpeg,.jpg,.mp4,.mov"
        // onChange={handleFileChange}
        onChange={(e) => {
          handleFileInput(e);
        }}
      />
      {/* <input
        type="text"
        ref={hiddenInputRef}
        style={{ position: 'absolute', top: '-1000px', left: '-1000px' }}
      /> */}
      {error && (
        <div className="messengerError_error">
          <div className="error_text">{error}</div>
          <button
            className="blue_btn"
            onClick={() => {
              setError('');
              setFile(null);
              setLoading(false);
              setText('');
              // setLoading(false);
              fileInput.current.value = null;
              setSendingFile(false);
            }}
          >
            Try again
          </button>
        </div>
      )}
      {file &&
        (file.name.toLowerCase().includes('.mp4') ||
        file.name.toLowerCase().includes('.mov') ? (
          <div className="shareVidContainer">
            <video className="postVid" controls>
              <source src={URL.createObjectURL(file)} type="video/mp4" />
            </video>
            <Cancel
              disabled={loading}
              className="shareCancelVid"
              onClick={() => {
                setFile(null);
                setLoading(false);
                setIsLoading(false);
                fileInput.current.value = null;
                setSendingFile(false);
              }}
            />
          </div>
        ) : (
          <div className="shareImgContainer">
            <img className="shareImg" src={URL.createObjectURL(file)} alt="" />
            <Cancel
              disabled={loading}
              className="shareCancelImg"
              onClick={() => {
                setFile(null);
                setIsLoading(false);
                fileInput.current.value = null;
              }}
            />
          </div>
        ))}
    </>
  );
};

export default ChatBoxMiddle;
