import { useContext, memo, useEffect, useState } from 'react';
import Topbar from '../../components/topbar/Topbar';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';
import Sidebar from '../../components/sidebar/Sidebar';
import Feed from '../../components/feed/Feed';
import Rightbar from '../../components/rightbar/Rightbar';
import { useMediaQuery } from 'react-responsive';
// import { ViewPortContext } from '../../context/ViewPortContext';
import arePropsEqual from '../../arePropsEqual';
import { useParams } from 'react-router-dom';
import Post from '../../components/post/Post';
import { AuthContext } from '../../context/AuthContext';
import axiosConn from '../../axiosConn';

import './postpage.css';

export default memo(function PostPage() {
  const { player } = useContext(AuthContext);
  const { postId } = useParams(); //grab postId
  const [post, setPost] = useState(null);
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  // const { width } = useContext(ViewPortContext);
  // console.log(width);
  // console.log(postId, 'postId');

  useEffect(() => {
    const getPost = async () => {
      const res = await axiosConn.get('/posts/' + postId, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      // console.log(res.data.data.data);
      setPost(res.data.data.data);
    };
    getPost();
  }, [player.token, postId]);

  return (
    <>
      <Topbar />
      {mobileView && <TopbarNotification />}
      <div className="homeContainer">
        {!mobileView && <Sidebar />}

        {postId && post && (
          <div className="feed">
            <div className="feedWrapper">
              <Post initialPost={post} />
            </div>
          </div>
        )}
        {!mobileView && <Rightbar />}
      </div>
    </>
  );
}, arePropsEqual);
