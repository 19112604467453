import axiosConn from '../axiosConn';

export const fetchIPData = async () => {
  try {
    const response = await axiosConn.get('https://ipapi.co/json/');
    return response.data;
  } catch (error) {
    console.error('Error fetching IP data:', error);
    return null;
  }
};
