import { useQuery } from 'react-query';
import axiosConn from '../axiosConn';

//converted to react query from useState api call.
const getCurrPlayer = async ({ queryKey }) => {
  try {
    const res1 = await axiosConn.get(
      `/players/?playerId=${queryKey[1].data.player.playerId}`,
      {
        headers: {
          Authorization: `Bearer ${queryKey[1].token}`,
        },
      },
    );
    return res1.data.data.data[0];
    //   setCurrPlayer(res1.data.data.data[0]);
    //setNumFriendRequests(res1.data.data.data[0].requests.length);
    // console.log('currPlayer: ', res1.data.data.data[0]);
  } catch (err) {
    console.log(err);
  }
};

export const useGetCurrPlayer = (onSuccess, onError, player) => {
  //   console.log(player);
  return useQuery(['getCurrPlayer', player], getCurrPlayer, {
    onSuccess,
    onError,
  });
};
