import { useState, useEffect, useRef } from 'react';
import './btcTracker.css';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '../../context/ThemeContext';
import { addShakeAnimation } from '../../utils/animationUtils';

export default function BTCTracker() {
  const [source] = useState('CoinDesk BPI');
  const [showWidget, setShowWidget] = useState(false);
  const [timer, setTimer] = useState(null);
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const imgRef = useRef(null);
  const { darkTheme } = useTheme();

  useEffect(() => {
    if (showWidget) {
      const script = document.createElement('script');
      script.src =
        'https://widgets.coingecko.com/gecko-coin-price-chart-widget.js';
      script.async = true;
      document.body.appendChild(script);

      const timerId = setTimeout(() => {
        setShowWidget(false);
      }, 10000);
      setTimer(timerId);

      return () => {
        document.body.removeChild(script);
        clearTimeout(timerId);
      };
    }
  }, [showWidget]);

  useEffect(() => {
    const cleanup = addShakeAnimation(imgRef);
    return cleanup;
  }, []);

  const handleClick = () => {
    console.log('Image clicked');
    setShowWidget(true);
  };

  return (
    <>
      {showWidget ? (
        <div className={!mobileView ? 'btc_priceVal' : 'btc_priceValMobile'}>
          <gecko-coin-price-chart-widget
            locale="en"
            outlined="true"
            initial-currency="usd"
            dark-mode={darkTheme ? true : false}
          ></gecko-coin-price-chart-widget>
        </div>
      ) : (
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
          <img
            ref={imgRef}
            className="btc_ImgFeed"
            src="https://bitcoin.org/img/icons/opengraph.png?1660986466"
            alt="Chirbichaw"
            title="Chirbichaw"
            loading="eager"
          />
        </div>
      )}
    </>
  );
}
