import axiosConn from '../../axiosConn';
import { useMediaQuery } from 'react-responsive';

import { useEffect, useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';

import './chatOnline.css';

export default function ChatOnline({
  onlinePlayers,
  currentId,
  setCurrentChat,
  setIsLoading,
}) {
  const [friends, setFriends] = useState([]);
  const [onlineFriends, setOnlineFriends] = useState([]);
  //const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const { player } = useContext(AuthContext);
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  //   console.log(currentId);
  //   console.log(onlinePlayers);
  //   console.log(player);
  useEffect(() => {
    let mounted = true;
    const getFriends = async () => {
      const res = await axiosConn.get('/players/friends/' + currentId, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      //console.log(res.data);
      if (!mounted) return;
      setFriends(res.data);
    };

    getFriends();
    return () => {
      mounted = false;
    };
  }, [player, currentId]);

  useEffect(() => {
    //console.log(onlinePlayers);
    setOnlineFriends(friends.filter((f) => onlinePlayers.includes(f._id)));
    //console.log(friends);
    //console.log(onlineFriends);
  }, [friends, onlinePlayers]);

  // const handleClick = async (player1) => {
  //   try {
  //     // console.log(player1);
  //     // console.log(currentId);
  //     const res = await axiosConn.get(
  //       `/conversations/find/${currentId}/${player1._id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${player.token}`,
  //         },
  //       },
  //     );

  //     if (!res.data) {
  //       //console.log('no conversation yet!');
  //       //if no conversation between currentId & player1 then
  //       //create a conversation.
  //       //setCurrentChat to new conversation.
  //       const res2 = await axiosConn.post(
  //         `/conversations/`,
  //         {
  //           senderId: currentId,
  //           receiverId: player1._id,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${player.token}`,
  //           },
  //         },
  //       );
  //       console.log(res2.data);
  //       setCurrentChat(res2.data);
  //     } else {
  //       console.log(res.data);
  //       setCurrentChat(res.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleClick2 = async (player1) => {
    try {
      // console.log(player1);
      // console.log(currentId);
      setIsLoading(true);
      await axiosConn
        .get(`/conversations/find/${currentId}/${player1._id}`, {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        })
        .then(async (res) => {
          //console.log(res.data);
          if (typeof res.data === 'undefined' || res.data === null) {
            //console.log('no conversation yet!');
            //if no conversation between currentId & player1 then
            //create a conversation.
            //setCurrentChat to new conversation.
            const res2 = await axiosConn.post(
              `/conversations/`,
              {
                senderId: currentId,
                receiverId: player1._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${player.token}`,
                },
              },
            );
            //console.log(res2.data);
            setCurrentChat(res2.data);
            setIsLoading(false);
          } else {
            // console.log(res.data);
            setCurrentChat(res.data);
            setIsLoading(false);
          }
        });
      //window.location.reload();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="chatOnline">
      {onlineFriends.map((o, i) => (
        <div
          key={i}
          className={
            !mobileView ? 'chatOnlineFriend' : 'chatOnlineFriendMobile'
          }
          onClick={() => handleClick2(o)}
        >
          <div className="chatOnlineImgContainer">
            <img
              className={!mobileView ? 'chatOnlineImg' : 'chatOnlineImgMobile'}
              src={
                o?.profilePhoto
                  ? o.profilePhoto
                  : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
              }
              alt=""
            />
            <div
              className={
                !mobileView ? 'chatOnlineBadge' : 'chatOnlineBadgeMobile'
              }
            ></div>
          </div>
          <span
            className={!mobileView ? 'chatOnlineName' : 'chatOnlineNameMobile'}
          >
            {o?.playerId}
          </span>
        </div>
      ))}
    </div>
  );
}
