// preciseFormat.js
const preciseFormat = (date) => {
  const now = new Date();
  const past = new Date(date);
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;
  const msPerMonth = msPerDay * 30; // Approximation
  const msPerYear = msPerDay * 365;

  const elapsed = now - past;

  if (elapsed < msPerMinute) {
    return 'just now';
  } else if (elapsed < msPerHour) {
    const minutes = Math.round(elapsed / msPerMinute);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerDay) {
    const hours = Math.round(elapsed / msPerHour);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerWeek) {
    const days = Math.round(elapsed / msPerDay);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerMonth) {
    const weeks = Math.round(elapsed / msPerWeek);
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
  } else if (elapsed < msPerYear) {
    const months = Math.round(elapsed / msPerMonth);
    return `${months} month${months > 1 ? 's' : ''} ago`;
  } else {
    // Calculate the difference in years and the remainder in months
    const years = Math.floor(elapsed / msPerYear);
    const monthsAfterYear = Math.floor((elapsed % msPerYear) / msPerMonth);
    if (monthsAfterYear > 0) {
      return `${years} year${
        years > 1 ? 's' : ''
      } and ${monthsAfterYear} month${monthsAfterYear > 1 ? 's' : ''} ago`;
    } else {
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
  }
};

export default preciseFormat;
