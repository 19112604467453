import axiosConn from '../axiosConn';

export const saveLocationData = async (
  latitude,
  longitude,
  accuracy,
  timestamp,
  token,
) => {
  try {
    if (latitude && longitude) {
      await axiosConn.put(
        '/players/lastLocation',
        {
          latitude,
          longitude,
          accuracy,
          timestamp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    }
    // console.log('saveLocationDta Success');
  } catch (err) {
    console.error('Error saving location:', err.message);
    throw err;
  }
};
