import { useEffect, useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// import Topbar from '../../components/topbar/Topbar';
// import Sidebar from '../../components/sidebar/Sidebar';
// import Feed from '../../components/feed/Feed';
// import Rightbar from '../../components/rightbar/Rightbar';
import ActivateForm from './ActivateForm';
import axiosConn from '../../axiosConn';
import { AuthContext } from '../../context/AuthContext';

import './activate.css';
export default function Activate() {
  const { player } = useContext(AuthContext);
  const history = useHistory();

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const { token } = useParams();
  useEffect(() => {
    // console.log('calling activateAccount');
    activateAccount();
  }, []);

  const activateAccount = async () => {
    try {
      //const tokenOg = token.replaceAll('JGJGEZ', '.');
      //alert(token, player.data.player._id);

      setLoading(true);
      // Check if the player is already verified
      if (player.data.player.verified) {
        setSuccess('Account is already verified.');
        setLoading(false);
        return;
      }

      const { data } = await axiosConn.post(
        '/players/activateAccount',
        { token, id: player.data.player._id },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      // console.log(data);
      setSuccess(data.message);
      // alert(data?.message);
      //Cookies.set('player', JSON.stringify({ ...player, verified: true }));
      player.data.player.verified = true;

      localStorage.setItem('player', JSON.stringify(player));
      // history.push('/login');

      // dispatch({
      //   type: 'VERIFY',
      //   payload: true,
      // });

      // setTimeout(() => {
      //   //navigate('/');
      //   window.open('/login');
      // }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      // alert(error.response.data.message);
      console.log(error);
      // setTimeout(() => {
      //   //navigate('/');
      //   console.log('already activated, redirect to /');
      //   window.open('/');
      // }, 1000);
    }
  };

  return (
    <>
      <div className="home">
        {success && (
          <ActivateForm
            type="success"
            header="Account verification succeeded."
            text={success}
            loading={loading}
          />
        )}
        {error && (
          <ActivateForm
            type="error"
            header="Account verification failed."
            text={error}
            loading={loading}
          />
        )}
      </div>
    </>
  );
}
