//import PropagateLoader from 'react-spinners/PropagateLoader';
import { Link } from 'react-router-dom';

export default function ActivateForm({ type, header, text, loading }) {
  return (
    <div className="blur">
      <div className="popup">
        <div
          className={`popup_header ${
            type === 'success' ? 'success_text' : 'error_text'
          }`}
        >
          {header}
        </div>
        <div className="popup_message">{text}</div>
        {/* <PropagateLoader color="#8a2be2" size={20} loading={loading} /> */}
        <div className="registerCenter">
          <Link to="/" style={{ textDecoration: 'none' }}>
            <button className="loginRegisterButton">Log into Account</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
