import React, { useEffect, useState, useRef, useContext } from 'react';
import { socket } from '../../context/socket';
import Message from '../message/Message';
import ChatBoxBottom from '../chatBoxBottom/ChatBoxBottom';
import { useConfettiText } from '../../hooks/useConfettiText';
import useMobileView from '../../hooks/useMobileView';
import useClickOutside from '../../clickOutside';
import { handleEmoji } from '../../utils/handleEmojis';
import { useIsTyping } from 'use-is-typing';
import { useReactMediaRecorder } from 'react-media-recorder';
import { AuthContext } from '../../context/AuthContext';
import { uploadProgress } from '../../utils/uploadProgress';
import axios from 'axios';
import axiosConn from '../../axiosConn';
import ChatBoxMiddle from '../../components/chatBoxMiddle/ChatBoxMiddle';
import { ViewPortContext } from '../../context/ViewPortContext';
import handleFileInput from '../../utils/handleFileInput';
import { ClipLoader } from 'react-spinners';
import { debounce } from '../../context/ViewPortContext';
import WelcomeMessage from '../../components/welcomeMessage/WelcomeMessage';
import AdRotator from '../adRotator/AdRotator';
import { v4 as uuidv4 } from 'uuid';
import { MAX_VIDEO_SIZE, MAX_IMAGE_SIZE } from '../../config/constants';

import {
  // PermMedia,
  // Label,
  // Room,
  // EmojiEmotions,
  Cancel,
} from '@material-ui/icons';
import { useQuery } from 'react-query';
import { INCOMING_MESSAGE_BL_URL } from '../../utils/constants';
import './galaxyChatBox.css';

const MESSAGE_PARTICLE_COUNT = 100;
const maxVideoSize = MAX_VIDEO_SIZE; // 90mb
const maxImageSize = MAX_IMAGE_SIZE; // 20mb

const GalaxyChatBox = ({
  galaxyId,
  senderId,
  senderProfilePhoto,
  senderPlayerId,
  galaxyMembers,
  subscriptionStatus = 'Subscribe',
  setProgress,
  galaxyChatOnlinePlayers,
  isPlayerInRole,
}) => {
  // console.log(
  //   'galaxyChatOnlinePlayers',
  //   galaxyChatOnlinePlayers,
  //   'galaxyMembers',
  //   galaxyMembers,
  // );
  const { player } = useContext(AuthContext);
  const { width } = useContext(ViewPortContext);

  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const from = 'galaxyChat';
  const [voiceMemo, setVoiceMemo] = useState(false);
  const [picker, setPicker] = useState(false);
  const [isTyping, register] = useIsTyping({ timeout: 300 });
  const [isTypingDisplay, SetIsTypingDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendingFile, setSendingFile] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ audio: true });
  // const [progress, setProgress] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [error, setError] = useState('');
  const [messageCount, setMessageCount] = useState(10);
  const [offlineMembers, setOfflineMembers] = useState([]);

  const scrollRef = useRef(null);
  const mobileView = useMobileView();
  const menu = useRef(null);
  const fileInput = useRef(null);
  const [cursorPosition, setCursorPosition] = useState();
  const [file, setFile] = useState(null);
  const textRef = useRef(null);
  const messageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);
  const messageSoundRef = useRef();

  const showMore = () => {
    setMessageCount((prev) => prev + 10);
  };

  const handleClickOutside = () => {
    setPicker((prev) => !prev);
  };

  useClickOutside(menu, () => handleClickOutside());

  const [showConfettiText, ConfettiTextComponent] = useConfettiText(
    !mobileView ? (voiceMemo ? messageRef : textRef) : messageRef,
    mobileView,
    '+1 JGK So Fun!',
    MESSAGE_PARTICLE_COUNT,
    'messenger',
    voiceMemo,
  );

  useEffect(() => {
    if (cursorPosition && textRef !== null)
      textRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  useEffect(() => {
    const newOfflineMembers = galaxyMembers.filter(
      (memberId) =>
        !galaxyChatOnlinePlayers.some(
          (chatPlayer) => chatPlayer.id === memberId,
        ),
    );
    setOfflineMembers(newOfflineMembers);
  }, [galaxyChatOnlinePlayers, galaxyMembers]);

  const getGalaxyMessages = async () => {
    try {
      const res = await axiosConn.get('/galaxyMessages/' + galaxyId, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      return res.data.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const {
    isLoading: isGalaxyLoading,
    data: galaxyData,
    status: galaxyStatus,
  } = useQuery(['getGalaxyMessages', galaxyId], getGalaxyMessages);

  // console.log('galaxyData', galaxyData);

  useEffect(() => {
    // console.log('galaxyData', galaxyData);
    let mounted = true;

    if (
      mounted &&
      galaxyStatus === 'success' &&
      subscriptionStatus === 'Subscribed'
    ) {
      setMessages(galaxyData);
      // setIsHistoryLoaded(true);
    }

    return () => {
      mounted = false;
    };
  }, [galaxyData, galaxyStatus, subscriptionStatus]);

  useEffect(() => {
    if (!messageSoundRef.current) {
      messageSoundRef.current = new Audio(INCOMING_MESSAGE_BL_URL);
    }

    if (!galaxyMembers.includes(senderId)) {
      // console.log('galaxyMembers', galaxyMembers);
      return;
    }

    socket.emit('addPlayerToGalaxyChat', { galaxyId, playerId: senderId });

    // const handleNewMessage = (data) => {
    //   // console.log('data', data);
    //   if (!galaxyMembers.includes(data.senderId)) {
    //     return;
    //   }
    //   setMessages((prevMessages) => [...prevMessages, data]);
    // };
    const handleNewMessage = (data) => {
      // console.log('handleNewMessage, data', data);
      if (!galaxyMembers.includes(data.senderId)) {
        // console.log('not a member of this galaxy', galaxyMembers);
        return;
      }
      // console.log('galaxyMembers', galaxyMembers);
      setMessages((prevMessages) => [...prevMessages, data]);

      // Check if the message is from someone else
      if (data.senderId !== senderId) {
        // console.log('play sound');
        const playPromise = messageSoundRef.current.play();

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Automatic playback started!
              // Additional actions if needed
            })
            .catch((error) => {
              // Auto-play was prevented
              // Additional actions if needed
              console.log(error);
            });
        }
      }
    };

    socket.on('getGalaxyChatMessage', handleNewMessage);

    return () => {
      socket.emit('removePlayerFromGalaxyChat', {
        galaxyId,
        playerId: senderId,
      });
      socket.off('getGalaxyChatMessage', handleNewMessage);
    };
  }, [galaxyId, senderId, galaxyMembers]);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      let isLoadMoreMutation = false;

      // Check if the mutation is due to loading more messages
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          for (let node of mutation.addedNodes) {
            if (
              node.classList &&
              node.classList.contains('load-more-messages')
            ) {
              // If the mutation is due to loading more messages, set the flag and break the loop
              isLoadMoreMutation = true;
              setIsViewMoreClicked(true);
              break;
            }
          }
        }

        if (isLoadMoreMutation) {
          break;
        }
      }

      // If the mutation is not due to loading more messages and the "View more messages" button hasn't been clicked, scroll to the bottom
      if (!isLoadMoreMutation && !isViewMoreClicked) {
        setTimeout(() => {
          if (chatContainerRef.current) {
            const scrollHeight = chatContainerRef.current.scrollHeight;
            chatContainerRef.current.scrollTop = scrollHeight;
          }
        }, 1000);
      }
    });

    if (chatContainerRef.current) {
      observer.observe(chatContainerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => observer.disconnect();
  }, [messages, isViewMoreClicked]); // Add isViewMoreClicked as a dependency
  // const sendMessage = () => {
  //   if (!galaxyMembers.includes(senderId)) {
  //     return;
  //   }

  //   socket.emit('sendGalaxyChatMessage', {
  //     galaxyId,
  //     senderId,
  //     text: messageText,
  //     img: '',
  //     senderProfilePhoto,
  //     senderPlayerId,
  //     createdAt: new Date().toISOString(),
  //   });
  //   setMessageText('');
  // };

  useEffect(() => {
    const container = document.querySelector('.chat-container');
    container.scrollTop = container.scrollHeight;
  }, [messages]);

  useEffect(() => {
    if (file) {
      const scrollHeight = chatContainerRef.current.scrollHeight;
      chatContainerRef.current.scrollTop = scrollHeight;
    }
  }, [file]);

  const handleMicClick = () => {
    // console.log('Microphone click', isRecording);
    setVoiceMemo(true);
    // isRecording = !isRecording;

    if (!isRecording) {
      // console.log('starting record.');
      startRecording();
    } else {
      // console.log('stopping record.');
      stopRecording();
    }
    setIsRecording((prev) => !prev);
  };

  const handleSendAudioMemo = async (e) => {
    if (!galaxyMembers.includes(senderId)) {
      return;
    }

    let SecurePostImgQ_AutoUrl = '';

    const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const fileName = Date.now() + '.mp3';
    const audioFile = new File([audioBlob], fileName, {
      type: 'audio/mpeg-3',
    });
    // then follow steps of handleSubmit.
    let data = new FormData(); // preparing to send to the server

    data.append('name', fileName);
    data.append('file', audioFile);
    data.append('upload_preset', 'cubejg');
    data.append('cloud_name', 'joegalaxy');

    const config = uploadProgress(setProgress, setUploadPercentage);

    try {
      const url = 'https://api.cloudinary.com/v1_1/joegalaxy/video/upload';
      setLoading(true);

      const response = await axios.post(url, data, config);
      SecurePostImgQ_AutoUrl = response.data.secure_url.replace(
        '/upload/',
        '/upload/q_auto/fl_progressive:steep/',
      );

      setProgress(100);
      setUploadPercentage(100);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMessageText('');
      setError(err.message);
    }

    const message = {
      galaxyId: galaxyId,
      senderId: player.data.player._id,
      text: messageText,
      img: SecurePostImgQ_AutoUrl,
      senderProfilePhoto: player.data.player.profilePhoto,
      senderPlayerId: player.data.player.playerId,
      createdAt: new Date(),
      tempId: uuidv4(),
    };

    socket.emit('sendGalaxyChatMessage', message);

    try {
      setLoading(true);

      await axiosConn.post(
        '/galaxyMessages',
        { message, offlineMembers },
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      setMessageText('');
      setFile(null);
      fileInput.current.value = null;
      showConfettiText();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setMessageText('');
      setError(err.message);
    }

    setVoiceMemo(false);
    clearBlobUrl();
  };

  const handleSubmit = async (e) => {
    // console.log('handleSubmit');

    if (
      !galaxyMembers.includes(senderId) &&
      subscriptionStatus !== 'Subscribed'
    ) {
      console.log('not a member of this galaxy', subscriptionStatus);
      return;
    }
    let SecurePostImgQ_AutoUrl = '';

    if (
      (e.key === 'Enter' || e.type === 'click') &&
      (messageText.trim() !== '' || fileInput.current.value !== '')
    ) {
      if (file) {
        // console.log('file', file);
        if (
          ![
            'image/jpeg',
            'image/png',
            'image/webp',
            'image/gif',
            'video/mp4',
            'video/quicktime',
          ].includes(file.type)
        ) {
          setError(`${file.name} format is not supported.`);
          return;
        }

        const isVideo =
          file.name.toLowerCase().includes('.mp4') ||
          file.name.toLowerCase().includes('.mov');
        const maxSize = isVideo ? MAX_VIDEO_SIZE : MAX_IMAGE_SIZE;

        if (file.size > maxSize) {
          setError(
            `${file.name} is too large, max ${
              isVideo ? `${MAX_VIDEO_SIZE}mb` : `${MAX_IMAGE_SIZE}mb`
            } allowed.`,
          );
          return;
        }

        const payload = new FormData();
        const fileName = Date.now() + file.name;
        payload.append('name', fileName);
        payload.append('file', file);
        payload.append('upload_preset', 'cubejg');
        payload.append('cloud_name', 'joegalaxy');

        const config = uploadProgress(setProgress, setUploadPercentage);
        const url = `https://api.cloudinary.com/v1_1/joegalaxy/${
          isVideo ? 'video' : 'image'
        }/upload`;

        try {
          setLoading(true);

          const { data } = await axios.post(url, payload, config);
          SecurePostImgQ_AutoUrl = data.secure_url.replace(
            '/upload/',
            '/upload/q_auto/fl_progressive:steep/',
          );

          setLoading(false);
        } catch (err) {
          setLoading(false);
          setMessageText('');
          setError(err.message);
          return;
        }
      }

      const message = {
        galaxyId: galaxyId,
        senderId: player.data.player._id,
        text: messageText.trim(),
        img: SecurePostImgQ_AutoUrl,
        senderProfilePhoto: player.data.player.profilePhoto,
        senderPlayerId: player.data.player.playerId,
        createdAt: new Date(),
        tempId: uuidv4(),
      };

      // console.log('message', message);
      try {
        socket.emit('sendGalaxyChatMessage', message);
      } catch (err) {
        console.error(err);
        return;
      }

      try {
        setLoading(true);
        // console.log('offlineMembers', offlineMembers);
        await axiosConn.post(
          '/galaxyMessages',
          { message, offlineMembers },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );

        setLoading(false);

        setMessageText('');
        setFile(null);
        if (fileInput.current) {
          fileInput.current.value = '';
        }
        setSendingFile(false);
        showConfettiText();
      } catch (err) {
        console.log('catch block', err);
        setLoading(false);
        setMessageText('');
        setError(err.message);
        setSendingFile(false);
      }
    }
  };

  const debouncedHandleSubmit = debounce(handleSubmit, 700);

  const handleDeleteMessage = (messageId) => {
    setMessages(
      messages.filter((m) => {
        return m._id !== messageId && m.tempId !== messageId;
      }),
    );
  };
  // console.log('messages', messages);
  return (
    <>
      {(subscriptionStatus === 'Subscribe' ||
        subscriptionStatus === 'Requested') && (
        <>
          <div className="centered-column-galaxyChatBox">
            <WelcomeMessage
              subscriptionStatus={subscriptionStatus}
              playerId={player.data.player.playerId}
            />
            <AdRotator />
          </div>
        </>
      )}
      <div className="chat-container" ref={chatContainerRef}>
        {subscriptionStatus === 'Subscribed' &&
          messageCount < messages?.length && (
            <div
              className={
                !mobileView
                  ? 'viewMessages load-more-messages'
                  : 'viewMessagesMobile load-more-messages'
              }
              onClick={() => showMore()}
            >
              View {messages?.length - messageCount} more messages
            </div>
          )}
        {Array.isArray(messages) &&
          messages
            ?.slice(Math.max(messages?.length - messageCount, 0))
            .map((message) => {
              const isOwnMessage = message.senderId === senderId;

              return (
                (galaxyMembers.includes(senderId) ||
                  subscriptionStatus === 'Subscribed') && (
                  <div
                    key={message._id}
                    className={isOwnMessage ? 'message-own' : 'message-other'}
                  >
                    <Message
                      message={message}
                      own={isOwnMessage}
                      senderProfilePhoto={message.senderProfilePhoto}
                      from="galaxyChat"
                      onDeleteMessage={handleDeleteMessage}
                      galaxyId={galaxyId}
                      isPlayerInRole={isPlayerInRole}
                    />
                  </div>
                )
              );
            })}
        <div className="bottomMessages" ref={scrollRef} />
        <div className="chat-input">
          <ConfettiTextComponent />
          {/* {isTypingDisplay && senderPlayerId && (
            <span>{senderPlayerId} is typing...</span>
          )} */}
        </div>
        <ChatBoxMiddle
          isTypingDisplay={isTypingDisplay}
          senderPlayerId={senderPlayerId}
          status={status}
          mediaBlobUrl={mediaBlobUrl}
          loading={loading}
          handleMicClick={handleMicClick}
          isRecording={isRecording}
          mobileView={mobileView}
          width={width}
          setVoiceMemo={setVoiceMemo}
          clearBlobUrl={clearBlobUrl}
          handleSendAudioMemo={handleSendAudioMemo}
          fileInput={fileInput}
          handleFileInput={(e) =>
            handleFileInput(e, setError, setFile, maxVideoSize, maxImageSize)
          }
          error={error}
          setError={setError}
          setFile={setFile}
          setText={setMessageText}
          file={file}
          setLoading={setLoading}
          setIsLoading={setIsLoading}
          setSendingFile={setSendingFile}
          messageRef={messageRef}
          chatContainerRef={chatContainerRef}
        />
      </div>

      <div className="chatBoxBottomContainer">
        {!voiceMemo && (
          <ChatBoxBottom
            voiceMemo={voiceMemo}
            // mobileView={mobileView}
            picker={picker}
            menu={menu}
            handleEmoji={(e, { emoji }) =>
              handleEmoji(
                e,
                { emoji },
                messageText,
                setMessageText,
                textRef,
                setCursorPosition,
              )
            }
            textRef={textRef}
            register={register}
            text={messageText}
            setText={setMessageText}
            handleSubmit={debouncedHandleSubmit}
            loading={loading}
            sendingFile={sendingFile}
            handleMicClick={handleMicClick}
            isRecording={isRecording}
            setPicker={setPicker}
            fileInput={fileInput}
            setSendingFile={setSendingFile}
            // width={width}
            handleSendAudioMemo={handleSendAudioMemo}
          />
        )}
      </div>
    </>
  );
};

export default GalaxyChatBox;
