// src/utils/useStyles.js
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  buttonSubscribed: {
    backgroundColor: '#8a2be2',
    color: 'white',
  },
  buttonDefault: {
    backgroundColor: '#3f51b5',
    color: 'white',
  },
  tooltip: {
    fontSize: theme.typography.pxToRem(18),
  },
}));

export default useStyles;
