// import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode';

const checkJWTExpiration = () => {
  const player = JSON.parse(localStorage.getItem('player'));
  //   console.log(player.token);
  if (player === typeof undefined || player === null) {
    //no player login session so return true for app.js routing.
    return true;
  }
  let decodedToken = jwt_decode(player?.token);
  //   console.log('Decoded Token', decodedToken);
  let currentDate = new Date();

  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    // console.log('Token expired.');
    return false;
  } else {
    // console.log('Valid token');
    return true;
  }
};
export default checkJWTExpiration;
