import { useContext, useState, useRef } from 'react';
import './createAd.css';
import axiosConn from '../../axiosConn';
import { useConfettiText } from '../../hooks/useConfettiText';
import { useMediaQuery } from 'react-responsive';

import { AuthContext } from '../../context/AuthContext';
import { CircularProgress } from '@material-ui/core';
import { useTheme } from '../../context/ThemeContext';

// import IconButton from 'material-ui/lib/icon-button';
//import { Icon_Visa, Icon_MasterCard } from 'material-ui-credit-card-icons';
const AD_PARTICLE_COUNT = 1000;

export default function CreateAd() {
  const { player } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  //const [newAd, setNewAd] = useState();
  const inputRef = useRef(null);
  // const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const url = useRef();
  const img = useRef();
  const title = useRef();
  const price = 9.99;
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const { darkTheme } = useTheme();

  const [showConfettiText, ConfettiTextComponent] = useConfettiText(
    inputRef, // inputRef
    mobileView, // mobileView
    '+10 JGK Yay!', // customText
    AD_PARTICLE_COUNT, // particleCount
    'CreateAd', // parent
    false, // isVoiceMemo
  );

  //form
  //url, img url, title, playerId, price.
  //upload/pay button
  //axios to create a new ad,
  //stripe client cc processing.
  //if approved set isApproved.
  const submitHandler = async (e) => {
    e.preventDefault();
    //console.log(file);
    setIsLoading(true);

    const Ad = {
      url: url.current.value,
      img: img.current.value,
      title: title.current.value,
      playerId: player.data.player.playerId,
      price,
    };

    try {
      const res = await axiosConn.post('/ads', Ad, {
        headers: {
          Authorization: `Bearer ${player.token}`,
        },
      });
      //setNewAd(res.data.data.data);
      //get checkout session from backend endpoint/api. param will be NewAd.id.
      //console.log(res.data.data.data);
      const res2 = await axiosConn.get(
        `/adBookings/checkout-session/${res.data.data.data._id}`,
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      //console.log(session);
      // possibly have endpoint redirect to session.url. to charge credit card on stripe's secure site.
      //console.log(res2.data.session.url);
      showConfettiText(); // Show the confetti text when the ad is successfully created
      window.location.href = res2.data.session.url;
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="createAdFeed">
      <div className="createAdFeedWrapper">
        <div className={darkTheme ? 'createAdShareDarkTheme' : 'createAdShare'}>
          <div className="createAdShareWrapper">
            <div className="shareTop">
              <img
                className="shareProfileImgCreateAd"
                src={
                  player.data.player.profilePhoto
                    ? player.data.player.profilePhoto
                    : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                }
                alt=""
              />
              <span
                className={
                  darkTheme ? 'createAdDesc dark-theme blackBg' : 'createAdDesc'
                }
              >
                <div>Advertise with JOE GALAXY, US$9.99/month.</div>
                <div className="stripeImg">
                  <img
                    className="stripeImg"
                    src={
                      'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1661538167/Images/Powered_By_Stripe_nmufch.png'
                    }
                    alt=""
                  />
                </div>
              </span>
              <form
                className={
                  darkTheme ? 'createAdBox dark-theme blackBg' : 'createAdBox'
                }
                onSubmit={submitHandler}
              >
                <div>
                  <input
                    placeholder="Ad Url"
                    type="text"
                    required
                    className="createAdInput"
                    ref={url}
                  />
                </div>
                <div>
                  <input
                    placeholder="Ad Img Url"
                    type="text"
                    required
                    className="createAdInput"
                    ref={img}
                  />
                </div>
                <div>
                  <input
                    placeholder="Ad Title"
                    type="text"
                    required
                    className="createAdInput"
                    ref={title}
                  />
                </div>
                <div>
                  <ConfettiTextComponent />
                  <button
                    ref={inputRef}
                    className="payButton"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : (
                      'Checkout'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
