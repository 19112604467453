import './topbarplayermapnotification.css';
import {
  Search,
  Person,
  Chat,
  // Notifications
} from '@material-ui/icons';
// import { ArrowDown } from '../../svg/arrowDown';

// import LogoutIcon from '@mui/icons-material/Logout';
// import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
// import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
// import QueuePlayNextOutlinedIcon from '@mui/icons-material/QueuePlayNextOutlined';
import { Tooltip } from '@mui/material';
import validator from 'validator';
import { Link } from 'react-router-dom';
import { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ViewPortContext, useViewport } from '../../context/ViewPortContext';
import PlayerSearchResults from '../playerSearchResults/PlayerSearchResults';
import axiosConn from '../../axiosConn';
import useClickOutside from '../../clickOutside';
import { useMediaQuery } from 'react-responsive';
// import PlayerMenu from './playerMenu';
import { SocketContext } from '../../context/socket';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import { useGetConversations } from '../../hooks/useGetConversations';
export default function TopbarPlayerMap({
  radius,
  setRadius,
  unit,
  setUnit,
  getPosition,
}) {
  const { player } = useContext(AuthContext);
  const { width } = useContext(ViewPortContext);

  // console.log(width);
  //const [player1, setPlayer1] = useState(player);
  const [numFollowers] = useState(player.data.player.followers.length);
  const [numFriendRequests, setNumFriendRequests] = useState(
    player.data.player.requests.length,
  );
  const [friendRequestPlayers, setFriendRequestPlayers] = useState([]);
  const [conversations, setConversations] = useState([]);
  const searchInput = useRef();
  const [isSearching, setIsSearching] = useState(false);

  const [searchedPlayers, setSearchedPlayers] = useState([]);
  // const [currPlayer, setCurrPlayer] = useState({});

  const [playerSearchResults, setPlayerSearchResults] = useState(false);
  const [showPlayerMenu, setShowPlayerMenu] = useState(false);
  const [showFriendRequestPlayersMenu, setShowFriendRequestPlayersMenu] =
    useState(false);
  const socket2 = useContext(SocketContext);
  const [totalUnReadMessages, setTotalUnReadMessages] = useState(0);
  const menu = useRef(null);
  const friendRequestPlayersMenu = useRef(null);
  const playermenu = useRef(null);

  // const [showUnReadMessages, setShowUnReadMessages] = useState(true);

  // const Logoff = () => {
  //   socket2.close();
  //   localStorage.clear();
  //   window.location.href = '/login';
  // };

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  //console.log(parent.parent);

  const handleClickOutside = () => {
    setPlayerSearchResults(false);
    searchInput.current.value = '';
  };

  useClickOutside(menu, () => handleClickOutside());

  useClickOutside(friendRequestPlayersMenu, () => {
    setShowFriendRequestPlayersMenu(false);
  });

  useClickOutside(playermenu, () => {
    setShowPlayerMenu(false);
  });

  //1. Get currentPlayer. currentPlayer.requests[]
  // useEffect(() => {
  //   const getCurrPlayer = async () => {
  //     try {
  //       const res1 = await axiosConn.get(
  //         `/players/?playerId=${player.data.player.playerId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${player.token}`,
  //           },
  //         },
  //       );
  //       setCurrPlayer(res1.data.data.data[0]);
  //       //setNumFriendRequests(res1.data.data.data[0].requests.length);
  //       // console.log('currPlayer: ', res1.data.data.data[0]);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   getCurrPlayer();
  // }, [player.data.player._id, player.token, player.data.player.playerId]);

  useEffect(() => {
    // get friendRequestPlayers
    const getFriendRequestPlayers = async () => {
      try {
        const res1 = await axiosConn.get(
          `/players/friendRequests/${player.data.player._id}`,
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
        // console.log('friendRequestPlayers: ', res1.data);
        setFriendRequestPlayers(res1.data);
        setNumFriendRequests(res1.data.length);
      } catch (err) {
        console.log(err);
      }
    };
    getFriendRequestPlayers();
  }, [player.data.player._id, player.token]);

  // const handleSearch = async (searchInput) => {
  //   //Player search
  //   setPlayerSearchResults(true);

  //   try {
  //     if (searchInput === '') {
  //       //console.log('empty');
  //       setIsSearching(false);
  //       setSearchedPlayers([]);
  //     } else if (validator.isAlphanumeric(searchInput)) {
  //       //console.log(searchInput);
  //       setIsSearching(true);
  //       const res = await axiosConn.get(`/players/regex/${searchInput}`, {
  //         headers: {
  //           Authorization: `Bearer ${player.token}`,
  //         },
  //       });
  //       //console.log(res.data.data.data);
  //       setSearchedPlayers(res.data.data.data);
  //       setIsSearching(false);
  //     }
  //   } catch (err) {
  //     setIsSearching(false);
  //     setSearchedPlayers([]);
  //     console.log(err);
  //   }
  // };

  const onSuccess = (data) => {
    // console.log('data: ', data);
  };

  const onError = (err) => {
    // console.log('err: ', err);
  };

  const { data, status } = useGetConversations(onSuccess, onError, player);
  // console.log(data);

  useEffect(() => {
    let mounted = true;
    //set conversations
    if (status === 'success') {
      if (!mounted) return;
      setConversations(data);
    }
    return () => {
      mounted = false;
    };
  }, [status, data]);

  // useEffect(() => {
  //   conversations?.map((conversation) => {
  //     //console.log(conversation.unReadMessages);
  //     setTotalUnReadMessages((prev) => prev + conversation.unReadMessages);
  //   });

  //   // setShowUnReadMessages(totalUnReadMessages > 0);
  //   return () => {
  //     setTotalUnReadMessages(0);
  //   };
  // }, [conversations]);

  useEffect(() => {
    let total = 0;
    conversations?.forEach((conversation) => {
      total += conversation.unReadMessages;
    });

    setTotalUnReadMessages(total);

    return () => {
      setTotalUnReadMessages(0);
    };
  }, [conversations]);

  // console.log(radius);

  return (
    <div
      ref={friendRequestPlayersMenu}
      className={
        !mobileView
          ? 'topbarPlayerMapContainer'
          : 'topbarPlayerMapNotificationContainerMobile'
      }
    >
      <div className={!mobileView ? 'topbarPlayerMapLeft' : 'topbarLeftMobile'}>
        <span className="distanceSpan">Player Distance Radius: </span>
        <div
          className={
            !mobileView ? 'distanceInputbar' : 'distanceInputbarMobile'
          }
        >
          <Search className={!mobileView ? 'searchIcon' : 'searchIconMobile'} />
          <label className="labelSearchPlayerRadius" htmlFor="search">
            <input
              type="number"
              min="1"
              max="6378"
              // autoComplete="on"
              aria-label="Search"
              placeholder={radius}
              value={radius}
              className={
                !mobileView
                  ? 'searchInputPlayerRadius'
                  : 'searchInputPlayerRadiusMobile'
              }
              onChange={(e) => {
                // handleSearch(e.target.value)
                // console.log(e.target.value);
                if (e.target.value >= 0) setRadius(e.target.value);
                else setRadius(0);
                // setZoom(
                //   e.target.value <= 10
                //     ? 11
                //     : e.target.value > 10 && e.target.value < 30
                //     ? 10
                //     : e.target.value > 30 && e.target.value < 100
                //     ? 6
                //     : e.target.value > 100 && e.target.value < 600
                //     ? 5
                //     : 4,
                // );
              }}
            />
          </label>
        </div>
        <span className="unitSpan">Units(km/mi): </span>
        <div
          className={
            !mobileView ? 'distanceInputbar' : 'distanceInputbarMobile'
          }
        >
          <Search className={!mobileView ? 'searchIcon' : 'searchIconMobile'} />
          <label className="labelSearchPlayerRadius" htmlFor="search">
            <input
              type="search"
              autoComplete="on"
              aria-label="Search"
              placeholder={unit}
              value={unit}
              className={
                !mobileView
                  ? 'searchInputPlayerRadius'
                  : 'searchInputPlayerRadiusMobile'
              }
              onChange={(e) => {
                // handleSearch(e.target.value)
                setUnit(e.target.value);
              }}
            />
          </label>
        </div>
        <span className="locationRequiredSpan">
          {getPosition ? null : 'location required'}
        </span>
      </div>
      {/* <div className="topbarPlayerMapRight"></div> */}
    </div>
  );
}
