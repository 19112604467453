import { createContext, useContext, useEffect, useState } from 'react';

export const ViewPortContext = createContext({});

// export function debounce(fn, ms) {
//   let timer;
//   return (_) => {
//     clearTimeout(timer);
//     timer = setTimeout((_) => {
//       timer = null;
//       fn.apply(this, arguments);
//     }, ms);
//   };
// }
// Higher-order debounce function
export function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export const ViewportProvider = ({ children }) => {
  // This is the exact same logic that we previously had in our hook

  const [width, setWidth] = useState(document.body.clientWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const debouncedHandleWindowResize = debounce(function handleWindowResize() {
    // setWidth(window.innerWidth);
    setWidth(document.body.clientWidth);
    setHeight(window.innerHeight);
  }, 1000);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleWindowResize);
    return () =>
      window.removeEventListener('resize', debouncedHandleWindowResize);
  }, [debouncedHandleWindowResize]);

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return (
    <ViewPortContext.Provider value={{ width, height }}>
      {children}
    </ViewPortContext.Provider>
  );
};

/* Rewrite the "useViewport" hook to pull the width and height values
   out of the context instead of calculating them itself */
export const useViewport = () => {
  /* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
  const { width, height } = useContext(ViewPortContext);
  return { width, height };
};
