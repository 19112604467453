import { useContext, memo, useState, useEffect } from 'react';
import Topbar from '../../components/topbar/Topbar';
import TopbarNotification from '../../components/topbarNotification/TopbarNotification';
import Sidebar from '../../components/sidebar/Sidebar';
import Feed from '../../components/feed/Feed';
import Rightbar from '../../components/rightbar/Rightbar';
import { useMediaQuery } from 'react-responsive';
import { ViewPortContext } from '../../context/ViewPortContext';
import arePropsEqual from '../../arePropsEqual';
import './home.css';
import { useGetConversations } from '../../hooks/useGetConversations';
import { AuthContext } from '../../context/AuthContext';

export default memo(function Home() {
  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const { width } = useContext(ViewPortContext);
  const [conversations, setConversations] = useState([]);
  const [totalUnReadMessages, setTotalUnReadMessages] = useState(0);
  const { player } = useContext(AuthContext);

  const onSuccess2 = (data) => {
    // console.log('data: ', data);
  };

  const onError2 = (err) => {
    // console.log('err: ', err);
  };

  const {
    data: conversationsData,
    isSuccess,
    status: conversationStatus,
    refetch,
  } = useGetConversations(onSuccess2, onError2, player);
  // console.log(data);

  useEffect(() => {
    //set conversations
    if (isSuccess) {
      // console.log(isSuccess);
      setConversations(conversationsData);
    }
  }, [conversationsData, isSuccess]);

  //console.log(width);

  return (
    <>
      {/* <Topbar /> */}
      <Topbar
        conversationsData={conversationsData}
        conversationsStatus={conversationStatus}
        refetchConversations={refetch}
        parent="Home"
        conversations={conversations}
        setConversations={setConversations}
        totalUnReadMessages={totalUnReadMessages}
        setTotalUnReadMessages={setTotalUnReadMessages}
      />
      {mobileView && <TopbarNotification />}
      <div className="homeContainer">
        {!mobileView && <Sidebar />}
        <Feed />
        {!mobileView && <Rightbar />}
      </div>
    </>
  );
}, arePropsEqual);
