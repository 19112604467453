import { useContext, useState, useEffect } from 'react';

import Topbar from '../../components/topbar/Topbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Rightbar from '../../components/rightbar/Rightbar';
import CreateAd from '../../components/createAd/CreateAd';
import AdRotator from '../../components/adRotator/AdRotator';
import { useMediaQuery } from 'react-responsive';
import { ViewPortContext, useViewport } from '../../context/ViewPortContext';
import { useGetConversations } from '../../hooks/useGetConversations';
import { AuthContext } from '../../context/AuthContext';

import './advertise.css';

export default function Home() {
  const { player } = useContext(AuthContext);

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });
  const { width } = useContext(ViewPortContext);
  const [conversations, setConversations] = useState([]);
  const [totalUnReadMessages, setTotalUnReadMessages] = useState(0);

  const onSuccess2 = (data) => {
    // console.log('data: ', data);
  };

  const onError2 = (err) => {
    // console.log('err: ', err);
  };

  const {
    data: conversationsData,
    isSuccess,
    status: conversationStatus,
    refetch,
  } = useGetConversations(onSuccess2, onError2, player);
  // console.log(data);

  useEffect(() => {
    //set conversations
    if (isSuccess) {
      // console.log(isSuccess);
      setConversations(conversationsData);
    }
  }, [conversationsData, isSuccess]);
  return (
    <>
      <Topbar
        conversationsData={conversationsData}
        conversationsStatus={conversationStatus}
        refetchConversations={refetch}
        parent="Advertise"
        conversations={conversations}
        setConversations={setConversations}
        totalUnReadMessages={totalUnReadMessages}
        setTotalUnReadMessages={setTotalUnReadMessages}
      />
      <div className="advertiseContainer">
        {!mobileView && <Sidebar />}
        <div className="spacerForPage">
          {width >= 979 && width <= 1201 && <AdRotator />}
        </div>
        <div className="createAdWrapper">
          <CreateAd />
          {width <= 978 && <AdRotator />}
        </div>
        {!mobileView && width > 1201 && <Rightbar />}
      </div>
    </>
  );
}
