import './rightbar.css';
import AdRotator from '../adRotator/AdRotator';
import MemeRotator from '../memeRotator/MemeRotator';
//import Online from '../online/Online';
import { useContext, useEffect, useState, memo } from 'react';
import axiosConn from '../../axiosConn';
import arePropsEqual from '../../arePropsEqual';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Add, Remove } from '@material-ui/icons';
import EasyEdit from 'react-easy-edit';
import Friendship from '../friendship/Friendship';
import isExcluded from '../../utils/isExcluded';
import { useTheme } from '../../context/ThemeContext';

//player is the logged in player.
//currentPlayer is also the logged in player.
//player1 is the profile visited.

export default function Rightbar({ player, player1 }) {
  // const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const [friends, setFriends] = useState([]);
  const { player: currentPlayer, dispatch } = useContext(AuthContext);
  // console.log(mobileView);

  //currentPlayer follows player1.
  const [followed, setFollowed] = useState(
    currentPlayer.data.player.followings.includes(player1?._id),
  );

  const textDefault = '-';
  const { darkTheme } = useTheme();

  const handleSave = (tag) => async (text) => {
    //update profilePhoto in localStorage object.
    const currentPlayer = JSON.parse(localStorage.getItem('player'));

    try {
      if (tag === 'desc') {
        currentPlayer.data.player.desc = text !== '' ? text : textDefault;
        // console.log(tag, text);

        await axiosConn.patch(
          '/players/updateMe',
          { desc: text !== '' ? text : textDefault },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
      } else if (tag === 'city') {
        currentPlayer.data.player.city = text !== '' ? text : textDefault;
        //    console.log(tag, text !== '' ? text : textDefault );
        await axiosConn.patch(
          '/players/updateMe',
          { city: text !== '' ? text : textDefault },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
      } else if (tag === 'from') {
        currentPlayer.data.player.from = text !== '' ? text : textDefault;
        //   console.log(tag, text );
        await axiosConn.patch(
          '/players/updateMe',
          { from: text !== '' ? text : textDefault },
          {
            headers: {
              Authorization: `Bearer ${player.token}`,
            },
          },
        );
      }
      localStorage.setItem('player', JSON.stringify(currentPlayer));
      //window.location.reload();
    } catch (err) {}
  };

  const handleCancel = (tag) => (text) => {
    //  console.log(text, tag);
  };

  //console.log(followed);
  //No player1 means homepage not profile page.
  //use currentPlayer to getFriends.
  //console.log(player1);
  useEffect(() => {
    let mounted = true;

    //This grabs Player friends.
    const getFriends = async () => {
      try {
        if (player1 && typeof player1._id !== 'undefined') {
          const friendList = await axiosConn.get(
            '/players/friends/' + player1._id,
            {
              headers: {
                Authorization: `Bearer ${player.token}`,
              },
            },
          );
          if (!mounted) return;
          setFriends(friendList.data);
          //console.log(friendList.data);
        } else {
          //console.log(currentPlayer.data.player._id);
          if (
            currentPlayer.data.player._id &&
            typeof currentPlayer.data.player._id !== 'undefined'
          ) {
            const friendList = await axiosConn.get(
              '/players/friends/' + currentPlayer.data.player._id,
              {
                headers: {
                  Authorization: `Bearer ${currentPlayer.token}`,
                },
              },
            );
            if (!mounted) return;
            setFriends(friendList.data);
            //console.log(friendList.data);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getFriends();
    return () => {
      mounted = false;
    };
  }, [player, player1, currentPlayer]);

  const handleClick = async () => {
    try {
      //console.log(followed);
      if (followed) {
        await axiosConn.put(
          `/players/${player1._id}/unfollow`,
          {
            id: currentPlayer.data.player._id,
          },
          {
            headers: {
              Authorization: `Bearer ${currentPlayer.token}`,
            },
          },
        );
        dispatch({ type: 'UNFOLLOW', payload: player1._id });
      } else {
        // console.log(
        //   currentPlayer.data.player.playerId,
        //   'is following: ',
        //   player1.playerId,
        // );
        await axiosConn.put(
          `/players/${player1._id}/follow`,
          {
            id: currentPlayer.data.player._id,
          },
          {
            headers: {
              Authorization: `Bearer ${currentPlayer.token}`,
            },
          },
        );
        dispatch({ type: 'FOLLOW', payload: player1._id });
      }
      setFollowed(!followed);
    } catch (err) {
      console.log(err);
    }
  };
  // const HomeRightbar = memo(() => {
  //   return (
  //     <>
  //       {/*
  //       <div className="birthdayContainer">
  //         <img className="birthdayImg" src="assets/gift.png" alt="" />
  //         <span className="birthdayText">
  //           <b>Pola Foster</b> and <b>3 other friends</b> have a birhday today.
  //         </span>
  //       </div>
  //       */}
  //       {/*<img className="rightbarAd" src="assets/extra/jg.jpg" alt="" /> */}
  //       <AdRotator />
  //       <h4 className="rightbarTitle">Meme Machine</h4>

  //       <MemeRotator />
  //       {/*
  //       <div className="rightbarFriendsContainer">
  //         <h4 className="rightbarTitle">Online Friends</h4>
  //         <ul className="rightbarFriendList">
  //           {friends.map((f) => (
  //             <Online key={f._id} player={f} />
  //           ))}
  //         </ul>
  //       </div>
  //       */}
  //     </>
  //   );
  // }, arePropsEqual);

  //   const ProfileRightbar = memo(() => {
  //     return (
  //       <>
  //         {player.data.player.playerId !== player1.playerId && (
  //           <Friendship player1={player1} />
  //         )}
  //         {/*
  //         {player1.playerId !== currentPlayer.data.player.playerId && (
  //           <button className="rightbarFollowButton" onClick={handleClick}>
  //             {followed ? 'Unfollow' : 'Follow'}
  //             {followed ? <Remove /> : <Add />}
  //           </button>
  //         )}
  //         */}
  //         {/*
  //         {player1.playerId !== currentPlayer.data.player.playerId
  //           ? followed && (
  //               <button className="rightbarFollowButton">followed</button>
  //             )
  //           : null}
  //         {player1.playerId !== currentPlayer.data.player.playerId ? (
  //           requestSent ? (
  //             <button className="rightbarFollowButton">Cancel Request</button>
  //           ) : (
  //             <button className="rightbarFollowButton">
  //               Send friend request
  //             </button>
  //           )
  //         ) : null}
  // */}
  //         <div className="ownProfileTitlePadding"></div>
  //         <h4 className="rightbarTitle">{player1.playerId} player info:</h4>
  //         <div className="rightbarInfo">
  //           <div className="rightbarInfoItem">
  //             <span className="rightbarInfoKey">
  //               <div className="rightbarInfoContainer">
  //                 <div className="rightbarInfoContent">City:</div>
  //                 <EasyEdit
  //                   value={player1.city}
  //                   type="text"
  //                   onSave={handleSave('city')}
  //                   onCancel={handleCancel('city')}
  //                   saveOnBlur
  //                   attributes={{ name: 'awesome-input', id: 1 }}
  //                   className="rightbarInfoValue"
  //                   allowEdit={
  //                     player1.playerId === currentPlayer.data.player.playerId
  //                   }
  //                 />
  //               </div>
  //             </span>
  //           </div>
  //           <div className="rightbarInfoItem">
  //             <div className="rightbarInfoContainer">
  //               <div className="rightbarInfoContent">From:</div>
  //               <EasyEdit
  //                 value={player1.from}
  //                 type="text"
  //                 onSave={handleSave('from')}
  //                 onCancel={handleCancel('from')}
  //                 saveOnBlur
  //                 attributes={{ name: 'awesome-input', id: 1 }}
  //                 className="rightbarInfoValue"
  //                 allowEdit={
  //                   player1.playerId === currentPlayer.data.player.playerId
  //                 }
  //               />
  //             </div>
  //           </div>
  //           <div className="rightbarInfoItem">
  //             <div className="rightbarInfoRelationship">
  //               <span className="rightbarInfoKey">Relationship:</span>
  //               <span className="rightbarInfoValue">
  //                 {player1.relationship === 1
  //                   ? 'Single'
  //                   : player1.relationship === 1
  //                   ? 'Married'
  //                   : '-'}
  //               </span>
  //             </div>
  //           </div>
  //         </div>
  //         <h4 className="rightbarTitle">{player1.playerId} Follows:</h4>
  //         <div className="rightbarFollowings">
  //           {(player1?.friends?.includes(player.data.player._id) ||
  //             currentPlayer.data.player._id === player1._id) &&
  //             friends.map((friend) => (
  //               <Link
  //                 to={'/profile/' + friend.playerId}
  //                 style={{ textDecoration: 'none' }}
  //               >
  //                 <div className="rightbarFollowing">
  //                   <img
  //                     src={
  //                       friend.profilePhoto
  //                         ? friend.profilePhoto
  //                         : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
  //                     }
  //                     alt=""
  //                     className="rightbarFollowingImg"
  //                   />
  //                   <span className="rightbarFollowingName">
  //                     {friend.playerId}
  //                   </span>
  //                 </div>
  //               </Link>
  //             ))}
  //         </div>
  //       </>
  //     );
  //   }, arePropsEqual);

  // Sort friends by jgkTokens in descending order
  const sortedFriends = [...friends].sort((a, b) => b.jgkTokens - a.jgkTokens);

  return (
    <div className={darkTheme ? 'app rightbar dark-theme' : 'app rightbar'}>
      {/* <div className="rightbar"> */}
      <div className="rightbarWrapper">
        {player ? (
          <>
            {player.data.player.playerId !== player1.playerId && (
              <Friendship player1={player1} />
            )}

            <div className="ownProfileTitlePadding"></div>
            <h4 className="rightbarTitle">{player1.playerId} player info:</h4>
            <div
              className={
                darkTheme ? 'rightbarInfo dark-theme blackBg' : 'rightbarInfo'
              }
            >
              <div
                className={
                  darkTheme
                    ? 'rightbarInfoItem dark-theme blackBg'
                    : 'rightbarInfoItem'
                }
              >
                <span className="rightbarInfoKey">
                  <div className="rightbarInfoContainer">
                    <div
                      className={
                        darkTheme
                          ? 'rightbarInfoContent dark-theme blackBg'
                          : 'rightbarInfoContent'
                      }
                    >
                      City:
                    </div>
                    <EasyEdit
                      value={player1.city}
                      type="text"
                      onSave={handleSave('city')}
                      onCancel={handleCancel('city')}
                      saveOnBlur
                      attributes={{ name: 'awesome-input', id: 1 }}
                      className={
                        darkTheme
                          ? 'rightbarInfoValue dark-theme blackBg'
                          : 'rightbarInfoValue'
                      }
                      allowEdit={
                        player1.playerId === currentPlayer.data.player.playerId
                      }
                    />
                  </div>
                </span>
              </div>
              <div className="rightbarInfoItem">
                <div className="rightbarInfoContainer">
                  <div
                    className={
                      darkTheme
                        ? 'rightbarInfoContent dark-theme blackBg'
                        : 'rightbarInfoContent'
                    }
                  >
                    From:
                  </div>
                  <EasyEdit
                    value={player1.from}
                    type="text"
                    onSave={handleSave('from')}
                    onCancel={handleCancel('from')}
                    saveOnBlur
                    attributes={{ name: 'awesome-input', id: 1 }}
                    className="rightbarInfoValue"
                    allowEdit={
                      player1.playerId === currentPlayer.data.player.playerId
                    }
                  />
                </div>
              </div>
              <div className="rightbarInfoItem">
                <div className="rightbarInfoRelationship">
                  <span
                    className={
                      darkTheme
                        ? 'rightbarInfoKey dark-theme blackBg'
                        : 'rightbarInfoKey'
                    }
                  >
                    Relationship:
                  </span>
                  <span className="rightbarInfoValue">
                    {player1.relationship === 1
                      ? 'Single'
                      : player1.relationship === 1
                      ? 'Married'
                      : '-'}
                  </span>
                </div>
              </div>
            </div>
            <h4 className="rightbarTitle">{player1.playerId} Follows:</h4>
            <div className="rightbarFollowings">
              {(player1?.friends?.includes(player.data.player._id) ||
                currentPlayer.data.player._id === player1._id) &&
                sortedFriends.map((friend) => (
                  <Link
                    to={'/profile/' + friend.playerId}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="rightbarFollowing">
                      <img
                        src={
                          friend.profilePhoto
                            ? friend.profilePhoto
                            : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                        }
                        alt=""
                        className="rightbarFollowingImg"
                      />
                      <span className="rightbarFollowingName">
                        {friend.playerId}
                      </span>
                    </div>
                  </Link>
                ))}
            </div>
          </>
        ) : (
          <>
            {!isExcluded('Ads') && <AdRotator />}

            {!isExcluded('Memes') && ( // Assuming isExcluded is a function and should be called
              <>
                <h4 className="rightbarTitle">Meme Machine</h4>
                <MemeRotator />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
