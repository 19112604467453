// MediaCover.jsx
import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import ModalImage from 'react-modal-image';

function MediaCover({
  isVideo,
  isTube,
  mediaSource,
  handleVideoLoad,
  parent,
  player1 = null,
}) {
  const { player } = useContext(AuthContext);
  let title = null;

  if (
    parent === 'playerProfile' &&
    player1?.playerId === player?.data?.player?.playerId
  ) {
    title = `${player1.playerId}, click to change your cover photo`;
  }

  if (isVideo) {
    if (isTube) {
      let videoId;
      if (mediaSource.includes('youtu.be')) {
        videoId = mediaSource.split('youtu.be/')[1];
      } else if (mediaSource.includes('m.youtube.com')) {
        videoId = mediaSource.split('?v=')[1];
      } else if (mediaSource.includes('youtube.com/live')) {
        videoId = mediaSource.split('live/')[1];
      } else {
        videoId = mediaSource.split('v=')[1];
      }

      return (
        <iframe
          id="ytplayer"
          className="galaxyProfileCoverImg"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&enablejsapi=1`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      );
    } else {
      return (
        <video
          className={
            parent === 'galaxyProfile'
              ? 'galaxyProfileCoverImg'
              : 'profileCoverImg'
          }
          src={mediaSource}
          controls
          autoPlay
          loop
          muted
          onLoadedData={handleVideoLoad}
        />
      );
    }
  } else {
    return player1?.playerId === player?.data?.player?.playerId ? (
      <img
        className={
          parent === 'galaxyProfile'
            ? 'galaxyProfileCoverImg'
            : 'profileCoverImg'
        }
        src={
          mediaSource ||
          'https://res.cloudinary.com/joegalaxy/image/upload/v1707443157/TimelineCovers.pro_miami-florida-coastline-cityscape-facebook-cover_q27ikm.jpg'
        }
        alt=""
        title={title}
      />
    ) : (
      <ModalImage
        hideDownload={true}
        small={
          mediaSource ||
          'https://res.cloudinary.com/joegalaxy/image/upload/v1707443157/TimelineCovers.pro_miami-florida-coastline-cityscape-facebook-cover_q27ikm.jpg'
        }
        large={
          mediaSource ||
          'https://res.cloudinary.com/joegalaxy/image/upload/v1707443157/TimelineCovers.pro_miami-florida-coastline-cityscape-facebook-cover_q27ikm.jpg'
        }
        className={
          parent === 'galaxyProfile'
            ? 'galaxyProfileCoverImg'
            : 'profileCoverImg'
        }
        alt=""
      />
    );
  }
}

export default MediaCover;
