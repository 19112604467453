import './memoryPostRotator.css';
import { useState, useEffect, useContext, memo } from 'react';
import Post from '../post/Post';
import arePropsEqual from '../../arePropsEqual';
import axiosConn from '../../axiosConn';
import { AuthContext } from '../../context/AuthContext';

import { CircularProgress } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import getMonthDiff from '../../utils/getMonthDiff';
import { useGetPosts } from '../../hooks/useGetPosts';

export default memo(function MemoryPostRotator() {
  const { player } = useContext(AuthContext);

  const [randomNum, setRandomNum] = useState(0);
  const [memoryPosts, SetMemoryPosts] = useState([]);

  //memory posts since # of months back.
  const d = new Date();
  const [memoryPostMonths, setMemoryPostMonths] = useState(
    getMonthDiff(new Date(2022, 2), new Date(d.getFullYear(), d.getMonth())),
  );

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  const {
    data: posts,
    status,
    isLoading,
  } = useGetPosts(null, null, player, null, 0);

  // if (status === 'success') {
  //   console.log('posts: ', posts);
  // }

  // useEffect(() => {
  //   // Initialize a timeout
  //   let mounted = true;
  //   //throwback memory post
  //   let d = new window.Date();
  //   if (!mounted) return;

  //   d.setMonth(d.getMonth() - Math.floor(Math.random() * memoryPostMonths));
  //   console.log('memoryPostMonths: ', memoryPostMonths);
  //   console.log('date for memory post is: ', d);
  //   SetMemoryPosts(
  //     posts?.filter((p) => p.createdAt.includes(d.toISOString().split('T')[0])),
  //   );

  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  useEffect(() => {
    let mounted = true;
    //throwback memory post
    let d = new window.Date();
    if (!mounted) return;

    // Set the start and end dates for memory posts
    let startMonth =
      d.getMonth() - Math.floor(Math.random() * memoryPostMonths);
    let startDate = new Date(d.getFullYear(), startMonth, 1);
    let endDate = new Date(d.getFullYear(), startMonth - 1, 0);

    // console.log('memoryPostMonths: ', memoryPostMonths);
    // console.log('date range for memory post is: ', endDate, ' to ', startDate); // Notice the order of dates

    SetMemoryPosts(
      posts?.filter((p) => {
        let postDate = new Date(p.createdAt);
        return postDate >= endDate && postDate < startDate;
      }),
    );

    return () => {
      mounted = false;
    };
  }, [memoryPostMonths]);

  useEffect(() => {
    let mounted = true;

    if (!mounted) return;
    setRandomNum(Math.floor(Math.random() * memoryPosts?.length));
    return () => {
      mounted = false;
    };
  }, [memoryPosts?.length]);

  // console.log('memoryPosts: ', memoryPosts, 'randomNum: ', randomNum);
  if (memoryPosts) {
    // console.log(memoryPosts[randomNum]);
  }
  return (
    <>
      {isLoading ? (
        <div className="feedPostProgress">
          <CircularProgress color="inherit" size="10px" />
        </div>
      ) : (
        <>
          {memoryPosts &&
            !isNaN(randomNum) &&
            memoryPosts[randomNum] !== undefined && (
              <h4 className="postTitleThrowback">Memory Post</h4>
            )}
          {memoryPosts && !isNaN(randomNum) && memoryPosts[randomNum] && (
            <Post
              key={memoryPosts[randomNum]?._id}
              initialPost={memoryPosts[randomNum]}
            />
          )}
        </>
      )}
    </>
  );
}, arePropsEqual);
