import { useContext, useState } from 'react';
import './buyJGKToken.css';
import axiosConn from '../../axiosConn';

import { AuthContext } from '../../context/AuthContext';
import { CircularProgress } from '@material-ui/core';
// import { useMediaQuery } from 'react-responsive';
import { ViewPortContext } from '../../context/ViewPortContext';

// import IconButton from 'material-ui/lib/icon-button';
//import { Icon_Visa, Icon_MasterCard } from 'material-ui-credit-card-icons';

export default function BuyJGKToken() {
  const { player } = useContext(AuthContext);
  const { width } = useContext(ViewPortContext);

  const [isLoading, setIsLoading] = useState(false);
  // const mobileView = useMediaQuery({
  //   query: '(max-width: 805px)',
  // });

  const submitHandler = async (e) => {
    e.preventDefault();
    //console.log(file);
    setIsLoading(true);

    try {
      const res2 = await axiosConn.post(
        `/jgkTokenTransactions/create-jgktransaction-checkout-session/`,
        {}, //had to put this empty post. no 401.
        {
          headers: {
            Authorization: `Bearer ${player.token}`,
          },
        },
      );
      //console.log(session);
      // possibly have endpoint redirect to session.url. to charge credit card on stripe's secure site.
      //console.log(res2.data.session.url);
      window.location.href = res2.data.session.url;
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="buyJGKTokenFeed">
      <div className="buyJGKTokenFeedWrapper">
        <div className="buyJGKTokenShare">
          <div className="createAdShareWrapper">
            <div className="shareTop">
              <img
                className="buyJGKTokenshareProfileImg"
                src={
                  player.data.player.profilePhoto
                    ? player.data.player.profilePhoto
                    : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                }
                alt=""
              />
              <span className="buyJGKTokenDesc">
                <div className="buyJGKTokeDesc">Buy JG Klout (JGK$) Tokens</div>
                {/* <div>receive benefits, rewards & reward others.</div> */}
                {/* <div className="kloutImg">
                  <img
                    className="kloutImg"
                    src={
                      'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1677560210/android-chrome-512x512_wbtvj3.png'
                    }
                    alt=""
                  />
                </div> */}
                <div className="stripeImgJGK">
                  <img
                    className="stripeImg"
                    src={
                      'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1661538167/Images/Powered_By_Stripe_nmufch.png'
                    }
                    alt=""
                  />
                </div>
              </span>
              <form className="buyJGKTokenBox" onSubmit={submitHandler}>
                <div>
                  <img
                    className="qrCodeImg"
                    src={
                      'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1677560946/qr_test_8wMaHU4F90CO2ME6oo_x7krc7.png'
                    }
                    alt=""
                  />
                </div>
                <span className="clickTxt">Or Click</span>
                <div>
                  <button
                    className="payButtonJGKToken"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress color="inherit" size="20px" />
                    ) : (
                      'Checkout'
                    )}
                  </button>
                </div>
                {/* {width !== 768 && width !== 912 && width !== 1024 && ( */}
                {width !== 768 &&
                  width !== 912 &&
                  width !== 1024 &&
                  width < 540 && (
                    <>
                      {
                        <div className="stripeImgJGK">
                          <img
                            className="stripeImg"
                            src={
                              'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1661538167/Images/Powered_By_Stripe_nmufch.png'
                            }
                            alt=""
                          />
                        </div>
                      }
                      <div className="buyJGKTokeDesc">
                        Buy JG Klout (JGK$) Tokens
                      </div>
                    </>
                  )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
