import './playersearchresults.css';
import { useRef, useState } from 'react';

import { Link } from 'react-router-dom';

export default function PlayerSearchResults({ searchedPlayers }) {
  return (
    <>
      <div>
        <ul className="ulollist">
          {searchedPlayers.map((player) => {
            return (
              <li key={player._id}>
                <Link
                  to={'/profile/' + player.playerId}
                  style={{ textDecoration: 'none' }}
                >
                  <div className="playerSearchResult">
                    <img
                      src={
                        player.profilePhoto
                          ? player.profilePhoto
                          : 'https://res.cloudinary.com/joegalaxy/image/upload/q_auto/v1656194537/Images/noAvatar_tsgpm9.png'
                      }
                      alt=""
                      className="topbarImg"
                    />
                    <span className="searchedPlayersPlayerId">
                      {player.playerId}
                    </span>
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
