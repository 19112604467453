import './table.css';

import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { ViewPortContext } from '../../context/ViewPortContext';
import { useMediaQuery } from 'react-responsive';
import { useTable, useFilters, useSortBy } from 'react-table';

export default function Table({
  columns,
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) {
  const { width } = useContext(ViewPortContext);
  const [filterInput, setFilterInput] = useState('');

  const mobileView = useMediaQuery({
    query: '(max-width: 805px)',
  });

  const [numColumns, setNumColumns] = useState(mobileView ? 3 : 4);

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
  );
  const [jgkTransactionsCount, setJGKTransactionsCount] = useState(10);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    setNumColumns(mobileView ? 3 : 4);
  }, [mobileView]);

  const showMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
    setJGKTransactionsCount((prev) => prev + 10);
    setShowAll(true);
  };

  const showLess = () => {
    setJGKTransactionsCount(10);
    setShowAll(false);
  };

  // Update the state when input changes
  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter('sender', value); // Update the show.name filter. Now our table will filter and show only the rows which have a matching value

    setFilterInput(value);
  };

  const jgkTransactionsRow = rows
    .slice(0, jgkTransactionsCount)
    .map((row, i) => {
      prepareRow(row);
      return (
        <tr {...row.getRowProps()}>
          {row.cells.slice(0, numColumns).map((cell) => {
            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
          })}
        </tr>
      );
    });

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    // Input element
    <>
      {/* <div className="tableSearchInput">
        <input
          value={filterInput}
          onChange={handleFilterChange}
          placeholder={'Search Sender'}
        />
      </div> */}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.slice(0, numColumns).map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'sort-desc'
                        : 'sort-asc'
                      : ''
                  }
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {jgkTransactionsRow}
          {hasNextPage && (
            <tr>
              <td colSpan={numColumns}>
                <div className="viewJGKTransactions" onClick={showMore}>
                  {isFetchingNextPage ? 'Loading more...' : 'Show more'}
                </div>
              </td>
            </tr>
          )}
          {showAll && (
            <tr>
              <td colSpan={numColumns}>
                <div className="viewJGKTransactions" onClick={showLess}>
                  show less
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
